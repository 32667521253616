import React, { useContext } from 'react';
import { getWindowOffsetsForMagnifiedCard, vhToPixels } from '../../../../../../shared/utils';
import { UiStateStoreContext } from '../../../../shared/UiStateStore';
import './MagnifiedCard.less';

type MagnifiedCardProps = {
  playAreaRef: any;
};

const MagnifiedCard = ({ playAreaRef }: MagnifiedCardProps) => {
  const uiStateStore = useContext(UiStateStoreContext);
  const { cardData, position, shouldShow, onLeft } = uiStateStore.useState(s => s.magnifiedCard);
  const isDecklistDialogOpen = uiStateStore.useState(s => s.decklistDialog.isOpen);
  const isAddCardDialogOpen = uiStateStore.useState(s => s.addCardDialog.isOpen);
  const isSideboardDialogOpen = uiStateStore.useState(s => s.sideboardDialog.isOpen);
  const showAboveDialog = isDecklistDialogOpen || isAddCardDialogOpen || isSideboardDialogOpen;

  if (!cardData || !position || !shouldShow) return null;

  // matches the css value of max(28vh, 350px)
  const CARD_HEIGHT = Math.max(vhToPixels(28), 350);
  const HALF_HEIGHT = CARD_HEIGHT / 2;
  const CARD_WIDTH = CARD_HEIGHT / 1.4;
  const QUARTER_WIDTH = CARD_WIDTH / 4;

  // x&y are the coordinates of the midpoint of the card being magnified
  const displayOnRight = !onLeft && position.x < (cardData.altFaceUrl ? document.body.clientWidth / 2 + 100 : 3 * (document.body.clientWidth / 4));
  const offsetFromHoveredCard = displayOnRight ? QUARTER_WIDTH : -QUARTER_WIDTH - CARD_WIDTH;
  const left = position.x + offsetFromHoveredCard;
  const right = left + CARD_WIDTH;
  const top = position.y - HALF_HEIGHT;

  // offsets to keep magnified card from overflowing viewport
  const [leftWindowOffset, topWindowOffset] = getWindowOffsetsForMagnifiedCard({
    left,
    top,
    right,
    bottom: top + CARD_HEIGHT,
  });

  const playAreaRect = playAreaRef.getBoundingClientRect();

  const secondaryHorizontalOffset = displayOnRight ? CARD_WIDTH : -CARD_WIDTH;

  const extraOffset = (isAddCardDialogOpen || isSideboardDialogOpen) && !onLeft ? 100 : 0;

  // getBoundingClientRect() returns coordinates relative to the viewport, not the offset parent.
  // So we're getting the original X/Y relative to the window, but the
  // absolutely positioned magpreview is relative to the playArea. Thus it needs
  // offsets for the play area. That is why we subtract playAreaRect.left/top here.
  const finalPrimaryLeft =
    left + leftWindowOffset - playAreaRect.left + extraOffset;
  const finalSecondaryLeft = finalPrimaryLeft + secondaryHorizontalOffset;

  const finalTop = top + topWindowOffset - playAreaRect.top;

  // Whichever side of the card is face-up, we display directly next to it.
  // The other face is displayed on the far side of that.
  const primaryImageUrl = cardData.showAltFace ? cardData.altFaceUrl : cardData.url;
  const secondaryImageUrl = cardData.showAltFace ? cardData.url : cardData.altFaceUrl;

  const primaryImageStyle = {
    backgroundImage: `url("${primaryImageUrl}")`,
    left: finalPrimaryLeft,
    top: finalTop,
  };

  const secondaryImageStyle = {
    backgroundImage: `url("${secondaryImageUrl}")`,
    left: finalSecondaryLeft,
    top: finalTop,
  };

  const classes = `magnifiedCard ${showAboveDialog ? 'aboveDialog': ''}`;

  return (
    <>
      <div
        className={classes}
        style={primaryImageStyle}
      />
      {cardData.altFaceUrl && <div
        className={classes}
        style={secondaryImageStyle}
      />}
    </>
  );
};

export default MagnifiedCard;