import { Store } from 'pullstate';
import { AccessRequestLocationOptions, BattlefieldLocations } from '../../../../../shared/types';
import { CarouselStore } from '../../../shared/CarouselStore';
import { UiStateStore } from '../../../shared/UiStateStore';
import BattlefieldApi from '../../../utils/BattlefieldApi';
import { closeDrawer } from '../../CarouselDrawer/CarouselDrawer';

export const getPlayAreaCardMenuOptions = (
  instanceData: {
    cardIds: string[],
    gathererUrl: string | undefined,
    showAltFaceMenuOption: boolean,
    showTurnFaceUpMenuOption: boolean,
    showTurnFaceDownMenuOption: boolean,
    mirrorMode: boolean,
  },
  carouselStore: Store<CarouselStore>,
  uiStateStore: Store<UiStateStore>,
) => {
  const lockedZone = uiStateStore.getRawState().lockedZone;
  const libraryIsLocked = [AccessRequestLocationOptions.Library, AccessRequestLocationOptions.TopNOfLibrary].includes(lockedZone!);

  const revealToOwner = {
    text: 'Reveal to me',
    action: () => {
      BattlefieldApi.revealCardsToOwner(instanceData.cardIds);
    },
  };

  const conditionalMenuOptions = [];

  if (instanceData.showAltFaceMenuOption) {
    conditionalMenuOptions.push({
      text: 'Flip to other face',
      action: () => {
        BattlefieldApi.flipCardsToAltFace(instanceData.cardIds); 
      },
    });
  }

  if (instanceData.showTurnFaceUpMenuOption) {
    conditionalMenuOptions.push(revealToOwner);

    conditionalMenuOptions.push({
      text: 'Turn face up',
      action: () => {
        BattlefieldApi.turnCardsFaceUp(instanceData.cardIds); 
      },
    });
  }

  if (instanceData.showTurnFaceDownMenuOption) {
    conditionalMenuOptions.push({
      text: 'Turn face down',
      action: () => {
        BattlefieldApi.turnCardsFaceDown(instanceData.cardIds); 
      },
    });
  }

  return [
    {
      text: 'Draw attention',
      action: () => {
        BattlefieldApi.drawAttentionToCards(instanceData.cardIds);
      },
    },
    ...conditionalMenuOptions,
    {
      text: 'Toggle +X/+Y',
      action: () => {
        BattlefieldApi.updatePTModifier(instanceData.cardIds, { shouldToggle: true });
      },
    },
    {
      text: 'View on Gatherer',
      action: () => {
        window.open(instanceData.gathererUrl, '_blank');
      },
      disabled: !instanceData.gathererUrl,
    },
    {
      text: 'Clone',
      action: () => {
        BattlefieldApi.cloneCards(instanceData.cardIds);
      },
    },
    {
      divider: true,
    },
    {
      text: 'Send to bottom of library',
      action: () => {
        BattlefieldApi.sendCardsToBottomOfLibrary(instanceData.cardIds, BattlefieldLocations.PlayArea);
      },
      disabled: libraryIsLocked,
    },
    {
      text: 'Shuffle into library',
      action: () => {
        closeDrawer(carouselStore, true);
        BattlefieldApi.shuffleCardsIntoLibrary(instanceData.cardIds, BattlefieldLocations.PlayArea);
      },
      disabled: libraryIsLocked,
    },
    {
      text: 'Give control to opponent',
      action: () => {
        BattlefieldApi.giveControlOfCards(
          instanceData.cardIds,
          uiStateStore.getRawState().currentOtherPlayerId,
        );
      },
      disabled: instanceData.mirrorMode,
    },
    {
      text: 'Delete',
      extraClass: 'delete',
      action: () => {
        BattlefieldApi.deleteCards(instanceData.cardIds);
      },
    },
  ];
};