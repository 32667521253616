import Mousetrap from 'mousetrap';
import { Store } from 'pullstate';
import { AccessRequestLocationOptions, BattlefieldLocations, Topics, UserSettings } from '../../../shared/types';
import { GameStore } from '../shared/GameStore';
import { UiStateStore } from '../shared/UiStateStore';
import BattlefieldApi from './BattlefieldApi';
import { getAuth } from 'firebase/auth';
import { KeyboardActions } from '../../shared/SettingsPanel/keyboardShortcutConstants';
import { clone } from 'lodash';
import { CarouselStore } from '../shared/CarouselStore';
import { closeDrawer } from '../components/CarouselDrawer/CarouselDrawer';
import { cancelMagnification } from '../components/PlayArea/PlayAreaUtils';

export const setupUiStateStore = (
  otherPlayerUserId: string,
  lockedZone: AccessRequestLocationOptions | null,
  uiStateStore: Store<UiStateStore>,
  gameStore: Store<GameStore>,
) => {
  const userId = getAuth().currentUser!.uid;

  if (otherPlayerUserId === '') {
    uiStateStore.update(s => {
      s.currentOtherPlayerId = gameStore.getRawState().otherPlayerIds[0];
    });
  }

  if (lockedZone !== uiStateStore.getRawState().lockedZone) {
    uiStateStore.update(s => {
      s.lockedZone = lockedZone;
    });
  }

  uiStateStore.update(s => {
    s.contextMenu.revealTopCardToSelf = !!gameStore.getRawState().privateCardData.revealedTopCardOfLibrary;
    s.contextMenu.revealTopCardToAll = !!gameStore.getRawState().publicCardData[userId].revealedTopCardOfLibrary;
    s.initComplete = true;
  });
};

export const setOnFocusLossHandlers = (uiStateStore: Store<UiStateStore>) => {
  window.onblur = () => {
    uiStateStore.update(s => {
      s.magnifiedCard.cardData = null;
      s.magnifiedCard.position = null;
      s.magnifiedCard.shouldShow = false;
    });
  };
};

// If the user has a shortcut mapped to Space (such as the default for tap/untap),
// it will also trigger whatever button in the UI is focused. So if they click
// a button in the left panel then hit space, it will activate that button again.
// Same thing for Enter. And Tab starts cycling through interactable elements.
const blockDefaultInputInteractions = (event: any) => {
  if (['Space', 'Enter', 'Tab'].includes(event.code) && document.activeElement?.tagName !== 'INPUT') {
    event.preventDefault();
  }
};

export const freeInputInteractions = () => {
  document.removeEventListener('keydown', blockDefaultInputInteractions);
};

export const setKeyboardShortcuts = (
  userSettings: UserSettings,
  uiStateStore: Store<UiStateStore>,
  gameStore: Store<GameStore>,
  carouselStore: Store<CarouselStore>,
) => {
  const userId = getAuth().currentUser!.uid;

  // typescript was being super obnoxious here, hence the 'any' and the 'clone'.
  // anyways, this is because mousetrap needs 'shift+m' not ['shift'], ['m'],
  // which it would treat as two separate keys being bound separately to the same action.
  const shortcuts: any = clone(userSettings.keyboardShortcuts);
  Object.keys(shortcuts).forEach((key: any) => {
    const keys = shortcuts[key as KeyboardActions];
    if (keys.length > 1) {
      shortcuts[key as KeyboardActions] = keys.join('+').toLowerCase();
    }
  });

  document.addEventListener('keydown', blockDefaultInputInteractions);

  // the descending hierarchy of shortcut targeting:
  // contextMenu target > drag-selected cards > hovered card
  const getTargetCardIds = () => {
    const {
      contextMenuTargetCardIds,
      hoveredCardId,
      selectedCardIds,
    } = uiStateStore.getRawState().keyboardShortcutTarget;

    if (contextMenuTargetCardIds) {
      return contextMenuTargetCardIds;
    } else if (hoveredCardId) {
      // if they hover a card that's selected, we want to target the selected cards as a whole
      if (selectedCardIds?.includes(hoveredCardId)) {
        return selectedCardIds;
      }
      return [hoveredCardId];
    } else if (selectedCardIds) {
      return selectedCardIds;
    } else {
      return null;
    }
  };

  const resetIfApplicable = (cardIds: string[]) => {
    setTimeout(() => {
      const newIds = getTargetCardIds();
      // if we've already picked a new target, don't overwrite it
      // (this is primarily for hovering a pile, where you can keep targeting
      // each successive new top card with Send to X commands)
      if (newIds && newIds[0] !== cardIds[0]) return;
      // but otherwise, clear out keyboardShortcutTarget
      PubSub.publish(Topics.CardsMovedByShortcut);
    }, 200);
  };

  Mousetrap.bind(shortcuts.SendToGraveyard, () => {
    const cardIds = getTargetCardIds();
    if (!cardIds) return;

    BattlefieldApi.moveCardsById(BattlefieldLocations.Graveyard, cardIds, resetIfApplicable);
  });

  Mousetrap.bind(shortcuts.SendToExile, () => {
    const cardIds = getTargetCardIds();
    if (!cardIds) return;

    BattlefieldApi.moveCardsById(BattlefieldLocations.Exile, cardIds, resetIfApplicable);
  });

  Mousetrap.bind(shortcuts.SendToHand, () => {
    const cardIds = getTargetCardIds();
    if (!cardIds) return;

    BattlefieldApi.moveCardsById(BattlefieldLocations.Hand, cardIds, resetIfApplicable);
  });

  Mousetrap.bind(shortcuts.SendToTopOfLibrary, () => {
    const cardIds = getTargetCardIds();
    if (!cardIds) return;

    BattlefieldApi.moveCardsById(BattlefieldLocations.Library, cardIds,
      resetIfApplicable, { isTopOfLib: true });
  });

  Mousetrap.bind(shortcuts.SendToBottomOfLibrary, () => {
    const cardIds = getTargetCardIds();
    if (!cardIds) return;

    BattlefieldApi.moveCardsById(BattlefieldLocations.Library, cardIds,
      resetIfApplicable, { isBottomOfLib: true });
  });

  Mousetrap.bind(shortcuts.ShuffleIntoLibrary, () => {
    const cardIds = getTargetCardIds();
    if (!cardIds) return;

    closeDrawer(carouselStore)

    BattlefieldApi.moveCardsById(BattlefieldLocations.Library, cardIds,
      resetIfApplicable, { shuffleIntoLib: true });
  });

  Mousetrap.bind(shortcuts.UntapAll, () => {
    BattlefieldApi.untapAll();
  });

  Mousetrap.bind(shortcuts.Shuffle, () => {
    BattlefieldApi.shuffleLibrary();
  });

  Mousetrap.bind(shortcuts.DrawToHand, () => {
    const canDraw = uiStateStore.getRawState().lockedZone === null;
    if (!canDraw) return;

    closeDrawer(carouselStore, true);

    BattlefieldApi.drawNCardsFromLibraryToDestination(1, BattlefieldLocations.Hand);
  });

  Mousetrap.bind(shortcuts.TurnToOtherFace, () => {
    const cardIds = getTargetCardIds();
    if (!cardIds) return;

    const playAreaIds = gameStore.getRawState()
                          .publicCardData[userId].playArea
                          .map(card => card.id);
    const isInPlay = playAreaIds.includes(cardIds[0]);

    if (isInPlay) {
      BattlefieldApi.flipCardsToAltFace(cardIds);
    } else {
      uiStateStore.update(s => {
        cardIds.forEach(cardId => {
          s.flippedCardIds[cardId] = !s.flippedCardIds[cardId];
        });
      });
    }
  });

  Mousetrap.bind(shortcuts.ToggleFaceDown, () => {
    const cardIds = getTargetCardIds();
    if (!cardIds) return;

    BattlefieldApi.toggleCardsFaceDown(cardIds);
  });

  Mousetrap.bind(shortcuts.Clone, () => {
    const cardIds = getTargetCardIds();
    if (!cardIds) return;

    BattlefieldApi.cloneCards(cardIds);
  });

  Mousetrap.bind(shortcuts.CreateCounter, () => {
    BattlefieldApi.createCounter();
  });

  Mousetrap.bind(shortcuts.RollDie, () => {
    uiStateStore.update(s => { s.rollDieDialog.isOpen = true; });
  });

  Mousetrap.bind(shortcuts.AddCardOrToken, (event) => {
    // prevents letter from appearing in autofocused search input when dialog opens
    event.preventDefault();
    uiStateStore.update(s => {
      s.addCardDialog.isOpen = true;
    });
  });

  Mousetrap.bind(shortcuts.DrawAttention, () => {
    const cardIds = getTargetCardIds();
    if (!cardIds) return;

    BattlefieldApi.drawAttentionToCards(cardIds);
  });

  // Mousetrap.bind(shortcuts.DrawArrowFromHere, () => {
  //   alert('DrawArrowFromHere');
  // });

  Mousetrap.bind(shortcuts.TapOrUntap, () => {
    const cardIds = getTargetCardIds();
    if (!cardIds) return;

    BattlefieldApi.tapOrUntapCards(cardIds);
  });

  Mousetrap.bind(shortcuts.CycleViewToNextPlayer, () => {
    cancelMagnification(uiStateStore);
    if (carouselStore.getRawState().isDraggingCard) return;
    PubSub.publish(Topics.CycleNextPlayer);
  });

  Mousetrap.bind(shortcuts.CycleViewToPreviousPlayer, () => {
    cancelMagnification(uiStateStore);
    if (carouselStore.getRawState().isDraggingCard) return;
    PubSub.publish(Topics.CyclePrevPlayer);
  });

  Mousetrap.bind(shortcuts.IncrementLifeCounter, () => {
    PubSub.publish(Topics.IncrementLifeCounter);
  });

  Mousetrap.bind(shortcuts.DecrementLifeCounter, () => {
    PubSub.publish(Topics.DecrementLifeCounter);
  });

  Mousetrap.bind('shift+' + shortcuts.IncrementLifeCounter, () => {
    PubSub.publish(Topics.IncrementLifeCounterBy5);
  });

  Mousetrap.bind('shift+' + shortcuts.DecrementLifeCounter, () => {
    PubSub.publish(Topics.DecrementLifeCounterBy5);
  });

  Mousetrap.bind(shortcuts.IncrementSecondaryLifeCounter, () => {
    PubSub.publish(Topics.IncrementSecondaryLifeCounter);
  });

  Mousetrap.bind(shortcuts.DecrementSecondaryLifeCounter, () => {
    PubSub.publish(Topics.DecrementSecondaryLifeCounter);
  });

  Mousetrap.bind('shift+' + shortcuts.IncrementSecondaryLifeCounter, () => {
    PubSub.publish(Topics.IncrementSecondaryLifeCounterBy5);
  });

  Mousetrap.bind('shift+' + shortcuts.DecrementSecondaryLifeCounter, () => {
    PubSub.publish(Topics.DecrementSecondaryLifeCounterBy5);
  });

  Mousetrap.bind(shortcuts.IncrementPTModifier, () => {
    const cardIds = getTargetCardIds();
    if (!cardIds) return;

    BattlefieldApi.updatePTModifier(cardIds, {
      powerIncrement: 1,
      toughnessIncrement: 1,
    });
  });

  Mousetrap.bind(shortcuts.DecrementPTModifier, () => {
    const cardIds = getTargetCardIds();
    if (!cardIds) return;

    BattlefieldApi.updatePTModifier(cardIds, {
      powerIncrement: -1,
      toughnessIncrement: -1,
    });
  });

  Mousetrap.bind('shift+' + shortcuts.IncrementPTModifier, () => {
    const cardIds = getTargetCardIds();
    if (!cardIds) return;

    BattlefieldApi.updatePTModifier(cardIds, {
      powerIncrement: 5,
      toughnessIncrement: 5,
    });
  });

  Mousetrap.bind('shift+' + shortcuts.DecrementPTModifier, () => {
    const cardIds = getTargetCardIds();
    if (!cardIds) return;

    BattlefieldApi.updatePTModifier(cardIds, {
      powerIncrement: -5,
      toughnessIncrement: -5,
    });
  });

  Mousetrap.bind(shortcuts.OpenDecklist, () => {
    uiStateStore.update(s => {
      s.decklistDialog.isOpen = !s.decklistDialog.isOpen;
    });
  });
};