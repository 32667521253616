import { Store } from 'pullstate';
import { getAuth } from 'firebase/auth';
import { AccessRequestLocationOptions, BattlefieldLocations, CardData, FacedownCard } from '../../../../shared/types';
import { clearDragListener, didMouseLeaveWindow } from '../../../../shared/utils';
import { CarouselId, CarouselStore } from '../../shared/CarouselStore';
import { GameStore } from '../../shared/GameStore';
import { UiStateStore } from '../../shared/UiStateStore';

export const resetCarouselHoverState = (
  carouselStore: Store<CarouselStore>,
  waitForUpdate?: boolean,
) => {
  if (waitForUpdate && !carouselStore.getRawState().cardWasPlacedInSameSpot) {
    carouselStore.update(s => {
      s.waitingForUpdate = true;
    });
  } else {
    carouselStore.update(s => {
      s.activeCardOrigin = null;
      s.cardWasPlacedInSameSpot = false;
      s.waitingForUpdate = false;
      s.previewIsVisible = false;
      s.isDraggingCard = false;
      s.cardData = null;
      s.hoveredCarouselId = null;
      s.carouselHoverIndex = null;
    });
  }
};

// For frontend UI logic, we need separate enum values to represent the actual piles and
// the content of those piles when opened in the carousel.
// This handles converting from content source identifier -> carousel identifier.
// That way, the places in the UI that need to know if something came from a pile or
// from the expanded carousel OF that pile, have a way to distinguish.
export const convertToCarouselIdentifier
 = (content: BattlefieldLocations) => {
  switch (content) {
    case BattlefieldLocations.Hand:
      return BattlefieldLocations.Hand;
    case BattlefieldLocations.Graveyard:
      return BattlefieldLocations.GraveyardCarousel;
    case BattlefieldLocations.Exile:
      return BattlefieldLocations.ExileCarousel;
    case BattlefieldLocations.Library:
      return BattlefieldLocations.LibraryCarousel;
    case BattlefieldLocations.TopNOfLibrary:
      return BattlefieldLocations.TopNOfLibrary;
    case BattlefieldLocations.OtherPlayerGraveyard:
      return BattlefieldLocations.OtherPlayerGraveyard;
    case BattlefieldLocations.OtherPlayerExile:
      return BattlefieldLocations.OtherPlayerExile;
    case BattlefieldLocations.OtherPlayerHand:
      return BattlefieldLocations.OtherPlayerHandCarousel;
    case BattlefieldLocations.OtherPlayerLibrary:
      return BattlefieldLocations.OtherPlayerLibraryCarousel;
    default:
      throw new Error(`Unrecognized carousel content identifier: ${content}`);
  }
};

// this does the opposite - it reduces UI locations to their data sources.
export const convertToDataSource = (location: BattlefieldLocations | null) => {
  switch (location) {
    case BattlefieldLocations.PlayArea:
      return BattlefieldLocations.PlayArea;
    case BattlefieldLocations.Hand:
      return BattlefieldLocations.Hand;
    case BattlefieldLocations.GraveyardCarousel:
    case BattlefieldLocations.Graveyard:
      return BattlefieldLocations.Graveyard;
    case BattlefieldLocations.ExileCarousel:
    case BattlefieldLocations.Exile:
      return BattlefieldLocations.Exile;
    case BattlefieldLocations.LibraryCarousel:
    case BattlefieldLocations.Library:
      return BattlefieldLocations.Library;
    case BattlefieldLocations.TopNOfLibrary:
      return BattlefieldLocations.TopNOfLibrary;
    case BattlefieldLocations.OtherPlayerGraveyard:
      return BattlefieldLocations.OtherPlayerGraveyard;
    case BattlefieldLocations.OtherPlayerExile:
      return BattlefieldLocations.OtherPlayerExile;
    case BattlefieldLocations.OtherPlayerHand:
    case BattlefieldLocations.OtherPlayerHandCarousel:
      return BattlefieldLocations.OtherPlayerHand;
    case BattlefieldLocations.OtherPlayerLibrary:
    case BattlefieldLocations.OtherPlayerLibraryCarousel:
      return BattlefieldLocations.OtherPlayerLibrary;
    default:
      throw new Error(`Unrecognized carousel content identifier: ${location}`);
  }
};

export const isHoveringOpponentPileCarousel = (
  carouselStore: Store<CarouselStore>,
) => {
  const popupCarouselContent = carouselStore.getRawState().popupCarouselContent;

  if (!popupCarouselContent) return false;

  if (carouselStore.getRawState().hoveredCarouselId !== CarouselId.Popup) return false;

  return [
    BattlefieldLocations.OtherPlayerExile,
    BattlefieldLocations.OtherPlayerGraveyard,
  ].includes(popupCarouselContent);
};

export const isCarouselLocked = (
  contentSource: BattlefieldLocations,
  gameStore: Store<GameStore>,
) => {
  const lockedZone = gameStore.getRawState().privateCardData.lockedZone;

  switch (lockedZone) {
    case null:
      return false;
    case AccessRequestLocationOptions.Hand:
      return contentSource === BattlefieldLocations.Hand;
    case AccessRequestLocationOptions.Library:
    case AccessRequestLocationOptions.TopNOfLibrary:
      return [BattlefieldLocations.TopNOfLibrary, BattlefieldLocations.Library]
        .includes(contentSource);
    default:
      return false;
  }
};

// gets the actual data corresponding to a location identifier
export const getCarouselContent = (
  content: BattlefieldLocations,
  currentOtherPlayerId: string,
  isViewingLibraryFacedown: boolean,
  gameState: GameStore,
) => {
  if (!gameState.initialLoadComplete) return [];

  const userId = getAuth().currentUser!.uid;

  switch (content) {
    case BattlefieldLocations.Hand:
      return gameState.privateCardData.hand;
    case BattlefieldLocations.Graveyard:
      return gameState.publicCardData[userId].graveyard;
    case BattlefieldLocations.Exile:
      return gameState.publicCardData[userId].exile;
    case BattlefieldLocations.TopNOfLibrary:
      return gameState.privateCardData.topNOfLibrary;
    case BattlefieldLocations.OtherPlayerGraveyard:
      return gameState.publicCardData[currentOtherPlayerId].graveyard;
    case BattlefieldLocations.OtherPlayerExile:
      return gameState.publicCardData[currentOtherPlayerId].exile;
    case BattlefieldLocations.OtherPlayerHand:
    case BattlefieldLocations.OtherPlayerLibrary:
      if (!gameState.privateCardData.revealedOpponentCards) {
        alert('Something got messed up. Try closing the popup drawer and requesting to view those cards again.');
        return [];
      }
      return gameState.privateCardData.revealedOpponentCards.cards;
    case BattlefieldLocations.Library:
      if (isViewingLibraryFacedown) {
        return gameState.privateCardData.facedownLibrary;
      }
      return gameState.privateCardData.revealedLibrary;
    default:
      throw new Error(`Content identifier not recognized: ${content}`);
  }
};

export const onPreviewDragEnd = (carouselStore: Store<CarouselStore>, waitForUpdate: boolean) => {
  resetCarouselHoverState(carouselStore, waitForUpdate);
  clearDragListener();
};

// This used to live in the CardCarousel, but hypothetically doesn't need to
// be there and was just bloating it. So, here we are.
let onWindowLeaveReference: any = null;

export const hidePreviewOnWindowLeave = (
  carouselStore: Store<CarouselStore>,
  uiStateStore: Store<UiStateStore>,
) => {
  if (!onWindowLeaveReference) {
    const onWindowLeave = (leaveEvent: any) => {
      if (didMouseLeaveWindow(leaveEvent)) {
        // reset everything involving drag state if we move the mouse out of the window
        onPreviewDragEnd(carouselStore, false);
        uiStateStore.update(s => {
          s.isTrespassing = false;
        });
      }
    };
    document.body.addEventListener('mouseout', onWindowLeave);
    onWindowLeaveReference = onWindowLeave;
  }
};

export const haveCardsChanged = (
  cards: CardData[] | FacedownCard[],
  previousCards: CardData[] | FacedownCard[] | null,
) => {
  if (!previousCards) return true;
  // check if a card was added or removed
  if (cards.length !== previousCards?.length) return true;
  // check if order has changed
  for (let n = 0; n < cards.length; n++) {
    if (cards[n].id !== previousCards[n].id) return true;
  }
  return false;
};

// this does all the state calculations for deciding whether and where to show a ghost,
// and which cards to filter out if any (due to hovering or filtering, etc)
export const processCarouselCards = (
  cards: CardData[] | FacedownCard[],
  cardsHaveChanged: boolean,
  carouselId: CarouselId,
  allowDrop: boolean,
  carouselData: CarouselStore,
  filterText?: string,
) => {
  // Filter out the current dragPreview card from the carousel
  cards = cards.filter(
    card => card.id !== carouselData.cardData?.id
  );

  if (filterText && !carouselData.isViewingLibraryFacedown) {
    cards = cards.filter(card => card.name!.toLowerCase().includes(filterText.toLowerCase()));
  }

  const showHoverGhost = carouselData.hoveredCarouselId === carouselId
    // If this is null, we're not hovering at all in any capacity, period
    && carouselData.carouselHoverIndex !== null
    // Show hover ghost if we're just hovering, but not if we're dragging
    // when allowDrop isn't allowed
    && (allowDrop || (!carouselData.isDraggingCard && carouselData.cardData))
    // The "cardsHaveChanged && carouselData.waitingForUpdate" part covers
    // the case of the render for which the server update just came in but the 
    // conditional in CardCarousel resetting carousel hover state hasn't executed yet.
    && (!cardsHaveChanged || cardsHaveChanged && carouselData.waitingForUpdate);

  if (showHoverGhost) {
    // Note that the ghost we add here is never saved, it's filtered out in onCardDrop
    cards.splice(carouselData.carouselHoverIndex!, 
      0, {
      isGhost: true,
      id: 'HOVER_GHOST_ID',
      name: 'HOVER_GHOST',
      url: '',
      gathererUrl: '',
      cmc: 0,
      type: '',
    });
  }

  return cards;
};

export const getPopupCarouselLabel = (contentSource: BattlefieldLocations) => {
  switch(contentSource) {
    case BattlefieldLocations.Library:
      return 'Library';
    case BattlefieldLocations.Exile:
      return 'Exile';
    case BattlefieldLocations.Graveyard:
      return 'Graveyard';
    case BattlefieldLocations.OtherPlayerGraveyard:
      return 'Opponent Graveyard';
    case BattlefieldLocations.OtherPlayerExile:
      return 'Opponent Exile';
    case BattlefieldLocations.OtherPlayerHand:
      return 'Opponent Hand';
    case BattlefieldLocations.OtherPlayerLibrary:
      return 'Opponent Library';
    case BattlefieldLocations.TopNOfLibrary:
      return 'Top X of Library'
  }
};