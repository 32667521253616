import React, { useContext, useEffect, useRef, useState } from 'react';
import { getAuth } from 'firebase/auth';
import { AccessRequestLocationOptions, BattlefieldLocations, CardInPlay, DRAG_ZINDEX, DropEventData, LEFT_MOUSE, Topics } from '../../../../shared/types';
import { blurInputs, clearDragListener, copyCardData, preventRightClick, setGlobalOnMouseMove } from '../../../../shared/utils';
import Dropzone from '../../shared/Dropzone';
import { GameStoreContext } from '../../shared/GameStore';
import { UiStateStoreContext } from '../../shared/UiStateStore';
import { showContextMenu } from '../ContextMenu';
import { CarouselStoreContext } from '../../shared/CarouselStore';
import { resetCarouselHoverState } from '../CardCarousel/CarouselUtils';
import { getTopCardImageUrl } from './LibraryUtils';
import { cancelMagnification, checkToShowTrespassingOverlay, setMagnifiedCardDetails } from '../PlayArea/PlayAreaUtils';

import { expandPile } from '../ContextMenu/ContextMenuOptions/GenericMenuOptions';
import BattlefieldApi from '../../utils/BattlefieldApi';

import './Library.less';

// dragging cards into play from the top of the deck at speed sometimes results
// in the same card being dragged in twice somehow. we need to set up logging statements 
// to figure out how it's happening by checking the state flow along the way.

const Library = () => {
  const gameStore = useContext(GameStoreContext);
  const carouselStore = useContext(CarouselStoreContext);
  const uiStateStore = useContext(UiStateStoreContext);

  const userId = getAuth().currentUser!.uid;
  const pileSize = gameStore.useState(s => s.publicCardData[userId].librarySize);
  const imageUrl = getTopCardImageUrl(gameStore, userId);

  const topCardRef = useRef(null);

  const [isDragging, setIsDragging] = useState(false);
  const [topCardPosition, setTopCardPosition] = useState({ left: 0, top: 0 });

  const lockedZone = uiStateStore.useState(s => s.lockedZone);
  const libraryIsLocked = [AccessRequestLocationOptions.Library, AccessRequestLocationOptions.TopNOfLibrary].includes(lockedZone!);

  // this is so top card doesn't flicker back into place before game update comes in
  const [waitingForUpdate, setWaitingForUpdate] = useState(false);

  useEffect(() => {
    PubSub.subscribe(Topics.InvalidDropLocation, () => {
      setWaitingForUpdate(false);
    });
  }, []);

  useEffect(() => {
    setWaitingForUpdate(false);
  }, [pileSize]);

  const onMouseDown = (mouseDownEvent: any) => {
    cancelMagnification(uiStateStore);
    blurInputs();

    if (mouseDownEvent.button !== LEFT_MOUSE) return;
    if (libraryIsLocked) return;
    if (carouselStore.getRawState().popupCarouselContent === BattlefieldLocations.TopNOfLibrary) {
      alert ('Cannot drag top card of library to another zone while viewing top X cards.');
      return;
    }
    mouseDownEvent.preventDefault();

    setTopCardPosition({
      left: mouseDownEvent.target.getBoundingClientRect().left,
      top: mouseDownEvent.target.getBoundingClientRect().top,
    });

    setIsDragging(true);

    carouselStore.update(s => {
      s.activeCardOrigin = BattlefieldLocations.Library;
      s.isDraggingCard = true;
    });

    const mouseDistanceFromTopOfCard = mouseDownEvent.clientY - mouseDownEvent.target.getBoundingClientRect().top;
    const mouseDistanceFromLeftOfCard = mouseDownEvent.clientX - mouseDownEvent.target.getBoundingClientRect().left;

    setGlobalOnMouseMove(mouseDownEvent, (newMousePosition, moveTarget) => {
      checkToShowTrespassingOverlay(moveTarget, uiStateStore);
      setTopCardPosition({
        left: newMousePosition.x - mouseDistanceFromLeftOfCard,
        top: newMousePosition.y - mouseDistanceFromTopOfCard,
      });
    });
  };

  const onMouseUp = (mouseUpEvent: any) => {
    if (mouseUpEvent.button !== LEFT_MOUSE) {
      return;
    }
    mouseUpEvent.preventDefault();
    mouseUpEvent.persist();

    if (!isDragging) return;
    clearDragListener();
    uiStateStore.update(s => {
      s.isTrespassing = false;
    });

    // setTimeout so that the data in carouselStore can be used before being reset
    setTimeout(() => {
      carouselStore.update(s => {
        s.activeCardOrigin = null;
        s.isDraggingCard = false;
      });
    }, 30);

    setWaitingForUpdate(true);
    setIsDragging(false);

    // because the card snaps back to the "top of the pile", we have to save its location
    // or else the playArea miscalculates where it thinks it was dropped
    const locationAtTimeOfDrop = mouseUpEvent.target.getBoundingClientRect();

    PubSub.publish(Topics.CardsDropped, {
      mouseUpEvent: {
        clientX: mouseUpEvent.clientX,
        clientY: mouseUpEvent.clientY,
        target: {
          getBoundingClientRect: () => locationAtTimeOfDrop
        },
        shiftKey: mouseUpEvent.shiftKey,
      },
      // this is overwritten in DropzoneUtils.ts with the card we draw from the server
      cards: [],
      cameFrom: BattlefieldLocations.Library,
      left: mouseUpEvent.target.getBoundingClientRect().left,
      top: mouseUpEvent.target.getBoundingClientRect().top
    });
  };

  // The user should not be able to magnify the top card of the library unless it's revealed,
  // so we nullify that data onEnter and onCardDrop (unless it's revealed.)
  const onMouseEnter = (mouseEnterEvent: any) => {
    if (isDragging) return;

    if (imageUrl && topCardRef) {
      const revealedToSelfCard = gameStore.getRawState().privateCardData.revealedTopCardOfLibrary;
      const revealedToAllCard = gameStore.getRawState().publicCardData[userId].revealedTopCardOfLibrary;

      const revealedCard = revealedToSelfCard || revealedToAllCard;

      setMagnifiedCardDetails(revealedCard, topCardRef, uiStateStore);
    } else {
      cancelMagnification(uiStateStore);
    }
  };

  const onMouseLeave = (mouseLeaveEvent: any) => {
    cancelMagnification(uiStateStore);
  };

  const onCardDrop = (dropData: DropEventData) => {
    // We have to let the backend process the card drop before we can update the magnified state,
    // so it's not an option to do that here. we have to leave it to the onMouseMove code.
    setMagnifiedCardDetails(null, null, uiStateStore);
    // remove any tokens in case the cards came from the play area
    return (dropData.cards as CardInPlay[])
        .filter(card => !card.isToken)
        .map(card => copyCardData(card));
  };

  const onSizeIndicatorClick = (clickEvent: any) => {
    const expandFaceDown = clickEvent.shiftKey;

    const lockedZone = uiStateStore.getRawState().lockedZone;
    // For some reason TS throws an error on trying to use .includes() with a 
    // non-matching type, in this case "null" - so just using ! to override 
    // that even though it can be null.
    const libraryIsLocked = [AccessRequestLocationOptions.Library, AccessRequestLocationOptions.TopNOfLibrary].includes(lockedZone!);

    if (libraryIsLocked) return;

    if (expandFaceDown) {
      BattlefieldApi.closeRevealedViews(true);
      carouselStore.update(s => {
        s.isDrawerOpen = true;
        s.popupCarouselContent = BattlefieldLocations.Library;
        s.isViewingLibraryFacedown = true;
      });
    } else {
      expandPile(BattlefieldLocations.Library, carouselStore, uiStateStore);
    }
  };

  const style = isDragging ? {
    position: 'fixed',
    zIndex: DRAG_ZINDEX,
    left: topCardPosition.left,
    top: topCardPosition.top,
    backgroundImage: imageUrl ? `url("${imageUrl}"  )` : '',
  } as React.CSSProperties : {
    backgroundImage: imageUrl ? `url("${imageUrl}")` : '',
  };

  const showTopCard = pileSize > 1 || (pileSize > 0 && !waitingForUpdate);

  return (
    <div className="pileContainer library"
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onContextMenu={(eventData) => {
        cancelMagnification(uiStateStore);
        showContextMenu(eventData, BattlefieldLocations.Library, uiStateStore);
      }}
    >
      <div className="pileLabel">
        Library
      </div>
      <Dropzone location={BattlefieldLocations.Library} disabled={libraryIsLocked} onCardDrop={onCardDrop}>
        {showTopCard &&
          <div
            ref={topCardRef}
            style={style}
            className="pileCard"
            onMouseDown={onMouseDown}
            onMouseUp={onMouseUp}
          />}
        {/* only show this when we start dragging, for now */}
        {(isDragging && pileSize > 1) &&
          <div className="pileCard secondCardInFacedownPile" />}
      </Dropzone>
      <div
        className={`pileSizeLabel ${pileSize > 99 ? 'bigPile' : ''}`}
        onClick={onSizeIndicatorClick}
      >
        {pileSize}
      </div>
    </div>
  );
};

export default Library;