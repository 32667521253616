import { Store } from 'pullstate';
import { AccessRequestLocationOptions, BattlefieldLocations, CardData, ContextMenuInstanceSources, FacedownCard, LEFT_MOUSE, MousePosition } from "../../../../../../shared/types";
import { blurInputs, getCardDistanceFromWindowBottom, setGlobalOnMouseMove, wasEventInsideRect } from "../../../../../../shared/utils";
import { CarouselId, CarouselStore } from '../../../../shared/CarouselStore';
import { UiStateStore } from '../../../../shared/UiStateStore';
import { showContextMenu } from '../../../ContextMenu';
import { resetCarouselHoverState, convertToDataSource, convertToCarouselIdentifier, isHoveringOpponentPileCarousel } from '../../CarouselUtils';
import { dismissContextMenu } from '../../../ContextMenu/ContextMenu';
import { GameStore } from '../../../../shared/GameStore';
import { checkToShowTrespassingOverlay } from '../../../PlayArea/PlayAreaUtils';

/* When the mouse enters a card in hand, sometimes the card preview is immediately hidden
 * because the mouse position is still registering as just outside the card boundaries.
 * Not sure why, but easiest fix is to just add a 1.5px buffer zone on the top & left edges.
 */
const BUFFER = 1.5;

// stored references to listeners so they can be removed
let onMouseMoveReference_hand: any = null;
let onMouseDownReference_hand: any = null;
let onRightClickReference_hand: any = null;

const clearPreviewListeners = () => {
  document.removeEventListener('mousemove', onMouseMoveReference_hand);
  document.removeEventListener('mousedown', onMouseDownReference_hand);
  document.removeEventListener('contextmenu', onRightClickReference_hand);
};

// stored reference to a pending action of hiding the card preview.
// The timeout allows smoothing out visuals when rapidly moving the cursor over the hand
// so that the card preview doesn't show/hide/show/hide at rapid speed.
let hideReference: any = null;

const shouldBlockDrag = (
  uiStateStore: Store<UiStateStore>,
  carouselStore: Store<CarouselStore>,
) => {
  const lockedZone = uiStateStore.getRawState().lockedZone;

  if (!lockedZone) return false;

  const hoveredCarouselId = carouselStore.getRawState().hoveredCarouselId;

  if (lockedZone === AccessRequestLocationOptions.Hand
      && hoveredCarouselId === CarouselId.Main) {
        return true;
  }

  const popupCarouselIsForLibrary = [BattlefieldLocations.TopNOfLibrary, BattlefieldLocations.Library].includes(carouselStore.getRawState().popupCarouselContent!);

  const libraryIsLocked = [AccessRequestLocationOptions.Library, AccessRequestLocationOptions.TopNOfLibrary].includes(lockedZone);

  if (libraryIsLocked && hoveredCarouselId === CarouselId.Popup && popupCarouselIsForLibrary) {
    return true;
  }

  return false;
};

// show the card, set its image and position, dismiss old listeners, define new listeners
export const showCardPreview = (
  mouseEnterEvent: any,
  card: CardData | FacedownCard,
  cardIndex: number, // the index of the card in the hand that is hovered
  carouselId: CarouselId, // which carousel we're hovering
  carouselStore: Store<CarouselStore>,
  uiStateStore: Store<UiStateStore>,
  gameStore: Store<GameStore>,
) => {
  mouseEnterEvent.preventDefault();
  clearInterval(hideReference);
  // Not strictly necessary probably since the listener removes itself but just in case
  clearPreviewListeners();

  // React's synthetic events get nullified if you don't manually persist them.
  // otherwise you can't reference their properties in callbacks later in execution
  mouseEnterEvent.persist();
  blurInputs();

  const boundingRect = mouseEnterEvent.target.getBoundingClientRect();
  // There's some code inside DraggablePreview.tsx that adjusts this left position
  // some more, fyi. this is misleading.
  // The misleading part is that we assign "cardMidpoint" to position.left just below
  const cardMidpoint = boundingRect.left + boundingRect.width / 2;

  carouselStore.update(s => {
    const activeCardOrigin = carouselId === CarouselId.Main
        ? BattlefieldLocations.Hand : s.popupCarouselContent;
    s.activeCardOrigin = convertToCarouselIdentifier(activeCardOrigin!);
    s.previewIsVisible = true;
    s.previewPosition = { left: cardMidpoint, bottom: '2vh' };
    s.cardData = card;
    s.hoveredCarouselId = carouselId;
    s.carouselHoverIndex = cardIndex;
  });

  uiStateStore.update(s => {
    s.keyboardShortcutTarget.hoveredCardId = card.id;
  });

  // onmove instead of onleave because the mouse is not actually "over" the element
  // and thus cannot "leave" it
  const onMouseMove = (moveEvent: any) => {
    moveEvent.preventDefault();
    // if we are now outside the card we hovered in the hand...
    if (moveEvent.clientX + BUFFER < boundingRect.left
      || moveEvent.clientX > boundingRect.right
      || (moveEvent.clientY + BUFFER) < boundingRect.top
      || moveEvent.clientY > boundingRect.bottom) {
        // hide DraggablePreview...
        hideReference = setTimeout(() => {
          resetCarouselHoverState(carouselStore);
          uiStateStore.update(s => {
            s.keyboardShortcutTarget.hoveredCardId = null;
          });
        }, 20);
        // and remove the hover listener as cleanup.
        clearPreviewListeners();
    }
  };

  const onMouseDown = (mouseDownEvent: any) => {
    if (mouseDownEvent.button !== LEFT_MOUSE) return;
    if (isHoveringOpponentPileCarousel(carouselStore)) return;
    if (shouldBlockDrag(uiStateStore, carouselStore)) {
      return;
    }

    dismissContextMenu(uiStateStore, carouselStore);

    mouseDownEvent.preventDefault();
    clearPreviewListeners();

    const mouseDistanceFromBottomOfCard = mouseDownEvent.target.getBoundingClientRect().bottom - mouseDownEvent.clientY;

    carouselStore.update(s => {
      s.isDraggingCard = true;
      s.previewPosition.bottom = getCardDistanceFromWindowBottom(
        mouseDownEvent.clientY,
        mouseDistanceFromBottomOfCard
      );
    });

    // clear this immediately on mousedown cuz we don't want some bizarreness
    // with triggering keyboard shortcuts while we're dragging it
    uiStateStore.update(s => {
      s.keyboardShortcutTarget.hoveredCardId = null;
    });

    setGlobalOnMouseMove(mouseDownEvent, (newMousePosition, moveTarget) => {
      const left = newMousePosition.x;
      const bottom = getCardDistanceFromWindowBottom(
        newMousePosition.y,
        mouseDistanceFromBottomOfCard
      );

      carouselStore.update(s => {
        s.previewPosition = {
          left,
          bottom,
        };
      });

      checkToShowTrespassingOverlay(moveTarget, uiStateStore);
      handleCarouselGhost(newMousePosition, carouselStore);
    }, true);
  };

  const onRightClick = (clickEvent: any) => {
    clearPreviewListeners();

    const carouselData = carouselStore.getRawState();

    showContextMenu(
      clickEvent,
      ContextMenuInstanceSources.CarouselCard,
      uiStateStore,
      {
        cardIds: [ carouselData.cardData!.id ],
        gathererUrl: carouselData.cardData!.gathererUrl,
        origin: convertToDataSource(carouselData.activeCardOrigin),
        showAltFaceMenuOption: !!carouselData.cardData!.altFaceUrl,
        // if there's no image url, it's facedown
        isFacedown: !carouselData.cardData!.url,
        mirrorMode: gameStore.getRawState().mirrorMode,
      },
    );
  };

  // Keep reference so we can remove it later
  onMouseMoveReference_hand = onMouseMove;
  onMouseDownReference_hand = onMouseDown;
  onRightClickReference_hand = onRightClick;
  document.addEventListener('mousemove', onMouseMove);
  document.addEventListener('mousedown', onMouseDown);
  document.addEventListener('contextmenu', onRightClick);
};

// These 3 variables are used for slightly delaying hoverghosts so that
// the carousel doesnt flicker when something is dragged past it on the way to somewhere else
let isWaitingForDelay = true;
let turnOffDelayRef: any = null;
let previousDragLocation: CarouselId | null = null;

const resetHoverDelay = () => {
  clearTimeout(turnOffDelayRef);
  turnOffDelayRef = null;
  isWaitingForDelay = true;
};

export const handleCarouselGhost = (
  mousePosition: { x: number; y: number },
  carouselStore: Store<CarouselStore>,
) => {
  const [hoveredCarouselId, children] = determineCarouselId(mousePosition);
  if (hoveredCarouselId === null) {
    previousDragLocation = null;
    resetHoverDelay();
    carouselStore.update(s => {
      s.carouselHoverIndex = null;
      s.hoveredCarouselId = null;
    });
    return;
  }

  if (hoveredCarouselId !== previousDragLocation) {
    resetHoverDelay();
    previousDragLocation = hoveredCarouselId;
  }

  if (isWaitingForDelay) {
    // console.log('waiting for delay');
    if (!turnOffDelayRef) {
      turnOffDelayRef = setTimeout(() => {
        // console.log('disabling delay');
        isWaitingForDelay = false;
      }, 100);
    }
    return;
  }
  // console.log('proceeding');

  const previewState = carouselStore.getRawState();
  const activeCardOrigin = previewState.activeCardOrigin;

  // Don't show hover ghost in popup carousel if we're dragging a card from
  // the top of the carousel's current source pile
  // This is somewhat confusing for the user. We should somehow visually indicate that
  // the carousel isn't valid for dropping when dragging a card from the top of that pile.
  // (The TopNOfLibrary clause is because the carousel/data source enum values are the same,
  // so it would set this stuff to null and block us from reordering the TopN carousel.)
  if (hoveredCarouselId === CarouselId.Popup &&
     (activeCardOrigin === convertToDataSource(previewState.popupCarouselContent)
     && activeCardOrigin !== BattlefieldLocations.TopNOfLibrary)) {
    carouselStore.update(s => {
      s.carouselHoverIndex = null;
      s.hoveredCarouselId = null;
    });
    return;
  }

  const previousCarouselId = previewState.hoveredCarouselId;
  // If we're not over the same carousel as was previously hovered,
  // then the previousHoverIndex doesn't matter and should be treated as null now
  const previousHoverIndex = previousCarouselId === hoveredCarouselId
    ? previewState.carouselHoverIndex : null;
  const hoverIndex = determineHoverIndex(children, previousHoverIndex, mousePosition);

  if (hoverIndex !== previousHoverIndex) {
    carouselStore.update(s => {
      s.carouselHoverIndex = hoverIndex;
      s.hoveredCarouselId = hoveredCarouselId;
    });
  }
};

const determineCarouselId = (
  mousePosition: MousePosition
) => {
  const mainCarouselContainer: Element = document.getElementsByClassName(
    'mainCarousel'
  )[0];

  const isInsideMainCarousel = wasEventInsideRect({
    clientX: mousePosition.x,
    clientY: mousePosition.y
  }, mainCarouselContainer);

  let children: any = [];
  if (isInsideMainCarousel) {
    children = Array.from(
      document.querySelectorAll('.mainCarousel .carouselCardWrapper')
    );
    return [CarouselId.Main, children];
  } else {
    const popupCarouselContainer: Element = document.getElementsByClassName(
      'popupCarousel'
    )[0];

    const isInsidePopupCarousel = wasEventInsideRect({
      clientX: mousePosition.x,
      clientY: mousePosition.y
    }, popupCarouselContainer);

    if (isInsidePopupCarousel) {
      children = Array.from(
        document.querySelectorAll('.popupCarousel .carouselCardWrapper')
      );
      return [CarouselId.Popup, children];
    } else {
      return [null, children];
    }
  }
};

const determineHoverIndex = (
  children: Element[],
  previousHoverIndex: number | null,
  mousePosition: MousePosition
) => {
  // starting assumption is that it's in the rightmost position,
  // then we iterate through the cards from left to right seeing if it's further left
  let hoverIndex = children.length;

  for (let n = 0; n < children.length; n++) {
    const card = children[n];
    const boundingRect = card.getBoundingClientRect();
    if (n === 0) {
      const cursorIsLeftOfCard = mousePosition.x <= boundingRect.left;
      if (cursorIsLeftOfCard) {
        hoverIndex = 0;
        break;
      }
    }
    const cursorIsOverCard = wasEventInsideRect({
      clientX: mousePosition.x,
      clientY: mousePosition.y,
    }, card);

    if (cursorIsOverCard && n === previousHoverIndex) {
      hoverIndex = n;
      break;
    }

    const cardMidpoint = boundingRect.left + boundingRect.width / 2;;
    const cursorIsLeftOfMidpoint = mousePosition.x <= cardMidpoint;

    if (cursorIsLeftOfMidpoint) {
      hoverIndex = n;
      break;
    }
  }

  return hoverIndex;
};