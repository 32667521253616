import { Store } from 'pullstate';
import { AppStoreType } from '../../../shared/AppStore';
import ErrorUtils from '../../../shared/ErrorUtils';
import { socket } from '../../../shared/SocketInit';
import { ClientActions } from '../../../shared/SocketEnums';
import { WaitingRoomExperienceLevel, GameFormat } from '../../../shared/types';
import { getAuth } from 'firebase/auth';

const launchGame = () => {
  socket.emit(ClientActions.LAUNCH_GAME, ErrorUtils.handleLaunchGameError);
};

const loadWaitingRooms = (setRooms: Function, setLoading: Function, appStore: Store<AppStoreType>) => {
  socket.emit(ClientActions.LOAD_WAITING_ROOMS, (response: any) => {
    if (response.error) {
      ErrorUtils.handleGenericError(response);
      setLoading(false);
      return;
    }

    appStore.update(s => { s.currentRoom = response.currentRoom; });
    setRooms(response.waitingRooms);
    setLoading(false);

    if (!response.currentRoom) {
      // stop asking if we're sure we want to leave (set in Battlefield.tsx on component mount)
      window.onbeforeunload = null;
    }
  });
};

const joinWaitingRoom = (
  roomId: string,
  password: string,
) => {
  const username = getAuth().currentUser?.displayName;
  socket.emit(ClientActions.JOIN_WAITING_ROOM, { roomId, username, password, },
    ErrorUtils.handleJoinWaitingRoomError);
};

const createWaitingRoom = (
  options: {
    title: string;
    password: string;
    maxPlayers: number;
    gameFormat: GameFormat;
    experienceLevel: WaitingRoomExperienceLevel;
    mirrorMode: boolean;
    username: string;
  },
) => {
  // just to prevent jokers from text-crashing server with War and Peace
  if (options.title.length > 50) {
    options.title = options.title.slice(0,50);
  }
  socket.emit(ClientActions.CREATE_WAITING_ROOM, options,
    ErrorUtils.handleCreateWaitingRoomError
  );
};

const updateWaitingRoom = (
  options: {
    title: string;
    password: string;
    maxPlayers: number;
    gameFormat: GameFormat;
    experienceLevel: WaitingRoomExperienceLevel;
    mirrorMode: boolean;
  },
) => {
  // just to prevent jokers from text-crashing server with War and Peace
  if (options.title.length > 50) {
    options.title = options.title.slice(0,50);
  }
  socket.emit(ClientActions.UPDATE_WAITING_ROOM, options,
    ErrorUtils.handleUpdateWaitingRoomError
  );
};

const sendChatMessage = (message: string) => {
  socket.emit(ClientActions.SEND_ROOM_MESSAGE, { message },
    ErrorUtils.handleSendRoomChatError
  );
};

const leaveWaitingRoom = () => {
  socket.emit(ClientActions.LEAVE_WAITING_ROOM, ErrorUtils.handleGenericError);
};

export default {
  launchGame,
  loadWaitingRooms,
  createWaitingRoom,
  updateWaitingRoom,
  joinWaitingRoom,
  sendChatMessage,
  leaveWaitingRoom,
};