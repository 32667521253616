import React from 'react';
import { useNavigate } from 'react-router-dom';
import { getAuth } from 'firebase/auth';
import { WaitingRoom } from '../../../../shared/types';
import DeckSelector from '../DeckSelector';
import { Button, IconButton } from '@mui/material';
import SettingsIcon from '@mui/icons-material/Settings';
import LobbyApi from '../../shared/LobbyApi';
import BattlefieldApi from '../../../Battlefield/utils/BattlefieldApi';
import { AppStore } from '../../../../shared/AppStore';

type RoomControlsPanelProps = {
  room: WaitingRoom;
  readyForLaunch: boolean;
};

const RoomControlsPanel = ({ room, readyForLaunch }: RoomControlsPanelProps) => {
  const navigate = useNavigate();
  const userId = getAuth().currentUser?.uid;
  const isHost = userId === room.hostUserId;

  if (room.gameLaunched) {
    return (
      <div className="roomControlsPanel gameStarted">
        <Button
          className="rejoinGameButton"
          variant="contained"
          size="small"
          onClick={() => {
            navigate('/game');
          }}
        >
          Return to game
        </Button>
        <Button
          className="exitGameButton"
          variant="contained"
          size="small"
          onClick={()=> {
            // we need the callback arg for exit from in-game, but if you don't give it a value,
            // calling socket.emit with an undefined second arg leads it to not hit the server at all.
            BattlefieldApi.exitGame(() => {});
          }}
        >
          Exit game
        </Button>
      </div>
    );
  }

  return (
    <div className="roomControlsPanel">
      <div className={`middleRowContainer ${isHost ? '' : 'centerSelector'}`}>
        <DeckSelector format={room.gameFormat}/>

        {isHost &&
          <IconButton
            className="updateGameButton"
            onClick={() => {
              AppStore.update(s => {
                s.createGameDialog.isOpen = true;
                s.createGameDialog.isUpdateMode = true;
              });
            }}
          >
            <SettingsIcon/>
          </IconButton>
        }
      </div>
  
      <div className="bottomRowContainer">
        {isHost &&
          <Button
            className="launchGameButton"
            variant="contained"
            size="small"
            onClick={LobbyApi.launchGame}
          >
            Launch Game
          </Button>
        }
    
        <Button
          className="leaveGameButton"
          variant="contained"
          size="small"
          onClick={() => {
            if (confirm('Are you sure you want to leave?')) {
              LobbyApi.leaveWaitingRoom();
            }
          }}
        >
          Leave
        </Button>
      </div>
    </div>
  );
};

export default RoomControlsPanel;