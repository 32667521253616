import React, { useContext } from 'react';
import { getAuth } from 'firebase/auth';
import { BattlefieldLocations } from '../../../../shared/types';
import BattlefieldApi from '../../utils/BattlefieldApi';
import Library from '../Library/Library';
import OtherPlayerLibrary from '../OtherPlayerLibrary';

import { IconButton, Tooltip } from '@mui/material';
import BlenderIcon from '@mui/icons-material/Blender';
import PlusOneIcon from '@mui/icons-material/PlusOne';
import BattlefieldChat from '../BattlefieldChat';
import { GameStoreContext } from '../../shared/GameStore';

import './RightSidebar.less';

const RightSidebar = () => {

  const gameStore = useContext(GameStoreContext);
  const userId = getAuth().currentUser!.uid;
  const librarySize = gameStore.useState(s => s.publicCardData[userId].librarySize);

  const disableShuffleButton = librarySize < 2;
  const disableDrawButton = librarySize === 0;

  return (
    <div className="rightSidebar">
      <div className="topThird">
        <OtherPlayerLibrary/>
      </div>

      <div className="middleThird">
        <BattlefieldChat/>
      </div>

      <div className="bottomThird">

        {/* Draw Card To Hand */}
        <Tooltip title="Draw Card To Hand" placement="top" enterDelay={500}>
          <IconButton disabled={disableDrawButton} className="utilityButton" onClick={(clickEvent: any) => {
            BattlefieldApi.drawNCardsFromLibraryToDestination(
              clickEvent.shiftKey ? 5 : 1,
              BattlefieldLocations.Hand,
            );
          }}>
            <PlusOneIcon/>
          </IconButton>
        </Tooltip>

        <Library/>

        {/* Shuffle Library */}
        <Tooltip title="Shuffle" enterDelay={500}>
          <IconButton disabled={disableShuffleButton} className="utilityButton" onClick={() => {
            BattlefieldApi.shuffleLibrary();
          }}>
            <BlenderIcon/>
          </IconButton>
        </Tooltip>

      </div>
    </div>
  );
};

export default RightSidebar;