import React, { useContext, useEffect, useRef } from 'react';
import { CardInPlay, ContextMenuInstanceSources } from '../../../../../../shared/types';
import { usePrevious } from '../../../../../../shared/utils';
import { GameStoreContext } from '../../../../shared/GameStore';
import { UiStateStoreContext } from '../../../../shared/UiStateStore';
import { showContextMenu } from '../../../ContextMenu';
import { cancelMagnification, setMagnifiedCardDetails, nullifyUiStoreSelectionState } from '../../PlayAreaUtils';
import PowerToughnessModifier from '../PowerToughnessModifier';
import './OtherPlayerCard.less';

type OtherPlayerCardProps = {
  card: CardInPlay;
  playAreaRef: any;
  noMirror?: boolean;
};

const OtherPlayerCard = React.memo(({
  card,
  playAreaRef,
  noMirror,
}: OtherPlayerCardProps) => {
  if (!playAreaRef) return null;

  const uiStateStore = useContext(UiStateStoreContext);
  const gameStore = useContext(GameStoreContext);

  const previousCardState = usePrevious(card);
  const cardRef = useRef<any>(null);

  const mirrorMode = gameStore.useState(s => s.mirrorMode);

  // mirror tap/untap actions
  useEffect(() => {
    if (!previousCardState) return;
    if (!previousCardState.tapped && card.tapped) {
      cardRef.current.classList.add('tapped');
    } else if (previousCardState.tapped && !card.tapped) {
      cardRef.current.classList.remove('tapped');
    }

    if (card.isHighlighted && card.isHighlighted !== previousCardState.isHighlighted) {
      cardRef.current.classList.add('highlighted');
      setTimeout(() => {
        cardRef.current.classList.remove('highlighted');
      }, 400);
    }
  });

  const onMouseEnter = (mouseEnterEvent: any) => {
    if (uiStateStore.getRawState().isDragSelecting) return;
    if (card.isFacedown) {
      uiStateStore.update(s => {
        s.keyboardShortcutTarget.hoveredCardId = card.id;
      });
      return; // can't view opponent's facedown cards
    }

    setMagnifiedCardDetails(card, cardRef, uiStateStore);
  };

  const onMouseLeave = (mouseLeaveEvent: any) => {
    cancelMagnification(uiStateStore);
  };

  const onRightClick = (clickEvent: any) => {
    cancelMagnification(uiStateStore);

    // deselect any cards we had selected when right-clicking an opponent card
    nullifyUiStoreSelectionState(uiStateStore);

    showContextMenu(
      clickEvent,
      ContextMenuInstanceSources.OtherPlayerPlayAreaCard,
      uiStateStore,
      { cardIds: [ card.id ], gathererUrl: card.gathererUrl, mirrorMode },
    );
  };

  // if it's been tapped since previous load, or is tapped on initial load,
  // display it in tapped state from the start with no animation.
  const tapped = !!(previousCardState?.tapped && card.tapped) ||
  (!previousCardState && card.tapped);

  if (!card.ref) {
    card.ref = cardRef;
  }

  return (
    <div
      ref={cardRef}
      onContextMenu={onRightClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      className={`${tapped ? 'tapped' : ''} cardInPlay otherPlayerCard`}
      style={{
        left: card.left + '%',
        bottom: noMirror ? '' : card.top + '%',
        top: noMirror ? card.top + '%' : '',
        zIndex: card.zIndex,
      }}
    >
      <div className={`flipper ${card.isFacedown ? 'flipped' : ''}`}>
        <div className="front">
          <div className={`flipper ${card.showAltFace ? 'flipped' : ''}`}>
            <div className="front" style={{ backgroundImage: `url("${card.url}")`}}/>
            {card.altFaceUrl &&
            <div className="back" style={{ backgroundImage: `url("${card.altFaceUrl}")`}}/>}
          </div>
        </div>
        <div className="back faceDownCardInPlay"/>
      </div>

      {card.showPTModifier &&
        <PowerToughnessModifier
          cardIds={[card.id]}
          powerModifier={card.powerModifier}
          toughnessModifier={card.toughnessModifier}
        />
      }
    </div>
  );
});

export default OtherPlayerCard;