import React, { useContext } from 'react';
import { Alert } from '@mui/material';
import { UiStateStoreContext } from '../../../../shared/UiStateStore';
import { GameStoreContext } from '../../../../shared/GameStore';
import './TrespassingOverlay.less';

const TrespassingOverlay = () => {
  const uiStateStore = useContext(UiStateStoreContext);
  const gameStore = useContext(GameStoreContext);

  const otherPlayerId = uiStateStore.useState(s => s.currentOtherPlayerId);
  const otherPlayerUsername = gameStore.useState(s => s.waitingRoom)?.usernamesByUserId[otherPlayerId];

  const numPlayers = Object.keys(gameStore.useState(s => s.publicCardData)).length;
  const mirrorMode = gameStore.useState(s => s.mirrorMode);
  const isTrespassing = uiStateStore.useState(s => s.isTrespassing);

  if (mirrorMode || numPlayers < 3) return null;
  if (!isTrespassing) return null;

  return (
    <div className="trespassingOverlay">
      <div className="dottedBorder"/>
      <div className="floatingNotification">
        <Alert severity="info">
          {`Placing an individual card on ${otherPlayerUsername}'s side of the battlefield will cause it to be displayed alongside their cards for all other players.`}
        </Alert>
      </div>
    </div>
  );
};

export default TrespassingOverlay;