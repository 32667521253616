import React, { useContext, useRef } from 'react';
import { getAuth } from 'firebase/auth';
import { BattlefieldLocations } from '../../../../shared/types';
import { GameStoreContext } from '../../shared/GameStore';
import { UiStateStoreContext } from '../../shared/UiStateStore';
import { showContextMenu } from '../ContextMenu';
import { cancelMagnification, setMagnifiedCardDetails } from '../PlayArea/PlayAreaUtils';
import './OtherPlayerLibrary.less';

const OtherPlayerLibrary = () => {
  const userId = getAuth().currentUser!.uid;
  const uiStateStore = useContext(UiStateStoreContext);
  const gameStore = useContext(GameStoreContext);

  const mirrorMode = gameStore.useState(s => s.mirrorMode);
  const publicCardData = gameStore.useState(s => s.publicCardData);

  const _otherPlayerUserId = uiStateStore.useState(s => s.currentOtherPlayerId);
  const otherPlayerUserId = mirrorMode ? userId : _otherPlayerUserId;

  const librarySize = publicCardData[otherPlayerUserId]?.librarySize || 0;
  const handSize = publicCardData[otherPlayerUserId]?.handSize || 0;

  const revealedTopCard = publicCardData[otherPlayerUserId]?.revealedTopCardOfLibrary;
  // this is relying on the null check in the style prop on the div
  const revealedToAllUrl = revealedTopCard?.showAltFace ? revealedTopCard?.altFaceUrl : revealedTopCard?.url;

  const topCardRef = useRef(null);

  const onRightClick = (clickEvent: any) => {
    showContextMenu(clickEvent, BattlefieldLocations.OtherPlayerHand, uiStateStore, {
      handIsEmpty: handSize === 0,
      libraryIsEmpty: librarySize === 0,
      mirrorMode,
    });
  };

  const style = {
    backgroundImage: revealedToAllUrl ? `url("${revealedToAllUrl}")` : '',
    visibility: librarySize > 0 ? undefined : 'hidden',
  } as React.CSSProperties; // fixes stupid TS bug as usual

  return (
    <div
      className="otherPlayerLibrary pileContainer"
      onContextMenu={onRightClick}
    >
      <div className="pileLabel">
        Library
      </div>
        <div
          ref={topCardRef}
          style={style}
          className="pileCard"
          onMouseEnter={(e) => {
            if (revealedToAllUrl) {
              setMagnifiedCardDetails(revealedTopCard, topCardRef, uiStateStore);
            }
          }}
          onMouseLeave={(e) => {
            cancelMagnification(uiStateStore);
          }}
          onContextMenu={() => {}}
        />
      <div className={`pileSizeLabel ${librarySize > 99 ? 'bigPile' : ''}`}>
        {librarySize}
      </div>
    </div>
  );
};

export default OtherPlayerLibrary;