import { Store } from 'pullstate';
import { BattlefieldLocations } from '../../../../../shared/types';
import { CarouselStore } from '../../../shared/CarouselStore';
import { UiStateStore } from '../../../shared/UiStateStore';
import BattlefieldApi from '../../../utils/BattlefieldApi';

export const expandPile = (
  source: BattlefieldLocations,
  carouselStore: Store<CarouselStore>,
  uiStateStore: Store<UiStateStore>,
) => {
  if (source === BattlefieldLocations.Library) {
    BattlefieldApi.openLibrary();
  }

  // Whenever we switch what the popup carousel is viewing, we close off the data
  // for the library and/or facedown exile pile and any exposed opponent data
  BattlefieldApi.closeRevealedViews(true, source !== BattlefieldLocations.Library);

  carouselStore.update(s => {
    s.isDrawerOpen = true;
    s.popupCarouselContent = source as BattlefieldLocations;
    s.isViewingLibraryFacedown = false;
  });
};