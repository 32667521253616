import React, { useContext, useEffect, useRef, useState } from 'react';
import { getAuth } from 'firebase/auth';
import { BattlefieldLocations, CardInPlay, DRAG_ZINDEX, DropEventData, DropReceivedEventData, LEFT_MOUSE } from '../../../../shared/types';
import { blurInputs, clearDragListener, copyCardData, removeCardsOrReturnToOriginalLocation, setGlobalOnMouseMove } from '../../../../shared/utils';
import Dropzone from '../../shared/Dropzone';
import { GameStoreContext } from '../../shared/GameStore';
import { CarouselStoreContext } from '../../shared/CarouselStore';
import { showContextMenu } from '../ContextMenu';
import { UiStateStoreContext } from '../../shared/UiStateStore';
import { resetCarouselHoverState } from '../CardCarousel/CarouselUtils';
import { cancelMagnification, checkToShowTrespassingOverlay, setMagnifiedCardDetails } from '../PlayArea/PlayAreaUtils';
import { expandPile } from '../ContextMenu/ContextMenuOptions/GenericMenuOptions';
import './Pile.less';

type PileProps = {
  className: string;
  isOtherPlayerPile: boolean;
  location: BattlefieldLocations;
};

const Pile = ({ className, isOtherPlayerPile, location }: PileProps) => {
  const carouselStore = useContext(CarouselStoreContext);
  const uiStateStore = useContext(UiStateStoreContext);
  const gameStore = useContext(GameStoreContext);

  const mirrorMode = gameStore.useState(s => s.mirrorMode);
  const flippedCardIds = uiStateStore.useState(s => s.flippedCardIds);
  const otherPlayerId = uiStateStore.useState(s => s.currentOtherPlayerId);
  const topCardRef = useRef(null);

  const publicCardData = useContext(GameStoreContext).useState(s => s.publicCardData);
  let userId = getAuth().currentUser!.uid;

  if (isOtherPlayerPile && !mirrorMode) {
    userId = otherPlayerId;
  }

  const playerPublicCardData = publicCardData[userId];

  const [isHovered, setIsHovered] = useState(false);
  const [isDragging, setIsDragging] = useState(false);
  const [topCardPosition, setTopCardPosition] = useState({ left: 0, top: 0 });
  const [pileContents, setPileContents] = useState(
    location === BattlefieldLocations.Graveyard || location == BattlefieldLocations.OtherPlayerGraveyard
    ? playerPublicCardData.graveyard : playerPublicCardData.exile
  );

  const topCard = pileContents[0] || null;
  const secondCardFromTop = pileContents[1] || null;

  useEffect(() => {
    setPileContents(location === BattlefieldLocations.Graveyard || location == BattlefieldLocations.OtherPlayerGraveyard
      ? playerPublicCardData.graveyard : playerPublicCardData.exile);
  }, [playerPublicCardData]);

  // This allows someone to hover a pile and use the "Send to..." keyboard
  // shortcuts on the top card over and over. Each time the topcard changes,
  // as long as we're still hovering the pile, we set it to be the new shortcut target.
  useEffect(() => {
    if (isHovered) {
      if (topCard) {
        setMagnifiedCardDetails(topCard, topCardRef, uiStateStore);
      } else {
        cancelMagnification(uiStateStore);
      }
    }
  }, [topCard]);

  const onMouseDown = (mouseDownEvent: any) => {
    if (mouseDownEvent.button !== LEFT_MOUSE) {
      return;
    }

    blurInputs();
    cancelMagnification(uiStateStore);

    if (isOtherPlayerPile) return;
    mouseDownEvent.preventDefault();

    setTopCardPosition({
      left: mouseDownEvent.target.getBoundingClientRect().left,
      top: mouseDownEvent.target.getBoundingClientRect().top
    });
    setIsDragging(true);

    carouselStore.update(s => {
      s.activeCardOrigin = location;
      s.isDraggingCard = true;
    });

    const mouseDistanceFromTopOfCard = mouseDownEvent.clientY - mouseDownEvent.target.getBoundingClientRect().top;
    const mouseDistanceFromLeftOfCard = mouseDownEvent.clientX - mouseDownEvent.target.getBoundingClientRect().left;

    setGlobalOnMouseMove(mouseDownEvent, (newMousePosition, moveTarget) => {
      checkToShowTrespassingOverlay(moveTarget, uiStateStore);
      setTopCardPosition({
        left: newMousePosition.x - mouseDistanceFromLeftOfCard,
        top: newMousePosition.y - mouseDistanceFromTopOfCard
      });
    });
  };

  const onMouseUp = (mouseUpEvent: any) => {
    if (mouseUpEvent.button !== LEFT_MOUSE) {
      return;
    }
    if (isOtherPlayerPile) return;
    mouseUpEvent.preventDefault();
    mouseUpEvent.persist();

    if (!isDragging) return;
    clearDragListener();
    uiStateStore.update(s => {
      s.isTrespassing = false;
    });

    // because the card snaps back to the "top of the pile", we have to save its location
    // or else the playArea miscalculates where it thinks it was dropped
    const locationAtTimeOfDrop = mouseUpEvent.target.getBoundingClientRect();

    // setTimeout so that the data can be used for the CardsDropped event before reset
    // LATER figure out why PlayAreaCard works without this, and adapt this to match that
    setTimeout(() => {
      resetCarouselHoverState(carouselStore);
      cancelMagnification(uiStateStore);
    }, 30);

    removeCardsOrReturnToOriginalLocation(
      {
        clientX: mouseUpEvent.clientX,
        clientY: mouseUpEvent.clientY,
        target: {
          getBoundingClientRect: () => locationAtTimeOfDrop
        },
        shiftKey: mouseUpEvent.shiftKey,
      },
      location,
      [topCard!],
      (dropData: DropReceivedEventData) => {
        const newContents = pileContents.filter(card => !dropData.cardIds.includes(card.id));
        setIsDragging(false);
        setPileContents(newContents);
      },
      () => {
        setIsDragging(false);
      }
    );
  };

  const onMouseEnter = (mouseEnterEvent: any) => {
    if (isDragging) return;
    setIsHovered(true);

    setMagnifiedCardDetails(topCard, topCardRef, uiStateStore);
  };

  // This is attached to both the topCard AND the pileContainer, because if the
  // pile is empty and we mouseOut, topCard won't trigger it because it isn't rendered
  const onMouseLeave = (mouseLeaveEvent: any) => {
    cancelMagnification(uiStateStore);
    setIsHovered(false);
  };

  const onCardDrop = (dropData: DropEventData) => {
    // remove any tokens in case the cards came from the play area
    let droppedCards = (dropData.cards as CardInPlay[])
        .filter(card => !card.isToken)
        .map(card => copyCardData(card));

    const newContents = droppedCards.concat(pileContents);
    setPileContents(newContents);

    return newContents;
  };

  const onSizeIndicatorClick = () => {
    if (mirrorMode && isOtherPlayerPile) return;
    expandPile(location, carouselStore, uiStateStore);
  };
  
  let topCardStyle: React.CSSProperties = {};
  let secondCardStyle: React.CSSProperties = {};

  // this is the same as checking that length !== 0, except it also
  // null-checks topCard so we don't need to use "!" on every reference
  if (topCard) {
    const topCardImageUrl = flippedCardIds[topCard.id] ? topCard.altFaceUrl : topCard.url;
    // if there is no second card, this variable doesn't get used. See secondCardStyle.
    const secondCardImageUrl = flippedCardIds[secondCardFromTop?.id]
      ? secondCardFromTop.altFaceUrl : secondCardFromTop?.url;

    topCardStyle = isDragging ? {
      position: 'fixed',
      zIndex: DRAG_ZINDEX,
      left: topCardPosition.left,
      top: topCardPosition.top,
      boxShadow: 'none',
      backgroundImage: `url("${topCardImageUrl}")`,
    } : {
      backgroundImage: `url("${topCardImageUrl}")`,
    };

    secondCardStyle = secondCardFromTop ? {
      backgroundImage: `url("${secondCardImageUrl}")`,
    } : {};
  }

  return (
    <div className={'pileContainer ' + className} onMouseLeave={onMouseLeave} onContextMenu={(e) => { e.preventDefault(); }}>
      <div className="pileLabel">
        {location === BattlefieldLocations.Graveyard || location == BattlefieldLocations.OtherPlayerGraveyard ? 'Graveyard' : 'Exile'}
      </div>

      <Dropzone location={location} disabled={isOtherPlayerPile} onCardDrop={onCardDrop}>
        {pileContents.length > 0 &&
          <div
            style={topCardStyle}
            ref={topCardRef}
            className="pileCard"
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            onMouseDown={onMouseDown}
            onMouseUp={onMouseUp}
            onContextMenu={(eventData: any) => {
              cancelMagnification(uiStateStore);
              showContextMenu(eventData, location, uiStateStore, { mirrorMode });
            }}
          />}
        {/* only show this when we start dragging, for now */}
        {isDragging && pileContents.length > 1 &&
          <div className="pileCard" style={secondCardStyle} />}
      </Dropzone>

      <div
        className={`pileSizeLabel ${pileContents.length > 99 ? 'bigPile' : ''}`}
        onClick={onSizeIndicatorClick}
      >
        {pileContents.length}
      </div>
    </div>
  );
};

export default Pile;