import React, { useContext, useEffect, useState } from 'react';
import { BattlefieldLocations, CardData, CardInPlay, DropEventData, FacedownCard, MousePosition, Topics } from '../../../../shared/types';
import CarouselCard from './components/CarouselCard';
import Dropzone from '../../shared/Dropzone';
import { GameStoreContext } from '../../shared/GameStore';
import { CarouselId, CarouselStoreContext } from '../../shared/CarouselStore';
import { handleCarouselGhost } from './components/DraggablePreview/DraggablePreviewUtils';
import { clearDragListener, copyCardData, usePrevious } from '../../../../shared/utils';
import { convertToCarouselIdentifier, getCarouselContent, getPopupCarouselLabel, haveCardsChanged, hidePreviewOnWindowLeave, isCarouselLocked, isHoveringOpponentPileCarousel, processCarouselCards, resetCarouselHoverState } from './CarouselUtils';
import { showContextMenu } from '../ContextMenu';
import { UiStateStoreContext } from '../../shared/UiStateStore';
import { closeDrawer } from '../CarouselDrawer/CarouselDrawer';
import CarouselFilter from './components/CarouselFilter';
import { Tooltip } from '@mui/material';

import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import SortIcon from '@mui/icons-material/Sort';

import './CardCarousel.less';
import BattlefieldApi from '../../utils/BattlefieldApi';

type CardCarouselProps = {
  contentSource: BattlefieldLocations | null;
  carouselId: CarouselId;
};

const CardCarousel = React.memo(({
  contentSource,
  carouselId,
}: CardCarouselProps) => {
  if (!contentSource) return null;

  const uiStateStore = useContext(UiStateStoreContext);
  const carouselStore = useContext(CarouselStoreContext);
  const gameStore = useContext(GameStoreContext);

  const carouselData = carouselStore.useState();
  const gameState = gameStore.useState();
  const [filterText, setFilterText] = useState('');

  const currentOtherPlayerId = uiStateStore.useState(s => s.currentOtherPlayerId);
  const cards: CardData[] | FacedownCard[] = getCarouselContent(
    contentSource,
    currentOtherPlayerId,
    carouselData.isViewingLibraryFacedown,
    gameState,
  ) || [];

  const previousCards = usePrevious(cards);
  const cardsHaveChanged = haveCardsChanged(cards, previousCards);

  const isLocked = isCarouselLocked(contentSource, gameStore);
  const shouldAllowDrop = !isLocked && !isHoveringOpponentPileCarousel(carouselStore);

  const processedCards = processCarouselCards(cards, cardsHaveChanged, carouselId,
    shouldAllowDrop, carouselData, filterText);

  const sizeLabel = gameState.privateCardData.hand.length;

  if (cardsHaveChanged) {
    resetCarouselHoverState(carouselStore);
  }

  // Clear filter whenever we close or open the carousel
  useEffect(() => {
    setFilterText('');
  }, [contentSource]);

  useEffect(() => {
    hidePreviewOnWindowLeave(carouselStore, uiStateStore);
    PubSub.subscribe(Topics.CardIsBeingDragged,
      (topic: string, newMousePosition: MousePosition) => {
        handleCarouselGhost(newMousePosition, carouselStore);
      }
    );
  }, []);

  const onCardDrop = (dropData: DropEventData) => {
    let droppedCards: CardData[] | FacedownCard[] = [];

    if (carouselData.isViewingLibraryFacedown && carouselId === CarouselId.Popup) {
      droppedCards = dropData.cards.filter(
        (card: any) => !card.isToken
      ).map(card => ({ id: card.id }));
    } else {
      // The data may be in the form of CardInPlay, not CardData. Need to convert.
      // Additionally, remove any tokens - they are deleted automatically if dragged
      // out of the playArea
      droppedCards = dropData.cards.filter(
        (card: any) => !card.isToken
      ).map(card => copyCardData(card));
    }

    // If we try dropping tokens, which are deleted immediately above, then the cards 
    // appear not to have change since we didn't add anything, nor does any server update come in.
    // So we need to catch that case and manually reset carousel hover state.
    const droppedCardsConsistedOnlyOfTokens = (dropData.cards as CardInPlay[])
      .every(card => card.isToken);

    // If we're moving a card around within the carousel (reordering),
    // there will only be one -- so make sure to filter that one out before adding it.
    // (droppedCards.length could be 0 if we tried dropping a token)
    let updatedCards = droppedCards.length > 0 ? [
      ...(cards.filter(card => (card.id !== droppedCards[0].id)))
    ] : [...cards];

    // Insert dropped cards at the location of the hover index
    updatedCards.splice(carouselData.carouselHoverIndex!, 0, ...droppedCards);

    // If a card is taken out and then immediately put back in the same place,
    // DraggablePreview.tsx's onMouseUp would instruct onPreviewDragEnd to set
    // carouselStore.waitingForUpdate to true. But when the update comes,
    // the cards haven't changed so the waitingForUpdate useEffect does nothing.
    // So we detect that case here and use this flag to stop that from happening.
    if (!haveCardsChanged(cards.filter(card => !card.isGhost), updatedCards)
      && !droppedCardsConsistedOnlyOfTokens) {
      carouselStore.update(s => {
        s.cardWasPlacedInSameSpot = true;
      });
    } else if (droppedCardsConsistedOnlyOfTokens) {
      resetCarouselHoverState(carouselStore);
    }

    return updatedCards;
  };

  const onRightClick = (clickEvent: any) => {
    clickEvent.preventDefault();
    if (contentSource === BattlefieldLocations.Hand) {
      showContextMenu(clickEvent, BattlefieldLocations.Hand, uiStateStore);
    }
  };

  const onFilter = (changeEvent: any) => {
    setFilterText(changeEvent.target.value);
  };

  const onScroll = () => {
    // if you hover a card in carousel and click bottom edge, it counts as clicking the card
    // AND clicking the scrollbar if you're on windows. calling clearDragListener prevents
    // the bugged state that results from that.
    clearDragListener();
    resetCarouselHoverState(carouselStore);
  };

  const isFacedown = carouselData.isViewingLibraryFacedown;
  const isPopupCarousel = carouselId === CarouselId.Popup;
  const showFilter = isPopupCarousel && !isFacedown;
  const isDrawerOpen = carouselStore.useState(s => s.isDrawerOpen);
  const showCloseButton = isPopupCarousel && isDrawerOpen;

  return (
    <div className="cardCarouselContainer" onContextMenu={onRightClick}>
      <Dropzone
        disabled={!shouldAllowDrop}
        location={convertToCarouselIdentifier(contentSource)}
        onCardDrop={onCardDrop}
      >
        {showFilter && <CarouselFilter onChange={onFilter} value={filterText}/>}

        {showCloseButton &&
          <IconButton className="drawerCloseButton" onClick={() => {
            closeDrawer(carouselStore);
          }}>
            <CloseIcon/>
          </IconButton>
        }

        {carouselId === CarouselId.Popup &&
          <div className="carouselLabel">
            {getPopupCarouselLabel(contentSource)}
          </div>
        }

        <div className={`widthLimiter ${processedCards.length > 8 ? 'wider' : ''}`}>
          {carouselId === CarouselId.Main &&
            <Tooltip title="Cards in Hand" enterDelay={700}>
              <div className="carouselSizeLabel">{sizeLabel}</div>
            </Tooltip>
            // showing size label for popup carousel too was just a little visually messy,
            // and ultimately redundant with existing indicators elsewhere in UI.
          }
          <div className="carouselBackground" onScroll={onScroll}>
            {carouselId === CarouselId.Popup && carouselData.popupCarouselContent !== BattlefieldLocations.OtherPlayerHand &&
              <div className="topLabel">Top</div>
            }
            {processedCards.map((card, index) => 
              <CarouselCard key={card.id} card={card} carouselId={carouselId} index={index} />
            )}
          </div>
          {carouselId === CarouselId.Main &&
            <Tooltip title="Group cards in hand by type" enterDelay={700}>
              <IconButton className="sortHandButton" disableRipple
                onClick={BattlefieldApi.sortHand}>
                <SortIcon/>
              </IconButton>
            </Tooltip>
          }
        </div>
      </Dropzone>
    </div>
  );
});

export default CardCarousel;