import React, { createRef, useState } from 'react';
import './CarouselFilter.less';

import SearchIcon from '@mui/icons-material/Search';

type CarouselFilterProps = {
  onChange: any;
  value: string;
};

const CarouselFilter = ({ onChange, value }: CarouselFilterProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const inputRef: any = createRef();

  const onClick = () => {
    if (!isOpen) {
      inputRef.current.focus();
    } else {
      // reset value with mock change event when closed
      onChange({ target: { value: '' }});
      inputRef.current.blur();
    }
    setIsOpen(!isOpen);
  };

  return (
    <div className={`search-box ${isOpen ? 'search-box-active' : ''}`}>
      <input ref={inputRef} className="search-text" onChange={onChange} type="text"  
        value={value} placeholder="Filter by card name"/>
      <SearchIcon onClick={onClick} className="search-btn"/>
    </div>
  );
};

export default CarouselFilter;