import { Dialog, DialogTitle } from '@material-ui/core';
import React, { useContext } from 'react';
import { DRAG_ZINDEX, DieVariant } from '../../../../../shared/types';
import { UiStateStoreContext } from '../../../shared/UiStateStore';
import BattlefieldApi from '../../../utils/BattlefieldApi';

import './RollDieDialog.less';

const RollDieDialog = () => {
  const uiStateStore = useContext(UiStateStoreContext);
  const isOpen = uiStateStore.useState(s => s.rollDieDialog.isOpen);

  const onClose = () => {
    uiStateStore.update(s => {
      s.rollDieDialog.isOpen = false;
    })
  };

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      style={{ zIndex: DRAG_ZINDEX }}
      PaperProps={{
        className: 'rollDieDialog',
      }}
    >
      <DialogTitle>Roll Die / Flip Coin</DialogTitle>
      <div className="rollDieDialogContent">
        <br/>
        <ul>
          <li onClick={() => {
            onClose();
            BattlefieldApi.rollDie(DieVariant.D6);
          }}>
            Roll d6
          </li>

          <li onClick={() => {
            onClose();
            BattlefieldApi.rollDie(DieVariant.D20);
          }}>
            Roll d20
          </li>

          <li onClick={() => {
            onClose();
            BattlefieldApi.rollDie(DieVariant.Coin);
          }}>
            Flip Coin
          </li>
        </ul>
        <br/>
      </div>
    </Dialog>
  );
};

export default RollDieDialog;