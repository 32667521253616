import React from 'react';
import { MousePosition } from '../../../../../../shared/types';
import './SelectBox.less';

type SelectBoxProps = {
  staticCorner: MousePosition;
  movingCorner: MousePosition;
};

const SelectBox = React.forwardRef(({
  staticCorner,
  movingCorner
}: SelectBoxProps, ref: any) => {

  const playArea = document.querySelector('.playArea')!.getBoundingClientRect();

  const style = {
    left: Math.min(staticCorner.x, movingCorner.x),
    right: playArea.width - Math.max(staticCorner.x, movingCorner.x),
    top: Math.min(staticCorner.y, movingCorner.y),
    bottom: playArea.height - Math.max(staticCorner.y, movingCorner.y),
  };

  return (
    <div ref={ref} className="selectBox" style={style} />
  );
});

export default SelectBox;