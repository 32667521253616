import React from 'react';
import { AppStore } from '../../../../shared/AppStore';

const RoomsListEmptyState = () => {
  const signedIn = AppStore.useState(s => s.signedIn);

  return (
    <div className="emptyStateMessage">
      <div className="emptyStateMainLabel">
        It looks like there aren't any games right now.
      </div>
      <div className="emptyStateCreateButton" onClick={() => {
        if (!signedIn) {
          alert('You are logged out. Log back in and try again.');
          return;
        }
        AppStore.update(s => { s.createGameDialog.isOpen = true; });
      }}>
        Create one!
      </div>
    </div>
  );
};

export default RoomsListEmptyState;