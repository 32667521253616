import React, { useContext, useState } from 'react';
import { Dialog, DialogTitle } from '@material-ui/core';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { addCardsToZone, fetchSuggestions } from './AddCardUtils';
import { AccessRequestLocationOptions, BattlefieldLocations, CardData, DRAG_ZINDEX } from '../../../../../shared/types';
import { UiStateStoreContext } from '../../../shared/UiStateStore';
import Button from '@mui/material/Button';

import Tooltip from '@mui/material/Tooltip';
import HelpIcon from '@mui/icons-material/Help';

import { blurInputs } from '../../../../../shared/utils';
import { cancelMagnification, setMagnifiedCardDetails } from '../../PlayArea/PlayAreaUtils';
import './AddCardDialog.less';

const AddCardDialog = () => {
  const uiStateStore = useContext(UiStateStoreContext);

  const { isOpen, recentlySelectedCards } = uiStateStore.useState(s => s.addCardDialog);
  const [cardData, setCardData] = useState(null);

  const [name, setName] = useState('');
  const [quantity, setQuantity] = useState(1);
  const [destination, setDestination] = useState(BattlefieldLocations.PlayArea);
  const [suggestions, setSuggestions] = useState<Partial<CardData>[]>(recentlySelectedCards);
  const [isToken, setIsToken] = useState(true);

  const handIsLocked = uiStateStore.useState(s => s.lockedZone) === AccessRequestLocationOptions.Hand;

  const onTokenChange = (changeEvent: any) => {
    setIsToken(changeEvent.target.checked);
  };

  const onNameChange = async (event: any) => {
    setName(event.target.value);
    fetchSuggestions(event.target.value, setSuggestions);
  };

  const onQuantityChange = (event: any) => {
    setQuantity(event.target.value);
  };

  const tooltipText = 'This is to make board clear easier, since you can select all cards in play and drag them elsewhere without worrying about tokens getting mixed in.';

  return (
    <Dialog
      open={isOpen}
      onClose={() => {
        setTimeout(() => {
          setDestination(BattlefieldLocations.PlayArea);
          setSuggestions(recentlySelectedCards);
          setQuantity(1);
          setIsToken(true);
        }, 100);
        uiStateStore.update(s => { s.addCardDialog.isOpen = false; });
        blurInputs();
      }}
      style={{ zIndex: DRAG_ZINDEX }}
      PaperProps={{
        className: 'addCardDialogContainer',
      }}
    >
      <DialogTitle className="addCardDialogTitle">Add Card / Token</DialogTitle>

      <div className="addCardDialogContent">
        <Autocomplete
          className="cardSearch"
          disablePortal
          options={suggestions}
          sx={{ width: '60%', background: 'white', borderRadius: '8px' }}
          clearOnBlur={false}
          // We need this AND the identical snippet below in onChange. This triggers when you
          // backspace while typing before selecting an option; the other triggers when you
          // clear the selection AFTER selecting an option.
          onInputChange={(event, value) => {
            if (!value) {
              setSuggestions(uiStateStore.getRawState().addCardDialog.recentlySelectedCards);
              setCardData(null);
              return;
            }
          }}
          onChange={(event: any, value: any) => {
            if (!value) {
              setSuggestions(uiStateStore.getRawState().addCardDialog.recentlySelectedCards);
              setCardData(null);
              setName('');
              return;
            }

            if (value?.name && value.name !== name) {
              setName(value.name);
              setCardData(value);
              uiStateStore.update(s => {
                // save their choice, but if they re-select the same value
                // from the suggested list a second time, don't add it again
                if (!s.addCardDialog.recentlySelectedCards.find(cardData => {
                  return cardData.id === value.id;
                })) {
                  s.addCardDialog.recentlySelectedCards.push(value);
                }
              });
            }
          }}
          onClose={() => {
            cancelMagnification(uiStateStore);
          }}
          // According to the docs, getOptionLabel is used to "fill the input" for some reason,
          // as well as the list box options "if renderOption isn't provided". Anyway,
          // it breaks if you remove it. Kinda stupid but it is what it is.
          getOptionLabel={(option: any) => {
            return option.name;
          }}
          renderOption={(props, option: any) => {
            return (
              <li
                {...props}
                key={option.id}
                data-url={option.url}
                data-altfaceurl={option.altFaceUrl}
                onMouseLeave={(e: any) => {
                  cancelMagnification(uiStateStore);
                }}
              >
                {option.name
                  + (option.colors ? ' ' + option.colors : '')
                  + (option.powerAndToughness ? ' ' + option.powerAndToughness : '')
                  + (option.keywords ? ' - ' + option.keywords : '')
                }
              </li>
            );
          }}
          onHighlightChange={(e: any) => {
            if (!e?.target) return;

            setMagnifiedCardDetails(
              {
                id: e.target.getAttribute('id'),
                name: e.target.getAttribute('data-name'),
                url: e.target.getAttribute('data-url'),
                altFaceUrl: e.target.getAttribute('data-altfaceurl'),
                showAltFace: !!e.target.getAttribute('data-altfaceurl'),
                gathererUrl: '', // we don't actually need this here except for typing
                cmc: 0,
                type: '',
              }, { current: e.target }, uiStateStore, { noDelay: true });
          }}
          renderInput={(params) =>
            <TextField
              {...params}
              autoFocus
              onChange={onNameChange}
              label={name}
              placeholder={'Name of card or token'}
              InputLabelProps={{ shrink: false }}
            />
          }
          filterOptions={(options, state) => {
            // As the user is typing, the autocomplete stops displaying any suggestions
            // that don't match what they've typed. By providing this prop we're
            // able to customize that functionality, which we do because we want
            // to ignore punctuation when doing the comparisons.
            return options.filter(option => {
              // Remove punctuation from the search string
              const formattedSearch = state.inputValue.replace(/[^\w\s]/g, "");
              // Remove punctuation from the card's name
              const formattedName = option.name.replace(/[^\w\s]/g, "");

              return formattedName.toLowerCase().includes(formattedSearch.toLowerCase());
            });
          }}
        />

        <div className="quantityContainer">
          <label htmlFor="quantity" className="quantityLabel">Quantity:</label>
          <input onChange={onQuantityChange} placeholder="1" value={quantity || ''} type="number" name="quantity" id="quantity"/>
        </div>

        <div className="destinationContainer">
          <label htmlFor="destination" className="destinationLabel">Add cards to:</label>
          <select name="destination" id="destination" onChange={(e) => {
            setDestination(e.target.value as BattlefieldLocations);
          }}>
            <option value={BattlefieldLocations.PlayArea}>Play area</option>
            <option value={BattlefieldLocations.Hand}>Hand</option>
          </select>
        </div>

        <div className="isTokenContainer">
          <input type="checkbox" id="isToken" name="isToken"
            onChange={onTokenChange} checked={isToken} />
          <label htmlFor="isToken" className="isTokenLabel">Mark new cards as tokens</label>
          <span className="isTokenFootnote">
            (Moving a token out of the play area deletes it automatically.)
            <Tooltip title={tooltipText}>
              <HelpIcon style={{
                position: 'relative',
                height: '20px',
                top: '5px',
                marginLeft: '5px',
              }}/>
            </Tooltip>
          </span>
        </div>

        <Button id="submit" variant="contained" onClick={() => {
          if (handIsLocked && destination === BattlefieldLocations.Hand) {
            alert('Cannot add cards to hand while hand is locked.');
            return;
          }

          if (quantity > 100) {
            alert('Cannot add more than 100 cards at once.');
            return;
          }

          addCardsToZone(cardData, quantity || 1, isToken, destination);
          uiStateStore.update(s => { s.addCardDialog.isOpen = false; });
          // delay so it closes before you see the change
          setTimeout(() => {
            setDestination(BattlefieldLocations.PlayArea);
            setSuggestions(recentlySelectedCards);
            setQuantity(1);
            setIsToken(true);
          }, 100);
        }}>
          Add cards/tokens
        </Button>
      </div>
    </Dialog>
  );
};

export default AddCardDialog;