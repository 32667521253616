import React, { useEffect, useState } from 'react';
import 'regenerator-runtime/runtime'; // react-router seems to require this import or it crashes
import Battlefield from '../components/Battlefield';
import Lobby from '../components/Lobby';
import { AppStore } from '../shared/AppStore';
// don't replace with <import from 'firebase'> bc we need to import it and run it once somewhere
import '../shared/firebaseInit';
import {
  createBrowserRouter,
  RouterProvider,
} from 'react-router-dom';
import { socket } from '../shared/SocketInit';
import { ClientActions } from '../shared/SocketEnums';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { ONE_MINUTE, authConnectOnLogin, setCookie } from '../shared/utils';

const router = createBrowserRouter([
  {
    path: '*',
    element: <Lobby path="/"/>,
  },
  {
    path: '/',
    element: <Lobby path="/"/>,
  },
  {
    path: '/decks',
    element: <Lobby path="/decks"/>,
  },
  {
    path: '/match-history',
    element: <Lobby path='/match-history'/>,
  },
  {
    path: '/upgrade',
    element: <Lobby path='/upgrade'/>,
  },
  {
    path: '/account',
    element: <Lobby path='/account'/>,
  },
  {
    path: '/settings',
    element: <Lobby path='/settings'/>,
  },
  {
    path: '/discord',
    element: <Lobby path='/discord'/>,
  },
  {
    path: '/about',
    element: <Lobby path='/about'/>,
  },
  {
    path: '/game',
    element: <Battlefield/>,
  },
]);

const App = () => {
  const [loading, setLoading] = useState(true);
  const signedIn = AppStore.useState(s => s.signedIn);
  const auth = getAuth();

  // On page load, if already logged-in, refresh token and authConnect and load decks.
  //
  // Frustratingly, we have to check if they're in the process of signing in
  // so that the initial call to firebase's signInWithEmailAndPassword in
  // SignInDialog.tsx doesn't trigger this before we've finished authConnect.
  //
  // We can't ONLY have this call to authConnect and remove the authConnect in SignInDialog.tsx
  // because the callback there also handles the validation state of the dialog itself.
  //
  // One more thing - I keep catching myself asking "when does setLoading(false) happen
  // for manual sign in? Why aren't we stuck waiting for it?"
  // The answer is that the interface isn't waiting for it, it defaulted to the logged-out 
  // state. It isn't "loading" or waiting for anything. It will switch from logged-out to 
  // logged-in after the manual sign in completes, but it isn't loading.
  // The loading state is just for determining if we're already logged-in or not.
  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      const signingIn = AppStore.getRawState().signingIn;

      if (user && !signingIn) {
        authConnectOnLogin(AppStore,
          () => { setLoading(false); },
          () => { setLoading(false); },
        );
      } else if (!user) {
        setLoading(false);
      }
    });

    // This handles the case of user closing laptop/putting pc to sleep without closing page,
    // then coming back. The interval won't have been firing since asleep, so token expired.
    // By the way an interval function has its first execution at the END of the first interval,
    // so we have to call it once here at the beginning or it would be 25 min until it happened.
    document.addEventListener('visibilitychange', () => {
      const updateAuthToken = async () => {
        const newAuthToken = await auth.currentUser!.getIdToken(true);
        setCookie('authToken', newAuthToken);
      };

      AppStore.update(s => {
        if (auth.currentUser) {
          const currentTime = new Date().getTime();
          if (!s.lastTokenUpdate || (currentTime - s.lastTokenUpdate >= 20 * ONE_MINUTE)) {
            updateAuthToken();
            clearInterval(s.tokenRefreshingFn); // never let there be multiple of these
            s.tokenRefreshingFn = setInterval(async () => {
              updateAuthToken();
            }, ONE_MINUTE * 25);
            s.lastTokenUpdate = currentTime;
          }
        } else {
          s.signedIn = false;
          s.tokenRefreshingFn = null;
          s.lastTokenUpdate = null;
        }
      });
    });
  }, []);

  // load user info on sign-in (this useEffect triggers for both manual login
  // and the automatic login when you initially load the page already logged-in)
  useEffect(() => {
    if (signedIn) {
      socket.emit(ClientActions.LOAD_USER, (response: any) => {
        if (response.error) {
          AppStore.update(s => {
            s.userData.error = 'Something went wrong while loading user data.';
            s.userData.loading = false;
          });
        } else {
          AppStore.update(s => {
            s.userData.decks = response.decks || [];
            s.userData.userSettings = response.userSettings || null;
            s.userData.loading = false;
          });
        }
      });
    }
  }, [signedIn]);

  if (loading) {
    return null;
  }

  return (
    <RouterProvider router={router}/>
  );
};

export default App;