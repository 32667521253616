import { ServerErrors } from './SocketEnums';

const logAndAlert = (message?: string) => {
  if (!message) {
    message = 'An unknown error occurred. Please try again.';
  }
  console.error(message);
  alert(message);
};

const handleUniversalErrors = (errorHandler: Function) => {
  return (response: any) => {
    if (response?.error === ServerErrors.LOGGED_OUT) {
      logAndAlert('You are logged out. Log back in and try again.');
      return;
    }
    
    errorHandler(response);
  }
};

const handleJoinWaitingRoomError = handleUniversalErrors((response: any) => {
  switch(response?.error) {
    case ServerErrors.ROOM_NO_LONGER_EXISTS:
      logAndAlert('The game you are trying to join no longer exists.');
      return;
    case ServerErrors.ROOM_IS_FULL:
      logAndAlert('The game you are trying to join is full.');
      return;
    case ServerErrors.GAME_ALREADY_LAUNCHED:
      logAndAlert('The game you are trying to join has already launched.');
      return;
    case ServerErrors.WRONG_PASSWORD:
      alert('Incorrect password.');
      return;
    case ServerErrors.GENERIC_ERROR:
      logAndAlert();
    default:
      return;
  }
});

const handleCreateWaitingRoomError = handleUniversalErrors((response: any) => {
  switch(response?.error) {
    case ServerErrors.GENERIC_ERROR:
      logAndAlert('An unknown error occurred and your game was not created. Please try again.');
      return;
    default:
      return;
  }
});

const handleUpdateWaitingRoomError = handleUniversalErrors((response: any) => {
  switch(response?.error) {
    case ServerErrors.ONLY_HOST_CAN_UPDATE:
      logAndAlert('You are not the host and cannot change the game settings.');
      return;
    case ServerErrors.GENERIC_ERROR:
      logAndAlert('An unknown error occurred and the settings were not updated. Please try again.');
      return;
    default:
      return;
  }
});

const handleLaunchGameError = handleUniversalErrors((response: any) => {
  switch(response?.error) {
    case ServerErrors.GAME_ALREADY_LAUNCHED:
      logAndAlert('The game has already been launched. Try refreshing.');
      return;
    case ServerErrors.PLAYERS_NOT_READY:
      logAndAlert('Not all players have selected decks yet.');
      return;
    default:
      return;
  }
});

const handleSendRoomChatError = handleUniversalErrors((response: any) => {
  switch(response?.error) {
    case ServerErrors.GENERIC_ERROR:
      logAndAlert('Message sending failed. Something is wrong.');
      return;
    default:
      return;
  }
});

const handleGenericError = handleUniversalErrors((response: any) => {
  switch(response?.error) {
    case ServerErrors.GENERIC_ERROR:
      logAndAlert();
      return;
    default:
      return;
  }
});

export default {
  handleGenericError,
  handleJoinWaitingRoomError,
  handleCreateWaitingRoomError,
  handleUpdateWaitingRoomError,
  handleLaunchGameError,
  handleSendRoomChatError,
};