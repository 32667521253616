import React from 'react';
import { Store } from 'pullstate';
import { GameState, WaitingRoom } from '../../../shared/types';

export const GameStoreContext = React.createContext<Store<GameStore>>({} as Store<GameStore>);

export type GameStore = GameState & {
  initialLoadComplete: boolean;
  waitingRoom: WaitingRoom | null;
};

export const createStoreForGameId = () => {
  const gameStore = new Store<GameStore>({
    startTime: 0,
    initialLoadComplete: false,
    waitingRoom: null,
    playerStatuses: {},
    mirrorMode: false,
    chat: [],
    lifeTotals: {},
    otherPlayerIds: [],
    publicCounterData: [],
    publicCardData: {},
    privateCardData: {
      startingDecklist: [],
      startingSideboard: [],
      currentDecklist: [],
      currentSideboard: [],
      hand: [],
      lockedZone: null,
      revealedOpponentCards: null,
      facedownLibrary: [],
      revealedLibrary: null,
      topNOfLibrary: null,
      revealedTopCardOfLibrary: null,
    },
  });

  return gameStore;
};