import React, { useEffect, useState } from 'react';
import './LifeCounter.less';

import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import BattlefieldApi from '../../../../utils/BattlefieldApi';
import { LifeCounterId, Topics } from '../../../../../../shared/types';

import Tooltip from '@mui/material/Tooltip';
import { ONE_SECOND } from '../../../../../../shared/utils';

type LifeCounterProps = {
  counterId: LifeCounterId;
  allowUpdate: boolean;
  value: number;
};

const LifeCounter = ({ counterId, allowUpdate, value }: LifeCounterProps) => {
  const tooltipText = counterId === LifeCounterId.PrimaryCounter ?
  'Primary Life Counter' : 'Secondary Damage Counter';

  // We need this (rather than just using the value itself) because otherwise
  // value is stale and we're always sending the value from the last render
  const [totalChange, setTotalChange] = useState(0);
  const [actionLogTimeout, setActionLogTimeout] = useState<any>(null);

  const onDecrement = (shift: boolean) => {
    const decrement = shift ? 5 : 1;
    BattlefieldApi.updateLifeCounter(
      counterId,
      value - decrement,
    );
    setTotalChange(totalChange - decrement);
    clearTimeout(actionLogTimeout);
    setActionLogTimeout(setTimeout(() => {
      // the totalChange value itself is stale due to closure
      const updatedTotalChange = totalChange - decrement;
      if (updatedTotalChange !== 0) {
        BattlefieldApi.logLifeCounterChange(counterId, updatedTotalChange);
        setTotalChange(0);
      }
    }, ONE_SECOND));
  };

  const onIncrement = (shift: boolean) => {
    const increment = shift ? 5 : 1;
    BattlefieldApi.updateLifeCounter(
      counterId,
      value + increment,
    );
    setTotalChange(totalChange + increment);
    clearTimeout(actionLogTimeout);
    setActionLogTimeout(setTimeout(() => {
      // the totalChange value itself is stale due to closure
      const updatedTotalChange = totalChange + increment;
      if (updatedTotalChange !== 0) {
        BattlefieldApi.logLifeCounterChange(counterId, updatedTotalChange);
        setTotalChange(0);
      }
    }, ONE_SECOND));
  };

  const onSetLifeTotal = () => {
    if (!allowUpdate) return;
    const newValue = prompt('New value?', '' + value);
    if (newValue === null) return; // in case they hit escape key
    // since otherwise that would get cast to 0 by the '+' and pass isNaN test
    if (isNaN(+newValue)) {
      alert('Must be a number.');
      return;
    }
    BattlefieldApi.updateLifeCounter(
      counterId,
      +newValue,
    );
    BattlefieldApi.logLifeCounterChange(counterId, +newValue - value);
    setTotalChange(0);
    clearTimeout(actionLogTimeout);
  };

  // listen for keyboard shortcuts
  useEffect(() => {
    if (allowUpdate && counterId === LifeCounterId.PrimaryCounter) {
      const subToken = PubSub.subscribe(Topics.IncrementLifeCounter, () => {
        onIncrement(false);
      });

      const subToken2 = PubSub.subscribe(Topics.IncrementLifeCounterBy5, () => {
        onIncrement(true);
      });

      const subToken3 = PubSub.subscribe(Topics.DecrementLifeCounter, () => {
        onDecrement(false);
      });

      const subToken4 = PubSub.subscribe(Topics.DecrementLifeCounterBy5, () => {
        onDecrement(true);
      });

      return () => {
        PubSub.unsubscribe(subToken);
        PubSub.unsubscribe(subToken2);
        PubSub.unsubscribe(subToken3);
        PubSub.unsubscribe(subToken4);
      }
    }
    if (allowUpdate && counterId === LifeCounterId.SecondaryCounter) {
      const subToken = PubSub.subscribe(Topics.IncrementSecondaryLifeCounter, () => {
        onIncrement(false);
      });

      const subToken2 = PubSub.subscribe(Topics.IncrementSecondaryLifeCounterBy5, () => {
        onIncrement(true);
      });

      const subToken3 = PubSub.subscribe(Topics.DecrementSecondaryLifeCounter, () => {
        onDecrement(false);
      });

      const subToken4 = PubSub.subscribe(Topics.DecrementSecondaryLifeCounterBy5, () => {
        onDecrement(true);
      });

      return () => {
        PubSub.unsubscribe(subToken);
        PubSub.unsubscribe(subToken2);
        PubSub.unsubscribe(subToken3);
        PubSub.unsubscribe(subToken4);
      }
    }
  // recreate listeners whenever they trigger in order to renew closure with fresh values,
  // otherwise it basically doesn't work at all.
  }, [value, totalChange]);

  return (
    <div className="lifeCounterContainer">
      {allowUpdate && 
      <IconButton className="" onClick={(clickEvent) => {
        onDecrement(clickEvent.shiftKey);
      }}>
        <ChevronLeftIcon/>
      </IconButton>}

      <Tooltip title={tooltipText} enterDelay={500}>
        <span className="counterValue" onClick={onSetLifeTotal}>
          {value}
        </span>
      </Tooltip>

      {allowUpdate &&
      <IconButton className="" onClick={(clickEvent) => {
        onIncrement(clickEvent.shiftKey);
      }}>
        <ChevronRightIcon/>
      </IconButton>}
    </div>
  );
};

export default LifeCounter;