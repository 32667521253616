import React, { useContext, useEffect, useRef, useState } from 'react';
import { Dialog, DialogTitle } from '@material-ui/core';
import Button from '@mui/material/Button';
import { WaitingRoomExperienceLevel, GameFormat } from '../../../../shared/types';
import LobbyApi from '../../shared/LobbyApi';
import { Select } from 'react-functional-select';
import { getAuth } from 'firebase/auth';
import { AppStore } from '../../../../shared/AppStore';
import './CreateGameDialog.less';

const auth = getAuth();

const CreateGameDialog = () => {
  const isOpen = AppStore.useState(s => s.createGameDialog.isOpen);
  const currentRoom = AppStore.useState(s => s.currentRoom);
  const isUpdateMode = AppStore.useState(s => s.createGameDialog.isUpdateMode);

  const [title, setTitle] = useState('');
  const [maxPlayers, setMaxPlayers] = useState(2);
  const [gameFormat, setGameFormat] = useState(GameFormat.Any);
  const [experienceLevel, setExperienceLevel] = useState(WaitingRoomExperienceLevel.Casual);
  const [mirrorMode, setMirrorMode] = useState(false);
  const [password, setPassword] = useState<string>('');

  const user = auth.currentUser;
  const username = user?.displayName || 'UsernameMissing';

  const maxPlayersSelectRef = useRef(null);

  useEffect(() => {
    if (maxPlayers > 1) setMirrorMode(false);
  }, [maxPlayers]);

  // Without this, the fields keep their initial values when it changes from Create to Update
  useEffect(() => {
    if (isUpdateMode && currentRoom) {
      setTitle(currentRoom.title);
      setMaxPlayers(currentRoom.maxPlayers);
      setGameFormat(currentRoom.gameFormat);
      setExperienceLevel(currentRoom.experienceLevel);
      setMirrorMode(currentRoom.mirrorMode);
    }
  }, [isUpdateMode]);

  const onTitleChange = (event: any) => {
    if (event.target.value?.length < 50) {
      setTitle(event.target.value);
    }
  };

  const onPasswordChange = (event: any) => {
    if (event.target.value?.length < 50) {
      setPassword(event.target.value);
    }
  };

  const toggleMirrorMode = (event: any) => {
    setMirrorMode(!mirrorMode);
    if (!mirrorMode) {
      setMaxPlayers(1);
      // https://master--625676b6922472003af898b4.chromatic.com/?path=/story/react-functional-select-demos--methods
      // tl;dr this stupid select component has no value prop, only initialValue,
      // so to update it visually you have to use a ref and their setValue method
      (maxPlayersSelectRef as any).current?.setValue([1]);
    }
  };

  return (
    <Dialog
      open={isOpen}
      onClose={() => {
        AppStore.update(s => {
          s.createGameDialog.isOpen = false;
        });
        // so that it closes before it changes
        setTimeout(() => {
          AppStore.update(s => { s.createGameDialog.isUpdateMode = false; });
        }, 200);
      }}
      PaperProps={{
        className: 'createGameDialogContainer',
      }}
    >
      <DialogTitle className="createGameDialogTitle">
        {isUpdateMode ? 'Update Game' : 'Create Game'}
      </DialogTitle>

      <div className="createGameDialogContent">
        <div className="titleContainer">
          <label className="titleInputLabel">Title</label>
          <input
            className="titleInput"
            onChange={onTitleChange}
            autoFocus
            type="text"
            value={title}
            placeholder={`${username}'s Game`}
          />
        </div>
        <div className="passwordContainer">
          <label className="passwordInputLabel">Password</label>
          <input
            className="passwordInput"
            onChange={onPasswordChange}
            type="text"
            value={password}
            placeholder={'Leave blank for no password'}
          />
        </div>

        <div className="maxPlayersContainer">
          <label htmlFor="maxPlayers" className="maxPlayersLabel">
            Max players
          </label>
          <Select
            initialValue={[maxPlayers]}
            ref={maxPlayersSelectRef}
            options={[1, 2, 3, 4, 5, 6, 7, 8]}
            onOptionChange={setMaxPlayers}
            getOptionValue={opt => opt}
            getOptionLabel={opt => opt}
            isSearchable={false}
          />
        </div>

        <div className="formatContainer">
          <label htmlFor="format" className="formatLabel">
            Game format
          </label>
          <Select
            initialValue={[gameFormat]}
            options={Object.values(GameFormat)}
            onOptionChange={setGameFormat}
            getOptionValue={opt => opt}
            getOptionLabel={opt => opt}
            isSearchable={false}
          />
        </div>

        <div className="experienceLevelContainer">
          <label htmlFor="experienceLevel" className="experienceLevelLabel">
            Experience level
          </label>
          <Select
            initialValue={[experienceLevel]}
            options={Object.values(WaitingRoomExperienceLevel)}
            onOptionChange={setExperienceLevel}
            getOptionValue={opt => opt}
            getOptionLabel={opt => opt}
            isSearchable={false}
          />
        </div>

        <div className="mirrorModeContainer">
          <input type="checkbox" name="mirrorMode" id="mirrorMode"
            checked={mirrorMode} onChange={toggleMirrorMode}/>
          <label htmlFor="mirrorMode">Mirror mode (solo only)</label>
        </div>

        <Button id="submit" variant="contained" onClick={() => {
          AppStore.update(s => {
            s.createGameDialog.isOpen = false;
          });

          // slightly delay this so it doesn't change before it closes
          setTimeout(() => {
            AppStore.update(s => {
              s.createGameDialog.isUpdateMode = false;
            });
          }, 100);

          if (isUpdateMode) {
            LobbyApi.updateWaitingRoom({
              title, password, maxPlayers, gameFormat, experienceLevel, mirrorMode,
            });
          } else {
            LobbyApi.createWaitingRoom({
              title, password, maxPlayers, gameFormat, experienceLevel, mirrorMode, username,
            });
          }
        }}>
          {isUpdateMode ? 'Update Game' : 'Create Game'}
        </Button>
      </div>
    </Dialog>
  );
};

export default CreateGameDialog;