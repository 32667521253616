import { Alert, AlertTitle } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { CSSTransition } from 'react-transition-group';
import { AccessRequestLocationOptions, BattlefieldLocations, RequestOutcome, RequestType, Topics } from '../../../../shared/types';
import { ONE_SECOND, usePrevious } from '../../../../shared/utils';
import { CarouselStoreContext } from '../../shared/CarouselStore';
import { GameStoreContext } from '../../shared/GameStore';
import BattlefieldApi from '../../utils/BattlefieldApi';
import './FloatingNotificationManager.less';

const getLeftAlertMessages = (
  isDrawerOpen: boolean,
  popupCarouselContent: BattlefieldLocations | null,
  lockedZone: AccessRequestLocationOptions | null,
) => {
  let yourLockedZoneAlert = null;
  if (!lockedZone) {
    yourLockedZoneAlert = null;
  } else if (lockedZone === AccessRequestLocationOptions.Hand) {
    yourLockedZoneAlert = 'Your hand is locked for you while your opponent looks at it.';
  } else {
    yourLockedZoneAlert = 'Your library is locked for you while your opponent looks at it.';
  }

  let opponentLockedZoneAlert = null;
  if (!isDrawerOpen) {
    opponentLockedZoneAlert = null;
  } else if (popupCarouselContent === BattlefieldLocations.OtherPlayerHand) {
    opponentLockedZoneAlert = "Your opponent's hand is locked for them while you look at it.";
  } else if (popupCarouselContent === BattlefieldLocations.OtherPlayerLibrary) {
    opponentLockedZoneAlert = "Your opponent's library is locked for them while you look at it.";
  }

  return [yourLockedZoneAlert, opponentLockedZoneAlert];
};

const FloatingNotificationManager = () => {
  const carouselStore = useContext(CarouselStoreContext);
  const gameStore = useContext(GameStoreContext);

  const isDrawerOpen = carouselStore.useState(s => s.isDrawerOpen);
  const popupCarouselContent = carouselStore.useState(s => s.popupCarouselContent);
  const lockedZone = gameStore.useState(s => s.privateCardData.lockedZone);
  const prevLockedZone = usePrevious(lockedZone);
  
  const [isOpen_right, setIsOpen_right] = useState(false);
  const [isOpen_left, setIsOpen_left] = useState(false);
  const [isGranted, setIsGranted] = useState(true);
  const [leftAlertMessages, setLeftAlertMessages] = useState<(string | null)[]>([null, null]);
  const [leftAlertWasDismissed, setLeftAlertWasDismissed] = useState(false);

  // set up listener for RequestOutcomeReceived to display right alert
  useEffect(() => {
    const subscriptionToken = PubSub.subscribe(
      Topics.RequestOutcomeReceived,
      async (topic: string, requestOutcome: RequestOutcome) => {

        const showRightAlert = () => {
          setIsGranted(requestOutcome.granted);
          setIsOpen_right(true);

          setTimeout(() => {
            setIsOpen_right(false);
          }, ONE_SECOND * 2);
        };

        if (requestOutcome.requestType === RequestType.LookAtCardsRequest
            && requestOutcome.granted) {

          // If we are in the middle of dragging a card when the request approval comes in,
          // we wait until we finish dragging before changing the carousel content.
          if (carouselStore.getRawState().isDraggingCard) {
            // Note: you can't call store.update inside of store.createReaction,
            // and instead need to make changes using the provided draftState.
            const cancelReaction = carouselStore.createReaction(
              s => s.isDraggingCard,
              (isDraggingCard, draftState) => {
                showRightAlert();
                setLeftAlertWasDismissed(false);
                BattlefieldApi.closeRevealedViews(false);
                draftState.isDrawerOpen = true;
                switch (requestOutcome.requestedLocation) {
                  case AccessRequestLocationOptions.Hand:
                    draftState.popupCarouselContent = BattlefieldLocations.OtherPlayerHand;
                    break;
                  case AccessRequestLocationOptions.Library:
                  case AccessRequestLocationOptions.TopNOfLibrary:
                    draftState.popupCarouselContent = BattlefieldLocations.OtherPlayerLibrary;
                    break;
                }

                // We unsubscribe this listener by calling the response to the creation method
                cancelReaction();
              }
            );
          } else {
            showRightAlert();
            setLeftAlertWasDismissed(false);
            BattlefieldApi.closeRevealedViews(false);
            carouselStore.update(s => {
              s.isDrawerOpen = true;
              switch (requestOutcome.requestedLocation) {
                case AccessRequestLocationOptions.Hand:
                  s.popupCarouselContent = BattlefieldLocations.OtherPlayerHand;
                  break;
                case AccessRequestLocationOptions.Library:
                case AccessRequestLocationOptions.TopNOfLibrary:
                  s.popupCarouselContent = BattlefieldLocations.OtherPlayerLibrary;
                  break;
              }
            });
          }
        } else {
          showRightAlert();
        }
      }
    );

    return () => {
      PubSub.unsubscribe(subscriptionToken);
    };
  });

  // update left warning alert when we open or close their cards, or lockedZone status changes
  useEffect(() => {
    const _leftAlertMessages = getLeftAlertMessages(isDrawerOpen, popupCarouselContent, lockedZone);
    // if there's a message and the alert is closed, open it
    if (_leftAlertMessages[0] || _leftAlertMessages[1]) {
      // only show it if it hasn't been dismissed or if a new zone has become locked
      if (!isOpen_left && (!leftAlertWasDismissed || (lockedZone && lockedZone !== prevLockedZone))) {
        setIsOpen_left(true);
        setLeftAlertWasDismissed(false);
      }
      setLeftAlertMessages(_leftAlertMessages);
    } else {
    // if there's no message and the alert is open, close it
      if (isOpen_left) {
        setIsOpen_left(false);
        setLeftAlertWasDismissed(false);
        setTimeout(() => {
          setLeftAlertMessages([ null, null ]);
        }, ONE_SECOND)
      }
    }
  }, [isDrawerOpen, popupCarouselContent, lockedZone]);

  const leftNotificationClasses = `${leftAlertMessages[0] && leftAlertMessages[1] ? 'leftNotificationDoubleHeight' : ''} floatingNotification leftNotification`;

  return (
    <>
      <CSSTransition in={isOpen_left} timeout={200} classNames="floatingNotification" unmountOnExit>
        <div className={leftNotificationClasses}>
          <Alert severity="warning" onClose={() => {
            setIsOpen_left(false);
            setLeftAlertWasDismissed(true);
          }}>
            <AlertTitle>Locked zones</AlertTitle>
            {leftAlertMessages[0]}
            {leftAlertMessages[0] && leftAlertMessages[1] ? <br/> : null}
            {leftAlertMessages[1]}
          </Alert>
        </div>
      </CSSTransition>

      <CSSTransition in={isOpen_right} timeout={200} classNames="floatingNotification" unmountOnExit>
        <div className="floatingNotification rightNotification">
          {isGranted ? 
            <Alert severity="success">Request Granted</Alert>
            :
            <Alert severity="error">Request Denied</Alert>
          }
        </div>
      </CSSTransition>
    </>
  );
};

export default FloatingNotificationManager;