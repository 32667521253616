import React from 'react';
import { getAuth } from 'firebase/auth';
import { Button } from '@mui/material';
import { GameFormat } from '../../../../shared/types';
import CreateGameDialog from '../CreateGameDialog';
import { Select } from 'react-functional-select';
import { AppStore } from '../../../../shared/AppStore';
import './RoomsListControls.less';
// using this icon causes a bizarre clash with global namespace that fucks imports in other
// libraries, namely socket.io but who knows what else
// import { ImportExport as ReverseSortIcon } from '@material-ui/icons';

const RoomsListControls = () => {
  const { formatFilter, titleFilter, currentRoom, signedIn } = AppStore.useState(s => {
    return {
      formatFilter: s.roomsList.formatFilter,
      titleFilter: s.roomsList.titleFilter,
      currentRoom: s.currentRoom,
      signedIn: s.signedIn,
    };
  });

  const userId = getAuth().currentUser?.uid;
  // If we aren't signed in, we still show the button.
  // It will tell them to log in if they click it.
  // But we don't show it at all if we're already hosting a game. Should prob disable instead.
  const showCreateGameButton = currentRoom?.hostUserId !== userId || !userId;

  const showCreateGameDialog = () => {
    if (!signedIn) {
      alert('You are logged out. Log back in and try again.');
      return;
    }
    AppStore.update(s => { s.createGameDialog.isOpen = true; });
  };

  return (
    <div className="roomsListControls">
      <CreateGameDialog/>

      {showCreateGameButton &&
        <Button variant="contained" className="createGameButton"
          onClick={showCreateGameDialog}>
          Create Game
        </Button>
      }

      <input
        className="titleFilter"
        onChange={(event) => {
          AppStore.update(s => { s.roomsList.titleFilter = event.target.value; })
        }}
        type="text"
        value={titleFilter}
        placeholder="Filter games by title"
      />

      <Select
        initialValue={[formatFilter]}
        options={Object.values(GameFormat)}
        onOptionChange={val => { AppStore.update(s =>  { s.roomsList.formatFilter = val; })}}
        getOptionValue={opt => opt}
        getOptionLabel={opt => opt}
        isSearchable={false}
      />

      {/* <IconButton className="reverseSortButton"> */}
        {/* <ReverseSortIcon/> */}
      {/* </IconButton> */}
    </div>
  );
};

export default RoomsListControls;