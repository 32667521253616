import React from 'react';
import { Button, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { DefaultKeyboardShortcuts, KeyboardActions, KeyboardShortcutLabels } from '../keyboardShortcutConstants';
import { UserSettings } from '../../../../shared/types';
import ChangeShortcutDialog from '../ChangeShortcutDialog';
import { AppStore } from '../../../../shared/AppStore';
import './KeyboardShortcutsTable.less';

type KeyboardShortcutsTableProps = {
  currentSettings: UserSettings['keyboardShortcuts'],
  setNewSettings: Function,
};

const KeyboardShortcutsTable = ({
  currentSettings,
  setNewSettings
}: KeyboardShortcutsTableProps) => {
  const resetDefaults = () => {
    setNewSettings({
      keyboardShortcuts: DefaultKeyboardShortcuts
    });
  };

  // convert ['shift', 'tab'] to 'Shift + tab' (for example)
  const getLabelForShortcut = (shortcutArray: string[]) => {
    if (shortcutArray.length === 1) {
      return shortcutArray[0].toUpperCase();
    }

    return shortcutArray.join(' + ').toUpperCase()
  };

  return (
    <>
      <div className="shortcutsTableHeader">
        <h1>Keyboard Shortcuts</h1>
        <Button
          size="small"
          variant="contained"
          className="resetDefaultsButton"
          onClick={resetDefaults}
        >
          Reset to Defaults
        </Button>
      </div>

      <TableContainer className="shortcutsTable" component={Paper}>
        <Table aria-label="simple table">

          <TableHead>
            <TableRow>
              <TableCell>Action</TableCell>
              <TableCell align="right">Key</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {Object.values(KeyboardActions).map((keyboardAction) => (
              <TableRow
                className="keyboardShortcutRow"
                key={keyboardAction}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                onClick={() => {
                  AppStore.update(s => {
                    s.changeShortcutDialog.isOpen = true;
                    s.changeShortcutDialog.action = keyboardAction;
                  });
                }}
              >
                <TableCell component="th" scope="row">
                  {KeyboardShortcutLabels[keyboardAction]}
                </TableCell>

                <TableCell align="right">
                  {getLabelForShortcut(currentSettings[keyboardAction])}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>

        </Table>
      </TableContainer>
      <ChangeShortcutDialog setNewSettings={setNewSettings} />
    </>
  );
};

export default KeyboardShortcutsTable;