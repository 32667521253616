import { Store } from 'pullstate';
import { GameStore } from '../../shared/GameStore';

export const getTopCardImageUrl = (
  gameStore: Store<GameStore>,
  userId: string,
) => {
  // (formulated as multiple variables so that order of hooks is always the same.)
  const revealedToSelfCard = gameStore.useState(s => s.privateCardData.revealedTopCardOfLibrary);
  const revealedToAllCard = gameStore.useState(s => s.publicCardData[userId].revealedTopCardOfLibrary);

  const revealedToSelfUrl = revealedToSelfCard?.showAltFace ? revealedToSelfCard.altFaceUrl : revealedToSelfCard?.url;
  const revealedToAllUrl = revealedToAllCard?.showAltFace ? revealedToAllCard.altFaceUrl : revealedToAllCard?.url;

  // If this is null then the top card is facedown, as is normal
  const imageUrl = revealedToSelfUrl || revealedToAllUrl;

  return imageUrl;
};