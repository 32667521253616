import { Store } from 'pullstate';
import React, { useContext, useEffect, useRef } from 'react';
import { ContextMenuInstanceSources, ContextMenuSource, Topics } from '../../../../shared/types';
import { didMouseLeaveWindow, percentPixelsOfWindowWidth } from '../../../../shared/utils';
import { CarouselStore, CarouselStoreContext } from '../../shared/CarouselStore';
import { UiStateStore, UiStateStoreContext } from '../../shared/UiStateStore';
import { resetCarouselHoverState } from '../CardCarousel/CarouselUtils';
import getMenuOptions from './ContextMenuOptions/getMenuOptions';
import { cancelMagnification } from '../PlayArea/PlayAreaUtils';
import { getSecondaryMenuOptions } from './ContextMenuOptions/CarouselCardMenuOptions';
import { GameStoreContext } from '../../shared/GameStore';
import './ContextMenu.less';

// used by other components to show the context menu on right click 
export const showContextMenu = (
  clickEvent: any,
  source: ContextMenuSource,
  uiStateStore: Store<UiStateStore>,
  instanceData?: any, // for when we right-click a specific card or counter
) => {
  clickEvent.preventDefault();
  uiStateStore.update(s => {
    s.contextMenu.isOpen = true;
    s.contextMenu.source = source;
    s.contextMenu.position = {
      x: clickEvent.clientX,
      y: clickEvent.clientY,
    };
    s.contextMenu.instanceData = instanceData || null;

    if (source === ContextMenuInstanceSources.PlayAreaCard || source === ContextMenuInstanceSources.CarouselCard) {
      s.keyboardShortcutTarget.contextMenuTargetCardIds = instanceData.cardIds;
    }
  });
};

export const dismissContextMenu = (
  uiStateStore: Store<UiStateStore>,
  carouselStore: Store<CarouselStore>,
) => {
  // This bit is in case we opened a context menu on the carousel card preview,
  // since opening it there dismisses the ordinary listeners for closing the preview.
  // We're basically holding it open until the contextMenu is closed.
  if (carouselStore.getRawState().previewIsVisible &&
    uiStateStore.getRawState().contextMenu.isOpen &&
    uiStateStore.getRawState().contextMenu.source === ContextMenuInstanceSources.CarouselCard) {
      resetCarouselHoverState(carouselStore);
  }
  uiStateStore.update(s => {
    s.contextMenu.isOpen = false;
    s.contextMenu.showSecondaryMenu = false;
    s.contextMenu.source = null;
    s.contextMenu.position = null;
    s.contextMenu.instanceData = null;
    s.keyboardShortcutTarget.contextMenuTargetCardIds = null;
  });
};

let onWindowLeaveReference: any = null;

const ContextMenu = () => {
  const gameStore = useContext(GameStoreContext);
  const uiStateStore = useContext(UiStateStoreContext);
  const carouselStore = useContext(CarouselStoreContext);

  const {
    isOpen,
    showSecondaryMenu,
    source,
    position,
    instanceData,
  } = uiStateStore.useState(s => s.contextMenu);

  const mainContextMenuRef = useRef(null);

  useEffect(() => {
    // somewhat arbitrarily chose this component to handle clearing everything when
    // you move a card by keyboardShortcut. it was originally spread out across
    // contextMenu/magnifiedCard/here, but to what end? may as well centralize.
    const subToken = PubSub.subscribe(Topics.CardsMovedByShortcut, () => {
      dismissContextMenu(uiStateStore, carouselStore);
      resetCarouselHoverState(carouselStore);
      cancelMagnification(uiStateStore);

      uiStateStore.update(s => {
        s.keyboardShortcutTarget = {
          playAreaCursorPosition: s.keyboardShortcutTarget.playAreaCursorPosition,
          contextMenuTargetCardIds: null,
          selectedCardIds: null,
          hoveredCardId: null,
        };
      });
    });

    // hide context menu whenever mouse leaves window
    if (!onWindowLeaveReference) {
      const onWindowLeave = (leaveEvent: any) => {
        if (didMouseLeaveWindow(leaveEvent)) {
          dismissContextMenu(uiStateStore, carouselStore);
        }
      };
      document.body.addEventListener('mouseout', onWindowLeave);
      onWindowLeaveReference = onWindowLeave;
    }

    return () => {
      PubSub.unsubscribe(subToken);
    };
  }, []);

  if (!isOpen || !position || !source) {
    return null;
  }

  const menuOptions = getMenuOptions(
    source,
    instanceData,
    carouselStore,
    uiStateStore,
  );

  const secondaryMenuOptions = getSecondaryMenuOptions(
    instanceData,
    gameStore,
    uiStateStore,
    carouselStore,
  );

  // values chosen 100% arbitrarily based on eye-balling
  const lateralSafetyMargin = percentPixelsOfWindowWidth(20);
  const verticalSafetyMargin = menuOptions.length * 30;

  let showOnRight = true;
  let showBelow = true;

  if (window.innerWidth - position.x < lateralSafetyMargin) {
    showOnRight = false;
  }

  if (window.innerHeight - position.y < verticalSafetyMargin) {
    showBelow = false;
  }

  const style = {
    left: showOnRight ? position.x : '',
    // it counts from right side
    right: showOnRight ? '' : (window.innerWidth - position.x),
    top: showBelow ? position.y : '',
    // it counts from bottom side
    bottom: showBelow ? '' : (window.innerHeight - position.y),
  };

  const mainMenuWidth = (mainContextMenuRef.current as any)?.getBoundingClientRect().width || 0;
  const hoverableOption = document.querySelector('.contextMenuItem.hoverable');
  const hoverableTop = hoverableOption?.getBoundingClientRect().top;
  const hoverableBottom = hoverableOption?.getBoundingClientRect().bottom;

  const secondaryMenuStyle = {
    left: showOnRight ? position.x + mainMenuWidth : '',
    // it counts from right side
    right: showOnRight ? '' : (window.innerWidth - position.x - mainMenuWidth),
    top: showBelow ? '': hoverableTop,
    // it counts from bottom side
    bottom: showBelow ? hoverableBottom : '',
  };

  return (
    <>
      <div style={style} ref={mainContextMenuRef} className="contextMenu">
        <ul>
          {menuOptions.map((option: any) => {
            if (option.divider) {
              return <hr key={Math.random()} />;
            }
            return (
              <li
                key={option.text}
                className={`contextMenuItem ${option.extraClass || ''} ${option.disabled ? 'disabledMenuOption' : ''}`}
                onClick={() => {
                  if (option.disabled) return;
                  option.action?.();
                  dismissContextMenu(uiStateStore, carouselStore);
                }}
                onMouseEnter={() => {
                  if (!option.disabled){
                    if (option.hoverAction) {
                      option.hoverAction();
                    } else {
                      uiStateStore.update(s => {
                        s.contextMenu.showSecondaryMenu = false;
                      });
                    }
                  }
                }}
              >
                <span>{option.text}</span>
              </li>
            );
          })}
        </ul>
      </div>
      {showSecondaryMenu &&
        <div style={secondaryMenuStyle} className="contextMenu secondaryMenu">
        <ul>
          {secondaryMenuOptions.map((option: any) => {
            if (option.divider) {
              return <hr key={Math.random()} />;
            }
            return (
              <li
                key={option.text}
                className={`contextMenuItem ${option.extraClass || ''} ${option.disabled ? 'disabledMenuOption' : ''}`}
                onClick={() => {
                  if (option.disabled) return;
                  option.action();
                  dismissContextMenu(uiStateStore, carouselStore);
                }}
              >
                <span>{option.text}</span>
              </li>
            );
          })}
        </ul>
      </div>}
    </>
  );
};

export default ContextMenu;