import React, { useContext } from 'react';
import BattlefieldApi from '../../../../utils/BattlefieldApi';
import { cancelMagnification } from '../../PlayAreaUtils';
import { UiStateStoreContext } from '../../../../shared/UiStateStore';
import './PowerToughnessModifier.less';

type PowerToughnessModifierProps = {
  cardIds: string[];
  powerModifier: number;
  toughnessModifier: number;
};

const PowerToughnessModifier = ({
  cardIds, powerModifier, toughnessModifier }: PowerToughnessModifierProps
) => {
  const uiStateStore = useContext(UiStateStoreContext);

  // no minus sign needed cuz it's already negative
  const powerModifierLabel = (powerModifier >= 0 ? '+' : '') + powerModifier;
  const toughnessModifierLabel = (toughnessModifier >= 0 ? '+' : '') + toughnessModifier;
  
  // The various event cancelers here are to prevent the normal card interactions
  // like dragging or tappingfrom being triggered by interactions with this component
  return (
    <div
      className="powerToughnessModifier"
      onMouseDown={(e) => {
        e.stopPropagation();
      }}
      onContextMenu={(e) => {
        e.stopPropagation();
        e.preventDefault();
      }}
      onClick={(e) => {
        e.stopPropagation();
        e.preventDefault();
      }}
      onMouseEnter={(e) => {
        cancelMagnification(uiStateStore);
      }}
    >
      <span
        onClick={(e) => {
          BattlefieldApi.updatePTModifier(cardIds, {
            powerIncrement: e.shiftKey ? 5 : 1
          });
        }}
        onContextMenu={(e) => {
          BattlefieldApi.updatePTModifier(cardIds, {
            powerIncrement: e.shiftKey ? -5 : -1
          });
        }}
      >
        {powerModifierLabel}
      </span>
      <span
        onClick={(e) => {
          BattlefieldApi.updatePTModifier(cardIds, {
            powerIncrement: e.shiftKey ? 5 : 1,
            toughnessIncrement: e.shiftKey ? 5 : 1,
          });
        }}
        onContextMenu={(e) => {
          BattlefieldApi.updatePTModifier(cardIds, {
            powerIncrement: e.shiftKey ? -5 : -1,
            toughnessIncrement: e.shiftKey ? -5 : -1,
          });
        }}
      >/</span>
      <span
        onClick={(e) => {
          BattlefieldApi.updatePTModifier(cardIds, {
            toughnessIncrement: e.shiftKey ? 5 : 1
          });
        }}
        onContextMenu={(e) => {
          BattlefieldApi.updatePTModifier(cardIds, {
            toughnessIncrement: e.shiftKey ? -5 : -1
          });
        }}
      >
        {toughnessModifierLabel}
      </span>
    </div>
  );
};

export default PowerToughnessModifier;