import React, { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import { isEqual } from 'lodash';
import { socket } from '../../../shared/SocketInit';
import { getAuth } from 'firebase/auth';
import KeyboardShortcutsTable from './KeyboardShortcutsTable';
import { ClientActions } from '../../../shared/SocketEnums';
import { DefaultKeyboardShortcuts } from './keyboardShortcutConstants';
import { Topics } from '../../../shared/types';
import { AppStore } from '../../../shared/AppStore';
import './SettingsPanel.less';

type SettingsPanelProps = {
  setShowSaveChanges: (shouldShow: boolean) => void;
};

const SettingsPanel = ({ setShowSaveChanges }: SettingsPanelProps) => {
  const { userSettings, loading } = AppStore.useState(s => s.userData);
  const [newSettings, setNewSettings] = useState(userSettings);

  const saveChanges = async () => {
    const auth = getAuth();
    const authToken = await auth.currentUser?.getIdToken(true);

    await axios.post('/update-settings', {
      authToken,
      newSettings,
    });

    socket.emit(ClientActions.LOAD_USER, (response: any) => {
      if (response.error) {
        AppStore.update(s => {
          s.userData.error = 'Something went wrong while loading user data.';
        });
      } else {
        AppStore.update(s => {
          s.userData.decks = response.decks || [];
          s.userData.userSettings = response.userSettings || null;
          s.settingsChanges.showSaveSuccess = true;
        });
      }
    });
  };

  useEffect(() => {
    const subscriptionToken = PubSub.subscribe(Topics.SaveSettingsChanges,
      (topic: string) => {
        saveChanges();
      }
    );

    const subscriptionToken2 = PubSub.subscribe(Topics.DiscardSettingsChanges,
      (topic: string) => {
        setNewSettings(userSettings);
        AppStore.update(s => { s.settingsChanges.showDiscardSuccess = true; });
      }
    );

    return () => {
      PubSub.unsubscribe(subscriptionToken);
      PubSub.unsubscribe(subscriptionToken2);
    };
  });

  useEffect(() => {
    setShowSaveChanges(!isEqual(userSettings, newSettings));
  }, [newSettings, userSettings]);

  // for backfilling old accounts, remove later
  useEffect(() => {
    if (loading) return;
    if (!userSettings && !newSettings) {
      setNewSettings({
        keyboardShortcuts: DefaultKeyboardShortcuts
      });
    } else if (!userSettings) {
      saveChanges();
    } else if (!newSettings) {
      setNewSettings(userSettings);
    }
  }, [loading, newSettings])

  if (!newSettings) return null;

  return (
    <div className="settingsPanelContainer">
      <KeyboardShortcutsTable
        currentSettings={newSettings!.keyboardShortcuts}
        setNewSettings={setNewSettings}
      />
    </div>
  );
};

export default SettingsPanel;