import React, { useContext, useEffect, useState } from 'react';
import { getAuth } from 'firebase/auth';
import { ContextMenuInstanceSources, Counter, DRAG_ZINDEX, LEFT_MOUSE } from '../../../../shared/types';
import { ONE_SECOND, clearDragListener, getLeftPositionAsPercent, getVerticalPositionAsPercent, setGlobalOnMouseMove } from '../../../../shared/utils';
import { UiStateStoreContext } from '../../shared/UiStateStore';
import BattlefieldApi from '../../utils/BattlefieldApi';
import { showContextMenu } from '../ContextMenu';
import './PlayAreaCounter.less';

type PlayAreaCounterProps = {
  counterData: Counter;
};

const PlayAreaCounter = ({ counterData }: PlayAreaCounterProps) => {
  const uiStateStore = useContext(UiStateStoreContext);

  const { id: counterId, value, label, color, ownerId, zIndex } = counterData;
  const [isDragging, setIsDragging] = useState(false);
  const [position, setPosition] = useState({
    left: counterData.left,
    top: counterData.top,
  });
  const [localZIndex, setLocalZIndex] = useState(zIndex);

  const [lastPosition, setLastPosition] = useState(position);

  // We need this (rather than just using the value itself) because otherwise
  // value is stale and we're always sending the value from the last render
  const [totalChange, setTotalChange] = useState(0);
  const [actionLogTimeout, setActionLogTimeout] = useState<any>(null);

  // for updating other player counters
  useEffect(() => {
    setPosition({ left: counterData.left, top: counterData.top });
  }, [counterData.left, counterData.top]);

  // When we mouseDown, we set localZindex to DRAG_ZINDEX so that
  // there isn't any flickering overlaps before the updated zindex comes in
  // with the game state update. This is what listens for the new zIndex.
  useEffect(() => {
    setLocalZIndex(zIndex);
  }, [zIndex]);

  const userId = getAuth().currentUser!.uid;

  const onLabelMouseDown = (mouseDownEvent: any) => {
    if (mouseDownEvent.button !== LEFT_MOUSE) return;
    if (counterData.ownerId !== userId) return;

    setIsDragging(true);
    setLastPosition(position);

    const mouseDistanceFromLeftEdgeOfCounter = mouseDownEvent.clientX - mouseDownEvent.target.parentElement.getBoundingClientRect().left;
    const mouseDistanceFromTopEdgeOfCounter = mouseDownEvent.clientY - mouseDownEvent.target.parentElement.getBoundingClientRect().top;

    setGlobalOnMouseMove(mouseDownEvent, newMousePosition => {
      const percentLeft = getLeftPositionAsPercent(
        newMousePosition.x - mouseDistanceFromLeftEdgeOfCounter
      );

      const percentTop = getVerticalPositionAsPercent(
        newMousePosition.y - mouseDistanceFromTopEdgeOfCounter
      );

      setPosition({ left: percentLeft, top: percentTop });
    });

    setLocalZIndex(DRAG_ZINDEX);
  };

  const onLabelMouseUp = (mouseUpEvent: any) => {
    if (mouseUpEvent.button !== LEFT_MOUSE) return;
    if (counterData.ownerId !== userId) return;
    if (!isDragging) return;

    let left = position.left;
    let top = position.top;

    // prevent counters being dropped outside the playArea
    if (left < 2) left = 2;
    if (left > 95) left = 95;
    if (top < 0) top = 0;
    if (top > 95) top = 95;

    BattlefieldApi.updateCounter(counterId, { left, top });

    setIsDragging(false);
    clearDragListener();
  };

  const onLabelRightClick = (clickEvent: any) => {
    clickEvent.preventDefault();
    if (counterData.ownerId !== userId) return;
    
    showContextMenu(
      clickEvent,
      ContextMenuInstanceSources.PlayAreaCounter,
      uiStateStore,
      { counterId, label }
    );
  };

  const onValueClick = (clickEvent: any) => {
    if (clickEvent.button !== LEFT_MOUSE) return;
    if (counterData.ownerId !== userId) return;

    const leftUnchanged = position.left === lastPosition.left;
    const topUnchanged = position.top === lastPosition.top;
    const positionUnchanged = leftUnchanged && topUnchanged;

    // don't increment/decrement when we drag with cursor on the value,
    // so detect if we've moved it before deciding whether to increment
    if (positionUnchanged) {
      const increment = clickEvent.shiftKey ? 5 : 1;

      BattlefieldApi.updateCounter(counterId, {
        value: value + increment,
        label,
      });

      setTotalChange(totalChange + increment);
      clearTimeout(actionLogTimeout);
      setActionLogTimeout(setTimeout(() => {
        // the totalChange value itself is stale due to closure
        const updatedTotalChange = totalChange + increment;
        if (updatedTotalChange !== 0) {
          BattlefieldApi.logCounterChange(counterData.label, updatedTotalChange);
          setTotalChange(0);
        }
      }, ONE_SECOND));
    }
  };

  const onValueRightClick = (clickEvent: any) => {
    clickEvent.preventDefault();
    if (counterData.ownerId !== userId) return;

    const decrement = clickEvent.shiftKey ? 5 : 1

    BattlefieldApi.updateCounter(counterId, {
      value: value - decrement,
      label,
    });

    setTotalChange(totalChange - decrement);
    clearTimeout(actionLogTimeout);
    setActionLogTimeout(setTimeout(() => {
      // the totalChange value itself is stale due to closure
      const updatedTotalChange = totalChange - decrement;
      if (updatedTotalChange !== 0) {
        BattlefieldApi.logCounterChange(counterData.label, updatedTotalChange);
        setTotalChange(0);
      }
    }, ONE_SECOND));
  };

  const isOwnedByPlayer = ownerId === userId;

  const style = {
    zIndex: localZIndex,
    left: position.left + '%',
    top: isOwnedByPlayer ? position.top + '%' : '',
    bottom: isOwnedByPlayer ? '' : position.top + '%',
  };

  const animationClass = isOwnedByPlayer ? '' : 'animateTransitions';

  return (
    <div style={style} className={`playAreaCounter ${animationClass}`}>
      <div className={'badge ' + color}
        onMouseDown={onLabelMouseDown}
        onMouseUp={onLabelMouseUp}
      >
        <div
          className="circle"
          onClick={onValueClick}
          onContextMenu={onValueRightClick}
        >
          {value}
        </div>
        <div
          className="ribbon"
          onMouseDown={onLabelMouseDown}
          onMouseUp={onLabelMouseUp}
          onContextMenu={onLabelRightClick}
        >
          {label}
        </div>
      </div>
    </div>
  );
};

export default PlayAreaCounter;