import React, { useContext, useEffect } from 'react';
import { GameStoreContext } from '../../../../shared/GameStore';
import { UiStateStoreContext } from '../../../../shared/UiStateStore';
import { Topics } from '../../../../../../shared/types';
import { blurInputs } from '../../../../../../shared/utils';
import './PlayerSelect.less';

const PlayerSelect = () => {
  const uiStateStore = useContext(UiStateStoreContext);
  const gameStateStore = useContext(GameStoreContext);

  const currentOtherPlayerId = uiStateStore.useState(s => s.currentOtherPlayerId);
  const otherPlayerIds = gameStateStore.useState(s => s.otherPlayerIds);

  const usernames = gameStateStore.useState(s => s.waitingRoom!.usernamesByUserId);

  // only display if there are multiple players to switch between
  if (otherPlayerIds.length < 2) {
    return null;
  }

  const updateViewedPlayer = (e: any) => {
    if (!e?.target?.value) return; // idk how this could ever happen but just in case

    uiStateStore.update(s => {
      s.currentOtherPlayerId = e.target.value;
    });

    // otherwise keyboard focus stays here after clicking it, blocking keyboard shortcuts
    blurInputs();
  };

  useEffect(() => {
    const currentIndex = otherPlayerIds.indexOf(currentOtherPlayerId);
    const numIds = otherPlayerIds.length;
    // thank chatGPT for the oneliners. i never would have thought of the modulo.
    // tl;dr the modulo makes it wrap so that "next" from the end is index 0 and vice versa.
    const subToken = PubSub.subscribe(Topics.CycleNextPlayer, () => {
      const nextId = otherPlayerIds[
        (currentIndex + 1) % numIds
      ];

      uiStateStore.update(s => {
        s.currentOtherPlayerId = nextId;
      });
    });

    const subToken2 = PubSub.subscribe(Topics.CyclePrevPlayer, () => {
      const prevId = otherPlayerIds[
        (currentIndex + numIds - 1) % numIds
      ];

      uiStateStore.update(s => {
        s.currentOtherPlayerId = prevId;
      });
    });

    return () => {
      PubSub.unsubscribe(subToken);
      PubSub.unsubscribe(subToken2);
    };
  }, [currentOtherPlayerId]);

  return (
    <div className="playerSelectContainer">
      <label htmlFor="playerSelect">View Player:</label>
      <select
        value={currentOtherPlayerId}
        onChange={updateViewedPlayer}
        onMouseOut={() => {
          // There's an issue where clicking the dropdown gives it focus, which disables
          // keyboard shortcuts. It blurs onChange, but if you just click outside it to
          // close it without changing anything, then you have to click a second time
          // before it's actually blurred cuz that's just how html inputs/selects work.
          // Anyway, we resolve this issue by blurring on mouseOut.
          // We have the "if" clause just to avoid the user unintentionally blurring chat input
          // when they mouseover the select without thinking about it.
          if (document.activeElement?.tagName === 'SELECT') blurInputs();
        }}
        name="playerSelect"
        id="playerSelect"
      >
        {otherPlayerIds.map(id => (
          <option key={id} value={id}>{usernames[id]}</option>
        ))}
      </select>
    </div>
  );
};

export default PlayerSelect;