import { Store } from 'pullstate';
import { ColorAttributes, Deck, GameFormat, UserSettings, WaitingRoom, ColorFilterStrategies } from './types';

export type AppStoreType = {
  signedIn: boolean;
  // signingIn is true only while waiting for server response to sign-in attempt.
  // used to keep us from acting on firebase sign-in in App.tsx before
  // authConnect has also completed.
  signingIn: boolean;
  tokenRefreshingFn: any;
  lastTokenUpdate: any;
  userData: {
    error: string | null;
    loading: boolean;
    decks: Deck[];
    userSettings: UserSettings | null;
  };
  currentRoom: WaitingRoom | null;
  roomsList: {
    formatFilter: GameFormat;
    titleFilter: string;
  };
  deckManager: {
    formatFilter: GameFormat;
    nameFilter: string;
    colorFilter: ColorAttributes;
    colorFilterStrategy: ColorFilterStrategies;
  };
  createDeckDialog: {
    isOpen: boolean;
    showSuccess: boolean;
  };
  editDeckDialog: {
    isOpen: boolean;
    deck: Deck | null;
    showEditSuccess: boolean;
    showDeleteSuccess: boolean;
  };
  createGameDialog: {
    isOpen: boolean;
    isUpdateMode: boolean;
  };
  signInDialog: {
    isOpen: boolean;
  };
  accountPagePromptDialog: {
    isOpen: boolean;
    mode: 'password' | 'username';
  };
  accountPageGravatarDialog: {
    isOpen: boolean;
  };
  changeShortcutDialog: {
    isOpen: boolean;
    action: string;
  };
  settingsChanges: {
    showSaveSuccess: boolean;
    showDiscardSuccess: boolean;
  };
};

export const AppStore = new Store<AppStoreType>({
  signedIn: false,
  signingIn: false,
  tokenRefreshingFn: null,
  lastTokenUpdate: null,
  userData: {
    error: null,
    loading: true,
    decks: [],
    userSettings: null,
  },
  currentRoom: null,
  roomsList: {
    formatFilter: GameFormat.Any,
    titleFilter: '',
  },
  deckManager: {
    formatFilter: GameFormat.Any,
    nameFilter: '',
    colorFilter: {
      red: false,
      blue: false,
      black: false,
      white: false,
      green: false,
      colorless: false,
    },
    colorFilterStrategy: ColorFilterStrategies.Including,
  },
  createDeckDialog: {
    isOpen: false,
    showSuccess: false,
  },
  editDeckDialog: {
    isOpen: false,
    deck: null,
    showEditSuccess: false,
    showDeleteSuccess: false,
  },
  createGameDialog: {
    isOpen: false,
    isUpdateMode: false,
  },
  signInDialog: {
    isOpen: false,
  },
  accountPagePromptDialog: {
    isOpen: false,
    mode: 'password',
  },
  accountPageGravatarDialog: {
    isOpen: false,
  },
  changeShortcutDialog: {
    isOpen: false,
    action: '',
  },
  settingsChanges: {
    showSaveSuccess: false,
    showDiscardSuccess: false,
  },
});