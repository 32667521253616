import React, { useContext, useEffect, useState } from 'react';
import { GameStoreContext } from '../../shared/GameStore';
import ChatInput from '../../../shared/ChatInput';
import { getAuth } from 'firebase/auth';
import { cancelMagnification, setMagnifiedCardDetails } from '../PlayArea/PlayAreaUtils';
import { UiStateStoreContext } from '../../shared/UiStateStore';
import { CardData } from '../../../../shared/types';
import { socket } from '../../../../shared/SocketInit';
import { getHtmlForMessage, logLatency } from './BattlefieldChatUtils';
import './BattlefieldChat.less';

const BattlefieldChat = () => {
  const gameStore = useContext(GameStoreContext);
  const uiStateStore = useContext(UiStateStoreContext);

  const usernames = gameStore.useState(s => s.waitingRoom!.usernamesByUserId);
  const messageSeries = gameStore.useState(s => s.chat);
  const userId = getAuth().currentUser?.uid;

  // don't auto-scroll the chat when they're hovering a card, because it would
  // move it out from under their cursor and interrupt their reading
  const [isHoveringACardReference, setIsHoveringACardReference] = useState(false);

  useEffect(() => {
    if (!isHoveringACardReference) {
      const chatLog = document.querySelector('.chatLog');
      chatLog!.scrollTop = chatLog!.scrollHeight;
    }
  }, [messageSeries]);

  useEffect(() => {
    const cardReferences = document.querySelectorAll('.hoverableCardReference');

    cardReferences.forEach(cardReference => {
      cardReference.addEventListener('mouseenter', () => {
        const url = cardReference.getAttribute('data-url');
        const altFaceUrl = cardReference.getAttribute('data-altFaceUrl');

        setIsHoveringACardReference(true);
        setMagnifiedCardDetails(
          { url, altFaceUrl } as CardData,
          { current: cardReference },
          uiStateStore,
        );
      });

      cardReference.addEventListener('mouseleave', () => {
        setIsHoveringACardReference(false);
        cancelMagnification(uiStateStore);
      });
    });

    // I originally thought we needed cleanup here to remove listeners, lest we end up
    // with thousands of duplicate listeners, but it doesn't work that way. I tested it
    // and setMagnifiedCardDetails is only ever called once per hover, no matter the number
    // of times this runs.
  }, [messageSeries]);

  return (
    <div className="battlefieldChatOuterContainer">
      <div className="battlefieldChatInnerContainer">
        <div className="chatFlexbox">
          <div className="chatLog" onDoubleClick={() => logLatency(socket)}>
            {messageSeries.map(msgSeries => (
              <div key={msgSeries.id} className={`${msgSeries.userId === userId ? 'userMessages' : 'opponentMessages'} ${msgSeries.isActionLog ? 'actionLogMessages' : ''} chatMessageSeries`}
              >
                {!msgSeries.isActionLog &&
                  <div className="usernameHeader">
                    {usernames[msgSeries.userId]}
                  </div>
                }
                {msgSeries.messages.map(msg => getHtmlForMessage(msg, userId!))}
              </div>
            ))}
          </div>
          <ChatInput/>
        </div>
      </div>
    </div>
  );
};

export default BattlefieldChat;