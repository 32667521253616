import Linkify from 'linkify-react';
import React from 'react';
import { Socket } from 'socket.io-client';
import parse from 'html-react-parser';
import BattlefieldApi from '../../utils/BattlefieldApi';
import { ChatMessage } from '../../../../shared/types';

export const logLatency = (socket: Socket) => {
  window.getSelection()?.empty();
  const start = Date.now();

  socket.emit('ping', () => {
    const duration = Date.now() - start;
    const seconds = Math.floor(duration / 1000); // Get the number of seconds
    const milliseconds = duration % 1000; // Get the remaining milliseconds

    const formattedDuration = `Current latency: ${seconds}s ${milliseconds}ms`;
    BattlefieldApi.sendChatMessage(formattedDuration);
  });
};

const wrapMessage = (msg: ChatMessage, textKey: string) => {
  return (
    <Linkify key={msg.id} options={{ target: '_blank' }}>
      <div className="chatMessage">
        {parse((msg as any)[textKey])}
      </div>
    </Linkify>
  );
};

export const getHtmlForMessage = (msg: ChatMessage, userId: string) => {
  if (msg.restrictedTo) {
    if (msg.restrictedTo.includes(userId!)) {
      return wrapMessage(msg, 'message');
    } else if (msg.senderId === userId) {
      return wrapMessage(msg, 'displayToSenderAs');
    } else {
      return wrapMessage(msg, 'publicMessage');
    }
  } else {
    return wrapMessage(msg, 'message');
  }
};