import { Store } from 'pullstate';
import { AccessRequestLocationOptions, BattlefieldLocations } from '../../../../../shared/types';
import { CarouselStore } from '../../../shared/CarouselStore';
import { UiStateStore } from '../../../shared/UiStateStore';
import BattlefieldApi from '../../../utils/BattlefieldApi';
import { resetCarouselHoverState } from '../../CardCarousel/CarouselUtils';
import { closeDrawer } from '../../CarouselDrawer/CarouselDrawer';
import { GameStore } from '../../../shared/GameStore';

export const getCarouselCardMenuOptions = (
  instanceData: {
    cardIds: string[],
    gathererUrl: string,
    origin: BattlefieldLocations,
    showAltFaceMenuOption: boolean,
    isFacedown?: boolean,
    mirrorMode: boolean,
  },
  carouselStore: Store<CarouselStore>,
  uiStateStore: Store<UiStateStore>,
) => {
  const lockedZone = uiStateStore.getRawState().lockedZone;
  const handIsLocked = lockedZone === AccessRequestLocationOptions.Hand;
  const libraryIsLocked = [AccessRequestLocationOptions.Library, AccessRequestLocationOptions.TopNOfLibrary].includes(lockedZone!);

  const viewOnGatherer = {
    text: 'View on Gatherer',
    action: () => {
      window.open(instanceData.gathererUrl, '_blank');
    },
    disabled: !instanceData.gathererUrl,
  };

  const revealToOtherPlayer = {
    text: 'Reveal to other player',
    hoverAction: () => {
      uiStateStore.update(s => {
        s.contextMenu.showSecondaryMenu = true;
      });
    },
    extraClass: 'hoverable',
    disabled: instanceData.mirrorMode,
  };

  const sendToBottomOfLibrary = {
    text: 'Send to bottom of library',
    action: () => {
      BattlefieldApi.sendCardsToBottomOfLibrary(instanceData.cardIds, instanceData.origin);
      resetCarouselHoverState(carouselStore);
    },
    disabled: libraryIsLocked || (handIsLocked && instanceData.origin === BattlefieldLocations.Hand),
  };

  const shuffleIntoLibrary = {
    text: 'Shuffle into library',
    action: () => {
      closeDrawer(carouselStore, true);
      BattlefieldApi.shuffleCardsIntoLibrary(instanceData.cardIds, instanceData.origin);
      resetCarouselHoverState(carouselStore);
    },
    disabled: libraryIsLocked || (handIsLocked && instanceData.origin === BattlefieldLocations.Hand)
  };

  const turnLibraryCardFaceUp = {
    text: 'Turn face-up',
    action: () => {
      BattlefieldApi.revealCardFromLibraryById(instanceData.cardIds[0]);
      resetCarouselHoverState(carouselStore);
    },
    disabled: libraryIsLocked,
  };

  const turnLibraryCardFaceDown = {
    text: 'Turn face-down',
    action: () => {
      BattlefieldApi.turnCardFromLibraryFacedownById(instanceData.cardIds[0]);
      resetCarouselHoverState(carouselStore);
    },
    disabled: libraryIsLocked,
  };

  const conditionalMenuOptions = [];
  
  // the "!instanceData.isFacedown" clause is only used for our facedown library carousel,
  // but it doesn't affect the other carousels' functionality in any way.
  if (instanceData.showAltFaceMenuOption && !instanceData.isFacedown) {
    conditionalMenuOptions.push({
      text: 'Flip to other face',
      action: () => {
        uiStateStore.update(s => {
          s.flippedCardIds[instanceData.cardIds[0]] =
            !s.flippedCardIds[instanceData.cardIds[0]];
        });
      },
    });
  }

  switch(instanceData.origin) {
    case BattlefieldLocations.Hand:
      return [
        viewOnGatherer,
        revealToOtherPlayer,
        ...conditionalMenuOptions,
        sendToBottomOfLibrary,
        shuffleIntoLibrary,
      ];

    case BattlefieldLocations.Graveyard:
    case BattlefieldLocations.Exile:
      return [
        viewOnGatherer,
        ...conditionalMenuOptions,
        sendToBottomOfLibrary,
        shuffleIntoLibrary,
      ];

    case BattlefieldLocations.TopNOfLibrary:
      return [
        viewOnGatherer,
        revealToOtherPlayer,
        ...conditionalMenuOptions,
        sendToBottomOfLibrary,
      ];

    case BattlefieldLocations.Library:
      if (instanceData.isFacedown) {
        return [
          turnLibraryCardFaceUp,
          revealToOtherPlayer,
          sendToBottomOfLibrary,
        ];
      }

      // for a card that has been turned face-up in a facedown library
      if (carouselStore.getRawState().isViewingLibraryFacedown) {
        return [
          viewOnGatherer,
          revealToOtherPlayer,
          ...conditionalMenuOptions,
          turnLibraryCardFaceDown,
          sendToBottomOfLibrary,
        ];
      }

      // for normal face-up card in normal face-up library
      return [
        viewOnGatherer,
        ...conditionalMenuOptions,
        sendToBottomOfLibrary,
      ];
    
    case BattlefieldLocations.OtherPlayerGraveyard:
    case BattlefieldLocations.OtherPlayerExile:
      return [
        viewOnGatherer,
        ...conditionalMenuOptions,
        {
          text: 'Request return to play under your control',
          action: () => {
            BattlefieldApi.requestReanimation(
              instanceData.cardIds[0],
              instanceData.origin,
              uiStateStore.getRawState().currentOtherPlayerId,
            );
          },
        },
      ];
    
    case BattlefieldLocations.OtherPlayerHand:
    case BattlefieldLocations.OtherPlayerLibrary:
      return [
        viewOnGatherer,
        ...conditionalMenuOptions,
        // different from the sendToBottomOfLibrary used for others above because
        // it isn't disabled when those would be disabled
        {
          text: 'Send to bottom of library',
          action: () => {
            BattlefieldApi.sendCardsToBottomOfLibrary(instanceData.cardIds, instanceData.origin);
            resetCarouselHoverState(carouselStore);
          },
        },
      ];

    default:
      return [];
  }
};

// this is currently only used for carousel cards, which is why it's in this file
export const getSecondaryMenuOptions = (
  instanceData: any,
  gameStore: Store<GameStore>,
  uiStateStore: Store<UiStateStore>,
  carouselStore: Store<CarouselStore>,
) => {
  const playerIds = gameStore.getRawState().otherPlayerIds;

  return playerIds.map(playerId => ({
    text: gameStore.getRawState().waitingRoom?.usernamesByUserId[playerId],
    action: () => {
      BattlefieldApi.revealCard(instanceData.cardIds[0], playerId);
      resetCarouselHoverState(carouselStore);
    },
  }));
};