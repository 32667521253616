import React, { useContext, useEffect, useRef } from 'react';
import { AccessRequestLocationOptions, BattlefieldLocations } from '../../../../shared/types';
import { CarouselId, CarouselStore, CarouselStoreContext } from '../../shared/CarouselStore';
import { UiStateStoreContext } from '../../shared/UiStateStore';
import BattlefieldApi from '../../utils/BattlefieldApi';
import CardCarousel from '../CardCarousel';
import DraggablePreview from '../CardCarousel/components/DraggablePreview';
import FloatingNotificationManager from '../FloatingNoticationManager';
import { Store } from 'pullstate';

import './CarouselDrawer.less';

export const closeDrawer = (carouselStore: Store<CarouselStore>, topNOnly?: boolean) => {
  // if we pass this param, we only want to close it if it's showing topN, not for anything else
  if (topNOnly && carouselStore.getRawState().popupCarouselContent !== BattlefieldLocations.TopNOfLibrary) {
    return;
  }

  carouselStore.update(s => {
    s.isDrawerOpen = false;
  });

  // make sure drawer is closed before privateCardData.revealedLibrary gets set to null
  setTimeout(() => {
    // this is done separately from the update above so that the cards don't vanish
    // as it slides shut
    carouselStore.update(s => {
      s.popupCarouselContent = null;
      s.isViewingLibraryFacedown = false;
    });
    BattlefieldApi.closeRevealedViews(true);
  }, 500);
};

const CarouselDrawer = () => {
  const carouselStore = useContext(CarouselStoreContext);
  const uiStateStore = useContext(UiStateStoreContext);
  const isDrawerOpen = carouselStore.useState(s => s.isDrawerOpen);
  const popupCarouselContent = carouselStore.useState(s => s.popupCarouselContent);

  const lockedZone = uiStateStore.useState(s => s.lockedZone);

  // if we're looking at the lib or topNOfLib and it gets locked, close drawer
  useEffect(() => {
    if ((popupCarouselContent === BattlefieldLocations.Library
        || popupCarouselContent === BattlefieldLocations.TopNOfLibrary)
        && lockedZone && lockedZone !== AccessRequestLocationOptions.Hand) {
          closeDrawer(carouselStore);
    }
  }, [lockedZone]);

  return (
    <div className="carouselDrawer">
      <div className={`slider ${isDrawerOpen ? 'sliderOpen' : ''}`}>
        {/* The FNM needs to be inside the slider so that it's in the same stacking context
        as the draggable preview, otherwise the preview ends up under the notifications. */}
        <FloatingNotificationManager/>

        <div className="mainCarousel">
          <CardCarousel carouselId={CarouselId.Main} contentSource={BattlefieldLocations.Hand} />
        </div>
        <div className="popupCarousel">
          <CardCarousel carouselId={CarouselId.Popup} contentSource={popupCarouselContent} />
        </div>
        <DraggablePreview />
      </div>
    </div>
  );
};

export default CarouselDrawer;