import { initializeApp } from 'firebase/app';

const firebaseConfig = {
  apiKey: "AIzaSyCWuBLJ6NIqdpNp0MtBM6BBYc-mfQOLk8I",
  authDomain: "card-tavern.firebaseapp.com",
  databaseURL: "https://card-tavern.firebaseio.com",
  projectId: "card-tavern",
  storageBucket: "card-tavern.appspot.com",
  messagingSenderId: "1048666121583",
  appId: "1:1048666121583:web:dc72d00b7fce95ecfbb49a",
  measurementId: "G-8FPXKN8W3D"
};

initializeApp(firebaseConfig);