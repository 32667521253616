import { Store } from 'pullstate';
import { AccessRequestLocationOptions, BattlefieldLocations } from '../../../../../shared/types';
import { CarouselStore } from '../../../shared/CarouselStore';
import { UiStateStore } from '../../../shared/UiStateStore';
import BattlefieldApi from '../../../utils/BattlefieldApi';
import { expandPile } from './GenericMenuOptions';
import { closeDrawer } from '../../CarouselDrawer/CarouselDrawer';

export const getGraveyardMenuOptions = (
  carouselStore: Store<CarouselStore>,
  uiStateStore: Store<UiStateStore>,
) => {
  const lockedZone = uiStateStore.getRawState().lockedZone;
  const handIsLocked = lockedZone === AccessRequestLocationOptions.Hand;
  const libraryIsLocked = [AccessRequestLocationOptions.Library, AccessRequestLocationOptions.TopNOfLibrary].includes(lockedZone!);

  return [
    {
      text: 'Expand',
      action: () => {
        expandPile(BattlefieldLocations.Graveyard, carouselStore, uiStateStore);
      },
    },
    {
      text: 'Send graveyard to exile',
      action: () => {
        BattlefieldApi.sendPileToOtherPile(
          BattlefieldLocations.Graveyard,
          BattlefieldLocations.Exile,
        );
      },
    },
    {
      text: 'Send graveyard to hand',
      action: () => {
        BattlefieldApi.sendPileToOtherPile(
          BattlefieldLocations.Graveyard,
          BattlefieldLocations.Hand,
        );
      },
      disabled: handIsLocked,
    },
    {
      text: 'Send graveyard to play',
      action: () => {
        BattlefieldApi.sendPileToOtherPile(
          BattlefieldLocations.Graveyard,
          BattlefieldLocations.PlayArea,
        );
      },
    },
    {
      text: 'Send graveyard to bottom of library',
      action: () => {
        BattlefieldApi.sendPileToBottomOfLibrary(BattlefieldLocations.Graveyard);
      },
      disabled: libraryIsLocked,
    },
    {
      text: 'Shuffle graveyard into library',
      action: () => {
        closeDrawer(carouselStore, true);
        BattlefieldApi.shufflePileIntoLibrary(BattlefieldLocations.Graveyard);
      },
      disabled: libraryIsLocked,
    },
    {
      text: 'Pick card at random from graveyard',
      action: () => {
        closeDrawer(carouselStore, true);
        BattlefieldApi.pickCardAtRandom(BattlefieldLocations.Graveyard);
      },
    },
  ];
};