import React, { useContext, useEffect, useState } from 'react';
import { BattlefieldLocations, CardInPlay, ContextMenuInstanceSources, DRAG_ZINDEX, LEFT_MOUSE } from '../../../../../../shared/types';
import {
  clearDragListener,
  didMouseLeaveWindow,
  getPlayAreaOffsetsForDropEvent,
  getLeftPositionAsPercent,
  getVerticalPositionAsPercent,
  removeCardsOrReturnToOriginalLocation,
  setGlobalOnMouseMove,
  wasEventInsideRect,
  getNextZIndex,
  usePrevious,
} from '../../../../../../shared/utils';
import BattlefieldApi from '../../../../utils/BattlefieldApi';
import { showContextMenu } from '../../../ContextMenu';
import { UiStateStoreContext } from '../../../../shared/UiStateStore';
import { CarouselStoreContext } from '../../../../shared/CarouselStore';
import { cancelMagnification, setMagnifiedCardDetails, nullifyUiStoreSelectionState, checkToShowTrespassingOverlay, updateTrespassingStatusForSingleCard } from '../../PlayAreaUtils';
import { resetCarouselHoverState } from '../../../CardCarousel/CarouselUtils';
import { GameStoreContext } from '../../../../shared/GameStore';
import PowerToughnessModifier from '../PowerToughnessModifier';
import './PlayAreaCard.less';

type PlayAreaCardProps = {
  card: CardInPlay;
  playAreaRef: any;
  setCardsInPlay: any; // (callback: (cardsInPlay: CardInPlay[]) => void) => void;
};

let onCardLeaveReference: any = null;

const PlayAreaCard = React.memo(React.forwardRef(({
  card,
  playAreaRef,
  setCardsInPlay
}: PlayAreaCardProps, cardRef: any) => {
  const gameStore = useContext(GameStoreContext);
  const uiStateStore = useContext(UiStateStoreContext);
  const carouselStore = useContext(CarouselStoreContext);

  const [isDragging, setIsDragging] = useState(false);
  const [mouseIsDown, setMouseIsDown] = useState(false);

  const mirrorMode = gameStore.useState(s => s.mirrorMode);
  const counters = gameStore.useState(s => s.publicCounterData);

  const previousCardState = usePrevious(card);
  // animations for tap/untap actions
  useEffect(() => {
    if (!previousCardState) return;
    if (previousCardState.tapped !== card.tapped) {
      if (card.tapped) {
        cardRef.current.classList.add('tapped');
      } else {
        cardRef.current.classList.remove('tapped');
      }
      cardRef.current.classList.add('transitioning');
      setTimeout(() => {
        cardRef.current.classList.remove('transitioning');
      }, 200);
    }

    if (card.isHighlighted && card.isHighlighted !== previousCardState.isHighlighted) {
      cardRef.current.classList.add('highlighted');
      setTimeout(() => {
        cardRef.current.classList.remove('highlighted');
      }, 400);
    }
  });

  const updateCard = (
    updatedCard: CardInPlay,
    sync: boolean,
    incrementZIndex?: boolean,
  ) => {
    setCardsInPlay((cardsInPlay: CardInPlay[]) => {
      // deselect any selected cards when we move a non-selected card
      nullifyUiStoreSelectionState(uiStateStore);

      let otherCards = cardsInPlay.filter(cardInPlay => 
        cardInPlay.id !== updatedCard.id
      );

      if (incrementZIndex) {
        updatedCard.zIndex = getNextZIndex(cardsInPlay, counters, updatedCard.id);
      }

      const updatedCards = otherCards.concat([ updatedCard ]);

      if (sync) {
        BattlefieldApi.moveCardsWithinPlayArea(updatedCards);
      }

      return updatedCards;
    });
  };

  const removeCardFromPlay = () => {
    setCardsInPlay((cardsInPlay: CardInPlay[]) =>
      cardsInPlay.filter(cardInPlay => cardInPlay.id !== card.id)
    );
  };

  const onMouseDown = (mouseDownEvent: any) => {
    if (mouseDownEvent.button !== LEFT_MOUSE) return;

    setMouseIsDown(true);
    cancelMagnification(uiStateStore);
  };

  const onMouseUp = (mouseUpEvent: any) => {
    if (mouseUpEvent.button !== LEFT_MOUSE) return;
    // we null this via cancelMagnification when we mousedown on a card,
    // and we reset it when we mouseup. it's to prevent use of shortcuts during drag.
    uiStateStore.update(s => {
      s.keyboardShortcutTarget.hoveredCardId = card.id;
    });
    setMouseIsDown(false);
    if (!isDragging) return;
    mouseUpEvent.preventDefault();
    mouseUpEvent.persist();
    // remove windowExit listener when drag ends so there's never more than one
    cardRef.current.removeEventListener('mouseout', onCardLeaveReference);

    setIsDragging(false);
    clearDragListener();

    carouselStore.update(s => {
      s.activeCardOrigin = null;
      s.isDraggingCard = false;
    });

    uiStateStore.update(s => {
      s.isTrespassing = false;
    });

    const isNotOverDrawer = mouseUpEvent.clientY < document.querySelector('.slider')!.getBoundingClientRect().top;
    
    // update card location in playArea if it was just moved around there
    if (wasEventInsideRect(mouseUpEvent, playAreaRef) && isNotOverDrawer) {
      const [leftOffset, topOffset] = getPlayAreaOffsetsForDropEvent(mouseUpEvent, playAreaRef);
      const cardRect = cardRef.current.getBoundingClientRect();
      const tappedOffset = card.tapped ? (cardRect.height - cardRect.width) / 2 : 0;

      const left = getLeftPositionAsPercent(cardRect.left + leftOffset - tappedOffset);
      const top = getVerticalPositionAsPercent(cardRect.top + topOffset + tappedOffset);

      updateCard({
        ...card,
        isDragging: false,
        left,
        top,
        originalLeft: left,
        originalTop: top,
      }, true, true);

      updateTrespassingStatusForSingleCard(top, card, uiStateStore);
    } else {
      removeCardsOrReturnToOriginalLocation(
        mouseUpEvent,
        BattlefieldLocations.PlayArea,
        [ card ],
        removeCardFromPlay,
        () => updateCard({
          ...card,
          left: card.originalLeft,
          top: card.originalTop
        }, false)
      );
    }
  };

  const onRightClick = (clickEvent: any) => {
    cancelMagnification(uiStateStore);

    // deselect everything when right-clicking an unselected card
    nullifyUiStoreSelectionState(uiStateStore);

    showContextMenu(
      clickEvent,
      ContextMenuInstanceSources.PlayAreaCard,
      uiStateStore,
      {
        cardIds: [ card.id ],
        gathererUrl: card.gathererUrl,
        showAltFaceMenuOption: !!card.altFaceUrl && !card.isFacedown,
        showTurnFaceUpMenuOption: card.isFacedown,
        showTurnFaceDownMenuOption: !card.isFacedown,
        mirrorMode,
      },
    );
  };

  const onMouseEnter = (mouseEnterEvent: any) => {
    if (isDragging) return;
    if (uiStateStore.getRawState().isDragSelecting) return;

    if (!card.isFacedown || card.isRevealedToOwner) {
      setMagnifiedCardDetails(card, cardRef, uiStateStore);
    } else {
      uiStateStore.update(s => {
        s.keyboardShortcutTarget.hoveredCardId = card.id;
      });
    }
  };

  const onMouseLeave = (mouseLeaveEvent: any) => {
    cancelMagnification(uiStateStore);
    uiStateStore.update(s => {
      s.keyboardShortcutTarget.playAreaCursorPosition = null;
    });
  };

  // Basically we don't want to start dragging onMouseDown, because then it starts dragging in
  // the middle of a double click for tap/untap. So we mark mouseIsDown instead and start
  // the actual drag when the mouse is moved instead.
  const onMouseMove = (mouseMoveEvent: any) => {
    if (mouseIsDown && !isDragging) {
      setIsDragging(true);
      // reset everything involving drag state if we move the mouse out of the window
      const onCardLeave = (leaveEvent: any) => {
        if (didMouseLeaveWindow(leaveEvent)) {
          setIsDragging(false);
          setMouseIsDown(false);
          clearDragListener();
          uiStateStore.update(s => {
            s.isTrespassing = false;
          });

          // return card to original location
          updateCard({
            ...card,
            isDragging: false,
            left: card.originalLeft,
            top: card.originalTop
          }, false, true);

          cardRef.current.removeEventListener('mouseout', onCardLeaveReference);

          resetCarouselHoverState(carouselStore);
        }
      };
      cardRef.current.addEventListener('mouseout', onCardLeave);
      onCardLeaveReference = onCardLeave;

      const mouseDistanceFromLeftEdgeOfCard = mouseMoveEvent.clientX - mouseMoveEvent.target.getBoundingClientRect().left;
      const mouseDistanceFromTopEdgeOfCard = mouseMoveEvent.clientY - mouseMoveEvent.target.getBoundingClientRect().top;

      const cardRect = cardRef.current.getBoundingClientRect();
      const tappedOffset = card.tapped ? (cardRect.height - cardRect.width) / 2 : 0;

      carouselStore.update(s => {
        s.activeCardOrigin = BattlefieldLocations.PlayArea;
        s.isDraggingCard = true;
      });

      setGlobalOnMouseMove(mouseMoveEvent, (newMousePosition, moveTarget) => {
        const percentLeft = getLeftPositionAsPercent(
          newMousePosition.x - mouseDistanceFromLeftEdgeOfCard - tappedOffset
        );
        const percentTop = getVerticalPositionAsPercent(
          newMousePosition.y - mouseDistanceFromTopEdgeOfCard + tappedOffset
        );

        checkToShowTrespassingOverlay(moveTarget, uiStateStore);

        updateCard({
          ...card,
          isDragging: true,
          left: percentLeft,
          top: percentTop,
          zIndex: DRAG_ZINDEX, // so that while dragging, card is on top of drawer (if open)
        }, false);
      });
    } else {
      uiStateStore.update(s => {
        const x = mouseMoveEvent.clientX;
        const y = mouseMoveEvent.clientY;
        s.keyboardShortcutTarget.playAreaCursorPosition = [x, y];
      });
    }
  };

  // We use onClick instead of onDoubleClick because onDoubleClick is rate-limited,
  // so consecutive double clicks don't register.
  // Instead we use e.detail to detect double clicks.
  const tapOrUntap = (e: any) => {
    if (!(e.detail % 2)) {
      BattlefieldApi.tapOrUntapCards([card.id]);
    }
  };

  // if it's been tapped since previous load, or is tapped on initial load,
  // display it in tapped state from the start with no animation.
  const tapped = !!(previousCardState?.tapped && card.tapped) ||
  (!previousCardState && card.tapped);

  return (
    <div
      className={`${tapped ? 'tapped' : ''} cardInPlay`}
      onMouseDown={onMouseDown}
      onMouseUp={onMouseUp}
      onClick={tapOrUntap}
      onContextMenu={onRightClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onMouseMove={onMouseMove}
      onDragStart={(e: React.MouseEvent) => { e.preventDefault(); }}
      ref={cardRef}
      style={{
        left: card.left + '%',
        top: card.top + '%',
        zIndex: card.zIndex
      }}
    >
      <div className={`flipper ${card.isFacedown ? 'flipped' : ''}`}>
        <div className="front">
          <div className={`flipper ${card.showAltFace ? 'flipped' : ''}`}>
            <div className="front" style={{ backgroundImage: `url("${card.url}")`}}/>
            {card.altFaceUrl &&
            <div className="back" style={{ backgroundImage: `url("${card.altFaceUrl}")`}}/>}
          </div>
        </div>
        <div className="back faceDownCardInPlay"/>
      </div>

      {card.showPTModifier &&
        <PowerToughnessModifier
          cardIds={[card.id]}
          powerModifier={card.powerModifier}
          toughnessModifier={card.toughnessModifier}
        />
      }
    </div>
  );
}));

export default PlayAreaCard;