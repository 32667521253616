import React from 'react';
import { useContext } from 'react';
import { BattlefieldLocations } from '../../../../shared/types';
import { GameStoreContext } from '../../shared/GameStore';
import Pile from './Pile';

type WrappedPileProps = {
  className: string;
  // otherPlayerId?: string;
  location: BattlefieldLocations;
};

const WrappedPile = ({ className, location }: WrappedPileProps) => {
  const gameStore = useContext(GameStoreContext);
  const initialLoadComplete = gameStore.useState(s => s.initialLoadComplete);

  const isOtherPlayerPile = 
   [BattlefieldLocations.OtherPlayerExile,
    BattlefieldLocations.OtherPlayerGraveyard]
      .includes(location);

  if (initialLoadComplete) {
    return <Pile
      className={className}
      isOtherPlayerPile={isOtherPlayerPile}
      location={location}
    />;
  } else return null;
};

export default WrappedPile;