import React from 'react';
import { MD5 } from 'crypto-js';
import { Avatar, Button } from '@mui/material';
import { getAuth } from 'firebase/auth';
import { useForceUpdate } from '../../../../shared/utils';
import SimplePromptDialog from './SimplePromptDialog';
import GravatarDialog from './GravatarDialog';
import { AppStore } from '../../../../shared/AppStore';
import './AccountPage.less';

const AccountPage = () => {
  const auth = getAuth();
  const user = auth.currentUser!;
  const forceUpdate = useForceUpdate();

  const username = user.displayName;
  const email = user.email;
  const accountCreationDate = new Date(user?.metadata.creationTime!)
    .toLocaleDateString('en-us', { weekday: 'long', year: 'numeric', month: 'short', day: 'numeric'});

  const md5Hash = MD5(email!.trim().toLowerCase());
  const gravatarUrl = `https://www.gravatar.com/avatar/${md5Hash}`;

  const handleChangeUsername = () => {
    AppStore.update(s => {
      s.accountPagePromptDialog.isOpen = true;
      s.accountPagePromptDialog.mode = 'username';
    });
  };

  const handleChangePassword = () => {
    AppStore.update(s => {
      s.accountPagePromptDialog.isOpen = true;
      s.accountPagePromptDialog.mode = 'password';
    });
  };

  const handleChangeAvatar = () => {
    AppStore.update(s => {
      s.accountPageGravatarDialog.isOpen = true;
    });
  };

  return (
    <div className="accountContainer">
      <div className="avatarContainer">
        <div className="playerAvatar">
          <Avatar src={gravatarUrl} style={{ height: '100%', width: '100%' }}/>
        </div>

        <Button onClick={handleChangeAvatar}>Change Avatar</Button>
      </div>

      <div className="accountDetailsContainer">
        <div className="labelsContainer">
          <div className="usernameLabel">{username}</div>
          <div>{email}</div>
          <div>{`Account created ${accountCreationDate}`}</div>
        </div>

        <div className="buttonsContainer">
          <Button variant="contained" onClick={handleChangeUsername}>
            Change Username
          </Button>
          <Button variant="contained" onClick={handleChangePassword}>
            Change Password
          </Button>
        </div>

        <SimplePromptDialog forceParentUpdate={forceUpdate}/>
        <GravatarDialog/>
      </div>
    </div>
  );
};

export default AccountPage;