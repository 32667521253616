import { AccessRequestLocationOptions, BattlefieldLocations, CardData, CardInPlay, CounterColor, DecklistItem, DieVariant, DropEventData, ErrorResponse, FacedownCard, GameState, GameStateResponse, LifeCounterId, RequestDetails, RequestOutcome, Topics } from '../../../shared/types';
import { socket } from '../../../shared/SocketInit';
import { Store } from 'pullstate';
import { GameStore } from '../shared/GameStore';
import { ClientActions, ServerErrors, ServerNotifications } from '../../../shared/SocketEnums';

const errorHandler = (callback?: Function) => {
  return (...args: any) => {
    if (args[0]?.error) {
      if (args[0].error === ServerErrors.LOGGED_OUT) {
        alert('Your session has expired. Please log in again.');
      }
    } else {
      if (callback) {
        callback(...args);
      }
    }
  }
};

const linkStoreToServer = (gameStore: Store<GameStore>) => {
  socket.emit(ClientActions.LOAD_GAME_STATE, 
    (response: GameStateResponse | ErrorResponse) => {
      if ('error' in response) {
        window.location = '/' as any;
      } else {
        gameStore.update(s => {
          s.startTime = response.startTime;
          s.waitingRoom = response.waitingRoom;
          s.chat = response.chat;
          s.playerStatuses = response.playerStatuses;
          s.lifeTotals = response.lifeTotals;
          s.publicCounterData = response.publicCounterData;
          s.publicCardData = response.publicCardData;
          s.privateCardData = response.privateCardData;
          s.otherPlayerIds = response.otherPlayerIds;
          s.mirrorMode = response.mirrorMode;
          s.initialLoadComplete = true;
        });
      }
    }
  );

  socket.on(ServerNotifications.GAME_UPDATE, (gameState: GameState) => {
    gameStore.update(s => {
      s.chat = gameState.chat;
      s.playerStatuses = gameState.playerStatuses;
      s.lifeTotals = gameState.lifeTotals;
      s.publicCounterData = gameState.publicCounterData;
      s.publicCardData = gameState.publicCardData;
      s.privateCardData = {
        ...gameState.privateCardData,
        // We don't send the values for these 4 keys on every update, only on initial load.
        // They are deliberately undefined in the data sent for GAME_UPDATE, so we ignore them.
        startingDecklist: s.privateCardData.startingDecklist,
        startingSideboard: s.privateCardData.startingSideboard,
        currentDecklist: s.privateCardData.currentDecklist,
        currentSideboard: s.privateCardData.currentSideboard,
      };
    });
  });

  socket.on(ServerNotifications.REQUEST_FROM_OTHER_PLAYER, (requestDetails: RequestDetails) => {
    PubSub.publish(Topics.RequestReceived, requestDetails);
  });

  socket.on(ServerNotifications.OUTCOME_OF_REQUEST, (requestOutcome: RequestOutcome) => {
    PubSub.publish(Topics.RequestOutcomeReceived, requestOutcome);
  });
};

// The backend doesn't know about the Carousel-versions of enum values,
// nor does it need to. So if we have one of those, we just reduce it
// to its base type e.g. GraveyardCarousel -> Graveyard.
const stripCarousel = (location: BattlefieldLocations) => {
  return location.replace(/Carousel/, '') as BattlefieldLocations;
};

const addCardsToHand = (
  cardData: Partial<CardData>,
  quantity: number,
) => {
  socket.emit(ClientActions.ADD_CARDS_TO_HAND, {
    cardData,
    quantity,
  }, errorHandler());
};

const addCardsToPlayArea = (
  cardData: Partial<CardData>,
  quantity: number,
  isToken: boolean,
) => {
  socket.emit(ClientActions.ADD_CARDS_TO_PLAY_AREA, {
    cardData,
    quantity,
    isToken,
  }, errorHandler(() => {
    // delay to make sure server update is received and processed first
    setTimeout(() => {
      PubSub.publish(Topics.CardsCreatedInPlay);
    }, 100);
  }));
};

const moveCardsById = (
  destination: BattlefieldLocations,
  cardIds: string[],
  resetIfApplicable: Function,
  destinationDetails?: {
    isTopOfLib?: boolean;
    isBottomOfLib?: boolean;
    shuffleIntoLib?: boolean;
  },
) => {
  socket.emit(ClientActions.MOVE_CARDS_BY_ID, {
    destination, cardIds, destinationDetails: destinationDetails || {},
  }, errorHandler(() => {
    resetIfApplicable(cardIds);
  }));
};

const moveCardsBetweenZones = (
  destination: BattlefieldLocations,
  origin: BattlefieldLocations,
  newCardsInDestination: CardData[] | CardInPlay[],
  cardsToRemoveFromOrigin: CardData[] | CardInPlay[],
  cardsBelongToOpponent?: boolean,
) => {
  newCardsInDestination = newCardsInDestination.map(card => ({
    ...card,
    // ref.current is an HTMLElement, which can't be serialized.
    // But we don't need to store it in server anyway, so we just remove it here.
    ref: undefined,
  }));

  if (cardsToRemoveFromOrigin) {
    cardsToRemoveFromOrigin = cardsToRemoveFromOrigin.map((card: any) => ({
      ...card,
      ref: undefined,
    }));
  }

  // The only case where the backend needs to differentiate,
  // since it UNSHIFTS cards on top of the library but has to INSERT into the lib carousel
  if (destination !== BattlefieldLocations.LibraryCarousel) {
    destination = stripCarousel(destination);
  }

  socket.emit(ClientActions.MOVE_CARDS_BETWEEN_ZONES, {
    destination,
    origin: stripCarousel(origin),
    newCardsInDestination,
    cardsToRemoveFromOrigin,
    cardsBelongToOpponent,
  }, errorHandler());
};

const moveCardsWithinPlayArea = (
  updatedCards: CardInPlay[],
) => {
  // cards in play are stored without the ref attribute since refs are not serializable
  socket.emit(ClientActions.MOVE_CARDS_WITHIN_PLAY_AREA, {
    updatedCards: updatedCards.map(card => ({
      ...card,
      ref: undefined,
    }))
  }, errorHandler());
};

const revealCardFromLibraryById = (cardId: string) => {
  socket.emit(ClientActions.REVEAL_CARD_FROM_LIBRARY_BY_ID, { cardId }, errorHandler());
};

const turnCardFromLibraryFacedownById = (cardId: string) => {
  socket.emit(ClientActions.TURN_LIBRARY_CARD_FACEDOWN_BY_ID, { cardId }, errorHandler());
};

const drawNCardsFromLibraryToDestination = (
  numCards: number,
  destination: BattlefieldLocations,
  sendToBottom?: boolean,
) => {
  socket.emit(ClientActions.DRAW_N_CARDS_FROM_LIBRARY, {
    numCards,
    destination,
    sendToBottom,
  }, errorHandler());
};

const cascade = (
  cmc: number,
) => {
  socket.emit(ClientActions.CASCADE, {
    cmc,
  }, errorHandler());
};

const drawCardByIdFromLibraryToDestination = (
  dropData: DropEventData,
  location: BattlefieldLocations,
  onCardDrop: (dropData: DropEventData) => any,
) => {
  socket.emit(
    ClientActions.DRAW_CARD_BY_ID_FROM_LIBRARY,
    { cardId: dropData.cards[0].id },
    errorHandler((response: { card: CardData }) => {
      if (!response.card) return;

      const newCardsInDestination = onCardDrop({ ...dropData, cards: [response.card] });

      moveCardsBetweenZones(location, dropData.cameFrom, newCardsInDestination, [response.card]);

      PubSub.publish(Topics.DroppedCardsReceived, {
        cameFrom: dropData.cameFrom,
        receivedBy: location,
        cardIds: [response.card.id]
      });
    })
  );
};

const updateFacedownLibrary = (
  cards: FacedownCard[],
  droppedCards: CardData[] | CardInPlay[] | FacedownCard[],
  origin: BattlefieldLocations,
) => {
  droppedCards = droppedCards.map((card: any) => ({
    ...card,
    ref: undefined,
  }));
  socket.emit(ClientActions.UPDATE_FACEDOWN_LIBRARY,
    { cards, droppedCards, origin }, errorHandler());
};

// should really rewrite this to be a single API call, zero reason it needs to be two
// edit: fyi it's two because we need the card data with which to call onCardDrop
// edit2: okay sure but why do we NEED to call onCardDrop? just for ui responsiveness?
// can we not just wait for the server update?
const drawTopCardFromLibraryToDestination = (
  dropData: DropEventData,
  location: BattlefieldLocations,
  onCardDrop: (dropData: DropEventData) => any,
) => {
  socket.emit(
    ClientActions.DRAW_TOP_CARD_FROM_LIBRARY,
    errorHandler((response: { card: CardData }) => {
      if (!response.card) return;
      const newCardsInDestination = onCardDrop({ ...dropData, cards: [response.card] });
      moveCardsBetweenZones(location, dropData.cameFrom, newCardsInDestination, [response.card]);
      PubSub.publish(Topics.DroppedCardsReceived, {
        cameFrom: dropData.cameFrom,
        receivedBy: location,
        cardIds: [response.card.id]
      });
    })
  );
};

const reorderCarousel = (
  location: BattlefieldLocations,
  newCardsInCarousel: CardData[]
) => {
  socket.emit(ClientActions.REORDER_CAROUSEL, {
    location: stripCarousel(location),
    newCardsInCarousel,
  }, errorHandler());
};

const sortHand = () => {
  socket.emit(ClientActions.SORT_HAND, errorHandler());
};

// Causes privateCardData to be updated to include revealedLibrary,
// which is then reset to null when the popup carousel is closed
const openLibrary = () => {
  socket.emit(ClientActions.OPEN_LIBRARY, errorHandler());
};

const closeRevealedViews = (closeOpponentViews: boolean, closeLibrary?: boolean) => {
  // close both by default if no argument given
  // remove this weird variable
  let _closeLibrary = closeLibrary;
  if (closeLibrary === undefined) {
    _closeLibrary = true;
  }
  socket.emit(ClientActions.CLOSE_REVEALED_VIEWS, {
    closeLibrary: _closeLibrary,
    closeOpponentViews,
  }, errorHandler());
};

const shuffleLibrary = () => {
  socket.emit(ClientActions.SHUFFLE_LIBRARY, errorHandler());
};

const createCounter = () => {
  socket.emit(ClientActions.CREATE_COUNTER, errorHandler());
};

const deleteAllCounters = () => {
  socket.emit(ClientActions.DELETE_ALL_COUNTERS, errorHandler());
};

type CounterUpdateOptions = {
  value?: number;
  color?: CounterColor;
  label?: string;
  left?: number;
  top?: number;
};

const updateCounter = (id: string, options: CounterUpdateOptions) => {
  socket.emit(ClientActions.UPDATE_COUNTER, { id, ...options }, errorHandler());
};

const deleteCounter = (id: string) => {
  socket.emit(ClientActions.DELETE_COUNTER, { id }, errorHandler());
};

const logCounterChange = (counterLabel: string, totalChange: number) => {
  socket.emit(ClientActions.LOG_COUNTER_CHANGE, { counterLabel, totalChange }, errorHandler());
};

const sendCardsToBottomOfLibrary = (cardIds: string[], origin: BattlefieldLocations) => {
  socket.emit(ClientActions.SEND_CARDS_TO_BOTTOM_OF_LIBRARY, { cardIds, origin }, errorHandler());
};

const sendPileToBottomOfLibrary = (location: BattlefieldLocations) => {
  socket.emit(ClientActions.SEND_PILE_TO_BOTTOM_OF_LIBRARY, { location }, errorHandler());
};

const shuffleCardsIntoLibrary = (cardIds: string[], origin: BattlefieldLocations) => {
  socket.emit(ClientActions.SHUFFLE_CARDS_INTO_LIBRARY, { cardIds, origin }, errorHandler());
};

const shufflePileIntoLibrary = (location: BattlefieldLocations) => {
  socket.emit(ClientActions.SHUFFLE_PILE_INTO_LIBRARY, { location }, errorHandler());
};

const sendPileToOtherPile = (origin: BattlefieldLocations, destination: BattlefieldLocations) => {
  socket.emit(ClientActions.SEND_PILE_TO_OTHER_PILE, {
    origin,
    destination,
  }, errorHandler());
};

const mulligan = () => {
  socket.emit(ClientActions.MULLIGAN, errorHandler());
};

const discardOrExileNCardsAtRandom = (numCards: number, destination: BattlefieldLocations) => {
  socket.emit(ClientActions.DISCARD_N_AT_RANDOM, {
    numCards,
    destination,
  }, errorHandler());
};

const revealTopNCardsOfLibrary = (numCards: number) => {
  socket.emit(ClientActions.REVEAL_TOP_N_OF_LIBRARY, { numCards }, errorHandler());
};

const pickCardAtRandom = (pile: BattlefieldLocations) => {
  socket.emit(ClientActions.PICK_CARD_AT_RANDOM, { pile }, errorHandler());
};

const deleteCards = (cardIds: string[]) => {
  socket.emit(ClientActions.DELETE_CARDS, { cardIds }, errorHandler());
};

const untapAll = () => {
  socket.emit(ClientActions.UNTAP_ALL, errorHandler());
};

const drawAttentionToCards = (cardIds: string[]) => {
  socket.emit(ClientActions.DRAW_ATTENTION_TO_CARDS, { cardIds }, errorHandler());
};

const toggleKeepTopCardRevealed = (revealedToSelf: boolean, revealedToAll: boolean) => {
  socket.emit(ClientActions.TOGGLE_KEEP_TOP_CARD_REVEALED, {
    revealedToSelf,
    revealedToAll,
  }, errorHandler());
};

const flipCardsToAltFace = (cardIds: string[]) => {
  socket.emit(ClientActions.FLIP_CARDS_TO_ALT_FACES, { cardIds }, errorHandler());
};

const cloneCards = (cardIds: string[]) => {
  socket.emit(ClientActions.CLONE_CARDS, { cardIds }, errorHandler());
};

const updatePTModifier = (cardIds: string[], options: {
  shouldToggle?: boolean,
  powerIncrement?: number,
  toughnessIncrement?: number,
}) => {
  socket.emit(ClientActions.UPDATE_PT_MODIFIER, { cardIds, options }, errorHandler());
};

const updateTrespassers = (cardIds: string[], isTrespassing: boolean, otherPlayerId?: string) => {
  socket.emit(ClientActions.UPDATE_TRESPASSERS, {
    cardIds, isTrespassing, otherPlayerId,
  }, errorHandler());
};

const turnCardsFaceUp = (cardIds: string[]) => {
  socket.emit(ClientActions.TURN_CARDS_FACE_UP_OR_DOWN, { cardIds, faceDown: false },
    errorHandler());
};

const turnCardsFaceDown = (cardIds: string[]) => {
  socket.emit(ClientActions.TURN_CARDS_FACE_UP_OR_DOWN, { cardIds, faceDown: true },
    errorHandler());
};

const toggleCardsFaceDown = (cardIds: string[]) => {
  socket.emit(ClientActions.TURN_CARDS_FACE_UP_OR_DOWN, { cardIds }, errorHandler());
};

const revealCardsToOwner = (cardIds: string[]) => {
  socket.emit(ClientActions.REVEAL_CARDS_TO_OWNER, { cardIds }, errorHandler());
};

const tapOrUntapCards = (cardIds: string[]) => {
  socket.emit(ClientActions.TAP_OR_UNTAP_CARDS, { cardIds }, errorHandler());
};

const giveControlOfCards = (cardIds: string[], otherPlayerId: string) => {
  socket.emit(ClientActions.GIVE_CONTROL_OF_CARDS, { cardIds, otherPlayerId }, errorHandler());
};

const requestControlOfCards = (cardIds: string[]) => {
  socket.emit(ClientActions.REQUEST_CONTROL_OF_CARDS, { cardIds }, errorHandler());
};

const requestToLookAtCards = (location: AccessRequestLocationOptions, ownerId: string, numCards?: number) => {
  socket.emit(ClientActions.REQUEST_TO_VIEW_CARDS, { location, ownerId, numCards },
    errorHandler((response: any) => {
      if (response.error === ServerErrors.PLAYER_NOT_CONNECTED) {
        alert('That player is not currently connected to the game, so the request could not be sent.');
      }

      if (response.error === ServerErrors.CARDS_LOCKED) {
        alert('Another player is currently viewing this player\'s cards, please try again when they have finished.');
      }
    })
  );
};

const requestReanimation = (cardId: string, location: BattlefieldLocations, ownerId: string) => {
  socket.emit(ClientActions.REQUEST_REANIMATION, { cardId, location, ownerId }, errorHandler());
};

const revealCard = (cardId: string, playerId: string) => {
  socket.emit(ClientActions.REVEAL_CARD, { cardId, playerId }, errorHandler());
};

const updateLifeCounter = (counterId: LifeCounterId, newValue: number) => {
  socket.emit(ClientActions.UPDATE_LIFE_COUNTER, { counterId, newValue }, errorHandler());
};

const logLifeCounterChange = (counterId: LifeCounterId, totalChange: number) => {
  socket.emit(ClientActions.LOG_LIFE_COUNTER_CHANGE, { counterId, totalChange }, errorHandler());
};

const sendChatMessage = (message: string) => {
  socket.emit(ClientActions.SEND_BATTLEFIELD_MESSAGE, { message }, errorHandler());
};

const exitGame = (callback: Function) => {
  socket.emit(ClientActions.EXIT_GAME, errorHandler(callback));
};

const resetGameForUser = () => {
  socket.emit(ClientActions.RESET_GAME_STATE, errorHandler());
};

const saveSideboardChanges = (
  sideboard: DecklistItem[],
  decklist: DecklistItem[],
  gameStore: Store<GameStore>,
) => {
  socket.emit(ClientActions.SAVE_SIDEBOARD_CHANGES, {
    sideboard,
    decklist,
  }, errorHandler((currentDecklist: DecklistItem[], currentSideboard: DecklistItem[]) => {
    gameStore.update(s => {
      s.privateCardData.currentDecklist = currentDecklist;
      s.privateCardData.currentSideboard = currentSideboard;
    });
  }));
};

const swapDeck = (deckId: string, gameStore: Store<GameStore>) => {
  socket.emit(ClientActions.SWAP_DECK, { deckId },
    errorHandler((decklist: DecklistItem[], sideboard: DecklistItem[]) => {
      gameStore.update(s => {
        s.privateCardData.currentDecklist = decklist;
        s.privateCardData.startingDecklist = decklist;
        s.privateCardData.currentSideboard = sideboard;
        s.privateCardData.startingSideboard = sideboard;
      });
    })
  );
};

const rollDie = (dieVariant: DieVariant) => {
  socket.emit(ClientActions.ROLL_DIE, { dieVariant }, errorHandler());
};

const releaseAllLockedZones = () => {
  socket.emit(ClientActions.RELEASE_ALL_LOCKS);
};

export default {
  createCounter,
  updateCounter,
  deleteCounter,
  deleteAllCounters,
  logCounterChange,

  addCardsToHand,
  addCardsToPlayArea,
  deleteCards,
  untapAll,

  drawAttentionToCards,
  flipCardsToAltFace,
  cloneCards,
  updatePTModifier,
  updateTrespassers,
  turnCardsFaceUp,
  turnCardsFaceDown,
  toggleCardsFaceDown,
  revealCardsToOwner,
  tapOrUntapCards,

  giveControlOfCards,
  requestControlOfCards,
  requestToLookAtCards,
  requestReanimation,
  revealCard,

  updateLifeCounter,
  logLifeCounterChange,

  openLibrary,
  closeRevealedViews,
  shuffleLibrary,

  revealTopNCardsOfLibrary,
  drawCardByIdFromLibraryToDestination,
  drawTopCardFromLibraryToDestination,
  drawNCardsFromLibraryToDestination,
  cascade,
  updateFacedownLibrary,
  revealCardFromLibraryById,
  turnCardFromLibraryFacedownById,
  sendCardsToBottomOfLibrary,
  sendPileToBottomOfLibrary,
  shuffleCardsIntoLibrary,
  shufflePileIntoLibrary,
  sendPileToOtherPile,
  mulligan,
  discardOrExileNCardsAtRandom,
  toggleKeepTopCardRevealed,
  pickCardAtRandom,

  moveCardsById,
  moveCardsBetweenZones,
  moveCardsWithinPlayArea,
  reorderCarousel,
  sortHand,
  linkStoreToServer,

  rollDie,
  sendChatMessage,

  exitGame,
  resetGameForUser,
  saveSideboardChanges,
  swapDeck,
  releaseAllLockedZones,
};