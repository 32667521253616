import React from 'react';
import { Store } from 'pullstate';
import { CarouselStore } from '../../../shared/CarouselStore';
import { UiStateStore } from '../../../shared/UiStateStore';
import BattlefieldApi from '../../../utils/BattlefieldApi';
import { CounterColor } from '../../../../../shared/types';

export const getPlayAreaCounterMenuOptions = (
  instanceData: { counterId: string, label: string },
  carouselStore: Store<CarouselStore>,
  uiStateStore: Store<UiStateStore>,
) => {
  return [
    {
      text: 'Delete',
      extraClass: 'delete',
      action: () => {
        BattlefieldApi.deleteCounter(instanceData.counterId);
      },
    },
    {
      text: 'Change label',
      action: () => {
        const newLabel = prompt('Choose new label:', instanceData.label);
        if (!newLabel) return;
        BattlefieldApi.updateCounter(instanceData.counterId, {
          label: newLabel
        });
      },
    },
    {
      text: 'Set value',
      action: () => {
        const newValue = prompt('New value?', '');
        if (newValue === null) return; // in case they hit escape key
        // since otherwise that would get cast to 0 by the '+' and pass isNaN test
        if (isNaN(+newValue)) {
          alert('Must be a number.');
          return;
        }
        BattlefieldApi.updateCounter(
          instanceData.counterId,
          { value: +newValue },
        );
      },
    },
    {
      text: (
        <div className="colorPickerGridContainer">
          <div className="colorPickerOption red" 
            onClick={() => {BattlefieldApi.updateCounter(instanceData.counterId, {
              color: CounterColor.Red
            })}}
          />
          <div className="colorPickerOption yellow"
            onClick={() => {BattlefieldApi.updateCounter(instanceData.counterId, {
              color: CounterColor.Yellow
            })}}
          />
          <div className="colorPickerOption orange"
            onClick={() => {BattlefieldApi.updateCounter(instanceData.counterId, {
              color: CounterColor.Orange
            })}}
          />
          <div className="colorPickerOption blue"
            onClick={() => {BattlefieldApi.updateCounter(instanceData.counterId, {
              color: CounterColor.Blue
            })}}
          />
          <div className="colorPickerOption blue-dark"
            onClick={() => {BattlefieldApi.updateCounter(instanceData.counterId, {
              color: CounterColor.BlueDark
            })}}
          />
          <div className="colorPickerOption green"
            onClick={() => {BattlefieldApi.updateCounter(instanceData.counterId, {
              color: CounterColor.Green
            })}}
          />
          <div className="colorPickerOption green-dark"
            onClick={() => {BattlefieldApi.updateCounter(instanceData.counterId, {
              color: CounterColor.GreenDark
            })}}
          />
          <div className="colorPickerOption teal"
            onClick={() => {BattlefieldApi.updateCounter(instanceData.counterId, {
              color: CounterColor.Teal
            })}}
          />
          <div className="colorPickerOption gold"
            onClick={() => {BattlefieldApi.updateCounter(instanceData.counterId, {
              color: CounterColor.Gold
            })}}
          />
          <div className="colorPickerOption silver"
            onClick={() => {BattlefieldApi.updateCounter(instanceData.counterId, {
              color: CounterColor.Silver
            })}}
          />
          <div className="colorPickerOption purple"
            onClick={() => {BattlefieldApi.updateCounter(instanceData.counterId, {
              color: CounterColor.Purple
            })}}
          />
          <div className="colorPickerOption pink"
            onClick={() => {BattlefieldApi.updateCounter(instanceData.counterId, {
              color: CounterColor.Pink
            })}}
          />
        </div>
      ),
      disabled: true, // to prevent blue highlight when hovering in menu
      action: () => {
        // N/A
      },
    }
  ];
};