import React from 'react';
import { AppStore } from '../../../../../shared/AppStore';

type DeckManagerEmptyStateProps = {
  filtered: boolean;
};

const DeckManagerEmptyState = ({ filtered }: DeckManagerEmptyStateProps) => {
  const signedIn = AppStore.useState(s => s.signedIn);
  const { error, loading } = AppStore.useState(s => s.userData);
  
  let mainLabel = '';
  if (!signedIn) {
    mainLabel = 'You aren\'t signed in.';
  } else if (loading) {
    mainLabel = 'Loading...';
  } else if (error) {
    mainLabel = error;
  } else if (filtered) {
    mainLabel = 'No decks match the filter criteria.';
  } else {
    mainLabel = "You don't have any decks yet.";
  }

  const showCreateButton = !filtered && !loading && !error && signedIn;
  const showSignInButton = !signedIn;

  return (
    <div className="emptyStateMessage">
      <div className="emptyStateMainLabel">
        {mainLabel}
      </div>

      {showCreateButton && <div className="emptyStateCreateButton" onClick={() => {
        AppStore.update(s => { s.createDeckDialog.isOpen = true; });
      }}>
        Create one!
      </div>}

      {showSignInButton && <div className="emptyStateCreateButton" onClick={() => {
        AppStore.update(s => { s.signInDialog.isOpen = true; });
      }}>
        Sign in
      </div>}
    </div>
  );
};

export default DeckManagerEmptyState;