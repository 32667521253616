import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { Dialog, DialogTitle } from '@material-ui/core';
import { DRAG_ZINDEX } from '../../../../../shared/types';
import { UiStateStoreContext } from '../../../shared/UiStateStore';
import BattlefieldApi from '../../../utils/BattlefieldApi';

import './MainMenuDialog.less';
import { freeInputInteractions } from '../../../utils/InitializationUtils';

const MainMenuDialog = () => {
  const uiStateStore = useContext(UiStateStoreContext);
  const navigate = useNavigate();

  const isOpen = uiStateStore.useState(s => s.mainMenuDialog.isOpen);

  return (
    <Dialog
      open={isOpen}
      onClose={() => uiStateStore.update(s => {
        s.mainMenuDialog.isOpen = false;
      })}
      style={{ zIndex: DRAG_ZINDEX }}
      PaperProps={{
        className: 'mainMenuDialog',
      }}
    >
      <DialogTitle>Main Menu</DialogTitle>
      <div className="mainMenuDialogContent">
        <br/>
        <ul>
          <li onClick={() => {
            BattlefieldApi.releaseAllLockedZones();
            freeInputInteractions();
            navigate('/');
          }}>
            Go Back to Lobby
          </li>
          {/* <li>
            Settings
          </li> */}
          <li onClick={() => {
            if (!confirm('Are you sure you want to permanently exit? Use "Go Back to Lobby" instead if you want to come back.')) {
              return;
            }
            freeInputInteractions();
            BattlefieldApi.exitGame(() => { navigate('/'); });
          }}>
            Exit Game
          </li>
        </ul>
        <br/>
      </div>
    </Dialog>
  );
};

export default MainMenuDialog;