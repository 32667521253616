import React, { useContext } from 'react';
import { BattlefieldLocations, ContextMenuInstanceSources } from '../../../../shared/types';
import BattlefieldApi from '../../utils/BattlefieldApi';
import AddCardDialog from '../Dialogs/AddCardDialog';
import DecklistDialog from '../Dialogs/DecklistDialog';
import RequestConfirmationDialog from '../Dialogs/RequestConfirmationDialog';
import { UiStateStoreContext } from '../../shared/UiStateStore';
import { GameStoreContext } from '../../shared/GameStore';
import PlayerProfile from './components/PlayerProfile';
import Pile from '../Pile';

import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import TimerIcon from '@mui/icons-material/Timer';
import CasinoIcon from '@mui/icons-material/Casino';
import ReplayIcon from '@mui/icons-material/Replay';
import MainMenuDialog from '../Dialogs/MainMenuDialog';
import RollDieDialog from '../Dialogs/RollDieDialog';

import { getAuth } from 'firebase/auth';
import './LeftSidebar.less';
import SideboardDialog from '../Dialogs/SideboardDialog';
import SwapDeckDialog from '../Dialogs/SwapDeckDialog';
import { showContextMenu } from '../ContextMenu';

const LeftSidebar = () => {
  const uiStateStore = useContext(UiStateStoreContext);
  const gameStore = useContext(GameStoreContext);

  const uiState = uiStateStore.useState();
  const publicCardData = gameStore.useState(s => s.publicCardData);

  const mirrorMode = gameStore.getRawState().mirrorMode;
  const userId = getAuth().currentUser!.uid;
  const otherPlayerUserId = mirrorMode ? userId : uiState.currentOtherPlayerId;

  const handSize = publicCardData[otherPlayerUserId].handSize;
  const handSizeLabel = `${handSize} ${handSize === 1 ? 'card' : 'cards'} in hand`;

  return (
    <div className="leftSidebar">
      <div className="opponentProfile">
        <PlayerProfile userId={otherPlayerUserId} showCounterButtons={false} />
      </div>

      <div className="opponentHandSize">
        {handSizeLabel}
      </div>

      <div className="opponentPiles">
        <Pile
          className="opponentExile"
          location={BattlefieldLocations.OtherPlayerExile}
        />
        <Pile
          className="opponentGraveyard"
          location={BattlefieldLocations.OtherPlayerGraveyard}
        />
      </div>

      <div className="middleArea">

        <DecklistDialog/>
        <RequestConfirmationDialog/>
        <AddCardDialog/>
        <MainMenuDialog/>
        <RollDieDialog/>
        <SideboardDialog/>
        <SwapDeckDialog/>

        <ul>
          <li>
            {/* Create Counter */}
            <Tooltip title="Create Counter" enterDelay={500}>
              <IconButton className="utilityButton"
                onClick={() => {
                  BattlefieldApi.createCounter();
                }}
                onContextMenu={(e) => {
                  showContextMenu(e, ContextMenuInstanceSources.CounterButton, uiStateStore);
                }}
              >
                <TimerIcon/>
              </IconButton>
            </Tooltip>
          </li>
          <li>
            {/* Roll Die / Flip Coin */}
            <Tooltip title="Roll Die / Flip Coin" enterDelay={500}>
              <IconButton className="utilityButton" onClick={() => {
                uiStateStore.update(s => { s.rollDieDialog.isOpen = true; });
              }}>
                <CasinoIcon/>
              </IconButton>
            </Tooltip>
          </li>
          <li>
            {/* Add Card/Token */}
            <Tooltip title="Add Card/Token" enterDelay={500}>
              <IconButton className="utilityButton" onClick={() => {
                uiStateStore.update(s => { s.addCardDialog.isOpen = true; });
              }}>
                <NoteAddIcon/>
              </IconButton>
            </Tooltip>
          </li>
          <li>
            {/* Untap All */}
            <Tooltip title="Untap All" enterDelay={500}>
              <IconButton className="utilityButton" onClick={() => {
                BattlefieldApi.untapAll();
              }}>
                <ReplayIcon/>
              </IconButton>
            </Tooltip>
          </li>
        </ul>

      </div>

      <div className="playerPiles">
        <Pile className="playerExile" location={BattlefieldLocations.Exile} />
        <Pile className="playerGraveyard" location={BattlefieldLocations.Graveyard} />
      </div>

      <div className="playerProfile">
        <PlayerProfile userId={userId} showCounterButtons={true} />
      </div>
    </div>
  );
};

export default LeftSidebar;