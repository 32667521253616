import React, { useState } from 'react';
import './ChatInput.less';

import SendIcon from '@mui/icons-material/Send';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import OutlinedInput from '@mui/material/OutlinedInput';
import BattlefieldApi from '../../Battlefield/utils/BattlefieldApi';
import { ENTER_KEY } from '../../../shared/types';
import LobbyApi from '../../Lobby/shared/LobbyApi';

// we use this input for the waitingRoomChat and the battlefieldChat
// so we just need to distinguish between them
type ChatInputProps = {
  isWaitingRoomInput?: boolean;
};

const ChatInput = ({ isWaitingRoomInput }: ChatInputProps) => {
  const [value, setValue] = useState('');

  const sendMessage = () => {
    if (value.trim() === '') {
      return;
    }
    if (isWaitingRoomInput) {
      LobbyApi.sendChatMessage(value);
    } else {
      BattlefieldApi.sendChatMessage(value);
    }
    setValue('');
  };

  return (
    <OutlinedInput
      onKeyDown={(keyDownEvent: any) => {
        if (keyDownEvent.keyCode === ENTER_KEY){
          sendMessage();
        }
      }}
      className="chatInput"
      type="text"
      value={value}
      onChange={(e) => {
        setValue(e.target.value);
      }}
      placeholder={isWaitingRoomInput ? 'Say hello...' : ''}
      endAdornment={
        <InputAdornment position="end">
          <IconButton
            aria-label="send message"
            onClick={sendMessage}
            edge="end"
          >
            <SendIcon/>
          </IconButton>
        </InputAdornment>
      }
    />
  );
};

export default ChatInput;