import { Store } from 'pullstate';
import { AccessRequestLocationOptions, BattlefieldLocations } from '../../../../../shared/types';
import { CarouselStore } from '../../../shared/CarouselStore';
import { UiStateStore } from '../../../shared/UiStateStore';
import BattlefieldApi from '../../../utils/BattlefieldApi';
import { expandPile } from './GenericMenuOptions';
import { closeDrawer } from '../../CarouselDrawer/CarouselDrawer';

export const getLibraryMenuOptions = (
  carouselStore: Store<CarouselStore>,
  uiStateStore: Store<UiStateStore>,
) => {
  const { revealTopCardToSelf, revealTopCardToAll } = uiStateStore.getRawState().contextMenu;
  const lockedZone = uiStateStore.getRawState().lockedZone;
  // For some reason TS throws an error on trying to use .includes() with a 
  // non-matching type, in this case "null" - so just using ! to override 
  // that even though it can be null.
  const libraryIsLocked = [AccessRequestLocationOptions.Library, AccessRequestLocationOptions.TopNOfLibrary].includes(lockedZone!);

  return [
    {
      text: 'Expand faceup',
      action: () => {
        expandPile(BattlefieldLocations.Library, carouselStore, uiStateStore);
      },
      disabled: libraryIsLocked,
    },
    {
      text: 'Expand facedown',
      action: () => {
        // close library in case user already has it open face-up when we pick this action
        BattlefieldApi.closeRevealedViews(true);
        carouselStore.update(s => {
          s.isDrawerOpen = true;
          s.popupCarouselContent = BattlefieldLocations.Library;
          s.isViewingLibraryFacedown = true;
        });
      },
      disabled: libraryIsLocked,
    },
    {
      divider: true,
    },
    {
      text: 'View decklist',
      action: () => {
        uiStateStore.update(s => {
          s.decklistDialog.isOpen = true;
        });
      },
    },
    {
      text: 'View sideboard',
      action: () => {
        uiStateStore.update(s => {
          s.sideboardDialog.isOpen = true;
        });
      },
    },
    {
      divider: true,
    },
    {
      text: 'Swap deck',
      action: () => {
        uiStateStore.update(s => {
          s.swapDeckDialog.isOpen = true;
        });
      },
    },
    {
      text: 'Reset my cards',
      action: () => {
        if (!confirm('Are you sure you want to reset your cards?')) {
          return;
        }
        carouselStore.update(s => { s.isDrawerOpen = false; });
        BattlefieldApi.resetGameForUser();
      },
    },
    {
      divider: true,
    },
    {
      text: 'Draw X cards',
      action: () => {
        const numCards = +prompt('How many?', '1')!;
        if (isNaN(numCards) || numCards < 1) {
          alert('Must be 1 or more.');
          return;
        }
        closeDrawer(carouselStore, true);
        BattlefieldApi.drawNCardsFromLibraryToDestination(
          numCards,
          BattlefieldLocations.Hand,
        );
      },
      // In this case, we lock as long as it's non-null since this affects both
      // hand and library
      disabled: !!lockedZone
    },
    {
      text: 'Look at top X cards',
      action: () => {
        const numCards = +prompt('How many?', '1')!;
        if (isNaN(numCards) || numCards < 1) {
          alert('Must be 1 or more.');
          return;
        }
        BattlefieldApi.revealTopNCardsOfLibrary(
          numCards,
        );
        carouselStore.update(s => {
          s.isDrawerOpen = true;
          s.popupCarouselContent = BattlefieldLocations.TopNOfLibrary;
          s.isViewingLibraryFacedown = false;
        });
      },
      disabled: libraryIsLocked,
    },
    {
      text: 'Send top X cards to graveyard',
      action: () => {
        const numCards = +prompt('How many?', '1')!;
        if (isNaN(numCards) || numCards < 1) {
          alert('Must be 1 or more.');
          return;
        }
        closeDrawer(carouselStore, true);
        BattlefieldApi.drawNCardsFromLibraryToDestination(
          numCards,
          BattlefieldLocations.Graveyard,
        );
      },
      disabled: libraryIsLocked,
    },
    {
      text: 'Send top X cards to exile',
      action: () => {
        const numCards = +prompt('How many?', '1')!;
        if (isNaN(numCards) || numCards < 1) {
          alert('Must be 1 or more.');
          return;
        }
        closeDrawer(carouselStore, true);
        BattlefieldApi.drawNCardsFromLibraryToDestination(
          numCards,
          BattlefieldLocations.Exile,
        );
      },
      disabled: libraryIsLocked,
    },
    {
      text: 'Send top X cards to play facedown',
      action: () => {
        const numCards = +prompt('How many?', '1')!;
        if (isNaN(numCards) || numCards < 1) {
          alert('Must be 1 or more.');
          return;
        }
        closeDrawer(carouselStore, true);
        BattlefieldApi.drawNCardsFromLibraryToDestination(
          numCards,
          BattlefieldLocations.PlayArea,
        );
      },
      disabled: libraryIsLocked,
    },
    {
      text: 'Send top X cards to bottom',
      action: () => {
        const numCards = +prompt('How many?', '1')!;
        if (isNaN(numCards) || numCards < 1) {
          alert('Must be 1 or more.');
          return;
        }
        closeDrawer(carouselStore, true);
        BattlefieldApi.drawNCardsFromLibraryToDestination(
          numCards,
          BattlefieldLocations.Library,
          true,
        );
      },
      disabled: libraryIsLocked,
    },
    {
      text: 'Cascade for X',
      action: () => {
        const cmc = +prompt('What was the CMC of the cascade card?', '1')!;
        if (isNaN(cmc) || cmc < 1) {
          alert('Must be 1 or more.');
          return;
        }
        closeDrawer(carouselStore, true);
        BattlefieldApi.cascade(cmc);
      },
      disabled: libraryIsLocked,
    },
    {
      divider: true,
    },
    {
      text: `Keep top card revealed to me${revealTopCardToSelf ? ' ✓' : ''}`,
      action: () => {
        uiStateStore.update(s => {
          const revealedToSelf = !s.contextMenu.revealTopCardToSelf;
          s.contextMenu.revealTopCardToSelf = revealedToSelf;
          s.contextMenu.revealTopCardToAll = false;
          BattlefieldApi.toggleKeepTopCardRevealed(revealedToSelf, false);
        });
      },
    },
    {
      text: `Keep top card revealed to all players${revealTopCardToAll ? ' ✓' : ''}`,
      action: () => {
        uiStateStore.update(s => {
          const revealedToAll = !s.contextMenu.revealTopCardToAll;
          s.contextMenu.revealTopCardToSelf = false;
          s.contextMenu.revealTopCardToAll = revealedToAll;
          BattlefieldApi.toggleKeepTopCardRevealed(false, revealedToAll);
        });
      },
    },
  ];
};