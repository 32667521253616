// duplicated on backend
export enum KeyboardActions {
  SendToGraveyard = 'SendToGraveyard',
  SendToExile = 'SendToExile',
  SendToHand = 'SendToHand',
  SendToTopOfLibrary = 'SendToTopOfLibrary',
  SendToBottomOfLibrary = 'SendToBottomOfLibrary',
  ShuffleIntoLibrary = 'ShuffleIntoLibrary',
  UntapAll = 'UntapAll',
  Shuffle = 'Shuffle',
  DrawToHand = 'DrawToHand',
  TurnToOtherFace = 'TurnToOtherFace',
  ToggleFaceDown = 'ToggleFaceDown',
  Clone = 'Clone',
  CreateCounter = 'CreateCounter',
  RollDie = 'RollDie',
  AddCardOrToken = 'AddCardOrToken',
  DrawAttention = 'DrawAttention',
  DrawArrowFromHere = 'DrawArrowFromHere',
  TapOrUntap = 'TapOrUntap',
  CycleViewToNextPlayer = 'CycleViewToNextPlayer',
  CycleViewToPreviousPlayer = 'CycleViewToPreviousPlayer',
  IncrementLifeCounter = 'IncrementLifeCounter',
  DecrementLifeCounter = 'DecrementLifeCounter',
  IncrementSecondaryLifeCounter = 'IncrementSecondaryLifeCounter',
  DecrementSecondaryLifeCounter = 'DecrementSecondaryLifeCounter',
  IncrementPTModifier = 'IncrementPTModifier',
  DecrementPTModifier = 'DecrementPTModifier',
  OpenDecklist = 'OpenDecklist',
};

export const KeyboardShortcutLabels = {
  SendToGraveyard: 'Send Selected Cards to Graveyard',
  SendToExile: 'Send Selected Cards to Exile',
  SendToHand: 'Send Selected Cards to Hand',
  SendToTopOfLibrary: 'Send Selected Cards to Top of Library',
  SendToBottomOfLibrary: 'Send Selected Cards to Bottom of Library',
  ShuffleIntoLibrary: 'Shuffle Selected Cards into Library',
  UntapAll: 'Untap All',
  Shuffle: 'Shuffle Library',
  DrawToHand: 'Draw 1 Card to Hand',
  TurnToOtherFace: 'Turn Selected Cards to Other Face',
  ToggleFaceDown: 'Toggle Selected Cards Face Down',
  Clone: 'Clone Selected Cards',
  CreateCounter: 'Create Counter',
  RollDie: 'Roll Die',
  AddCardOrToken: 'Add Card or Token',
  DrawAttention: 'Draw Attention to Selected Cards',
  DrawArrowFromHere: 'Draw Arrow from Here',
  TapOrUntap: 'Tap or Untap Selected Cards',
  CycleViewToNextPlayer: 'Cycle View to Next Player',
  CycleViewToPreviousPlayer: 'Cycle View To Previous Player',
  IncrementLifeCounter: 'Increment Life Counter',
  DecrementLifeCounter: 'Decrement Life Counter',
  IncrementSecondaryLifeCounter: 'Increment Secondary Life Counter',
  DecrementSecondaryLifeCounter: 'Decrement Secondary Life Counter',
  IncrementPTModifier: 'Increment Power/Toughness Modifier for Selected Cards',
  DecrementPTModifier: 'Decrement Power/Toughness Modifier for Selected Cards',
  OpenDecklist: 'Open Decklist',
};

// When user inputs "shift + 1" (if they set that as one of their shortcuts),
// it records as "shift + !"...this is to fix that.
// We don't include letters because they don't matter in this context.
export const ShiftedKeys: Record<string, string> = {
  '!': '1',
  '@': '2',
  '#': '3',
  '$': '4',
  '%': '5',
  '^': '6',
  '&': '7',
  '*': '8',
  '(': '9',
  ')': '0',
  '_': '-',
  '+': '=',
  '{': '[',
  '}': ']',
  '|': '\\',
  ':': ';',
  '"': '\'',
  '<': ',',
  '>': '.',
  '?': '/',
};

// this is temporary for backfilling accounts that existed before settings were implemented
export const DefaultKeyboardShortcuts = {
  SendToGraveyard: ['q'],
  SendToExile: ['w'],
  SendToHand: ['e'],
  SendToTopOfLibrary: ['r'],
  SendToBottomOfLibrary: ['t'],
  ShuffleIntoLibrary: ['y'],
  UntapAll: ['a'],
  Shuffle: ['s'],
  DrawToHand: ['d'],
  TurnToOtherFace: ['f'],
  ToggleFaceDown: ['g'],
  Clone: ['h'],
  CreateCounter: ['z'],
  RollDie: ['x'],
  AddCardOrToken: ['c'],
  DrawAttention: ['v'],
  DrawArrowFromHere: ['b'],
  TapOrUntap: ['space'],
  CycleViewToNextPlayer: ['tab'],
  CycleViewToPreviousPlayer: ['shift', 'tab'],
  IncrementLifeCounter: ['='],
  DecrementLifeCounter: ['-'],
  IncrementSecondaryLifeCounter: [']'],
  DecrementSecondaryLifeCounter: ['['],
  IncrementPTModifier: ['.'],
  DecrementPTModifier: [','],
  OpenDecklist: ['l'],
};
