import { GameFormat, WaitingRoom } from '../../../../shared/types';

export const filterRooms = (
  rooms: WaitingRoom[],
  currentRoom: WaitingRoom | null,
  formatFilter: GameFormat,
  titleFilter: string,
) => {
  const lowercasedTitleFilter = titleFilter.toLowerCase();
  let rearrangedRooms = rooms.filter(_room => _room.title.toLowerCase().includes(lowercasedTitleFilter));

  if (formatFilter !== GameFormat.Any) {
    rearrangedRooms = rearrangedRooms.filter(
      _room => _room.gameFormat === formatFilter
      || _room.gameFormat === GameFormat.Any);
  }

  // if we're in a room, move it to be the first item in the list and it supercedes filters
  if (currentRoom) {
    rearrangedRooms = [currentRoom].concat(rearrangedRooms.filter(_room => _room.id !== currentRoom.id));
  }

  return rearrangedRooms;
};