import React from 'react';
import AccountLinks from './components/AccountLinks';
import MainLogo from './logo.png';
import './TopBar.less';

const TopBar = () => {
  return (
    <div className="topBar">
      <img  className="mainLogo" src={MainLogo} alt="CardTavern: An Unofficial Magic: The Gathering Playtester"/>
      <div className="accountControls">
        <AccountLinks />
      </div>
    </div>
  );
};

export default TopBar;