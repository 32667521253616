import { DecklistItem } from '../../../../../../shared/types';
import { fetchCardsForParsedList, validateAndParseInput } from '../CreateDeckDialog/CreateDeckUtils';

const removeBlankLines = (inputText: string) => {
  const lines = inputText.trim().split('\n');
  const nonBlankLines = lines.filter(line => line.trim() !== '');
  return nonBlankLines.join('\n');
};

export const didUserChangeDecklist = (
  originalRawDecklist: string,
  rawDecklist: string,
) => {
  const modifiedInputWithoutBlankLines = removeBlankLines(originalRawDecklist);
  const originalInputWithoutBlankLines = removeBlankLines(rawDecklist);

  return originalInputWithoutBlankLines !== modifiedInputWithoutBlankLines;
};

export const getRawDecklistFromDecklist = (decklist: DecklistItem[]) => {
  let rawDecklist = '';

  decklist.forEach(decklistItem => {
    rawDecklist += `${decklistItem.quantity} ${decklistItem.name}\n`;
  });

  return rawDecklist;
};

export const getDataForEditedDeck = async (
  decklist: string,
  sideboard: string,
  setError: (error: string | null) => void,
  setDecklist: (decklist: string) => void,
  setSideboard: (decklist: string) => void,
) => {
  setError(null);

  if (decklist === '') {
    setError('Decklist cannot be empty.');
    return;
  };

  const parsedDecklist = validateAndParseInput(decklist, setError);
  const parsedSideboard = validateAndParseInput(sideboard, setError);

  if (!parsedDecklist) return;

  const consolidatedDecklist = await fetchCardsForParsedList(
    parsedDecklist,
    setError,
    setDecklist,
  );

  let consolidatedSideboard;
  if (parsedSideboard) {
    consolidatedSideboard = await fetchCardsForParsedList(
      parsedSideboard,
      setError,
      setSideboard,
    );
  }

  if (decklist && !consolidatedDecklist) return;
  if (sideboard && !consolidatedSideboard) return;

  return {
    decklist: consolidatedDecklist,
    sideboard: consolidatedSideboard,
  };
};