import React, { useRef, useEffect, useContext } from 'react';
import PubSub from 'pubsub-js';
import { BattlefieldLocations, DropEventData, Topics } from '../../../../shared/types';
import { wasEventInsideRect } from '../../../../shared/utils';
import { dropIsValid, handleSuccessfulDrop } from './DropzoneUtils';
import { CarouselStoreContext } from '../CarouselStore';
import './Dropzone.less';

let dropzoneCount = 0;
let dropzonesAccountedFor = 0;

// Reset count to zero whenever a new drop event occurs
PubSub.subscribe(Topics.CardsDropped, () => {
  dropzonesAccountedFor = 0;
});

// If all existing dropZones fail to receive a given drop event,
// publish an InvalidDropLocation event so the dropped cards
// can be put back in their original location.
PubSub.subscribe(Topics.CardsDroppedElsewhere, () => {
  dropzonesAccountedFor++;
  if (dropzonesAccountedFor === dropzoneCount) {
    PubSub.publish(Topics.InvalidDropLocation);
    dropzonesAccountedFor = 0;
  }
});

type DropzoneProps = {
  location: BattlefieldLocations;
  children: React.ReactNode;
  disabled?: boolean;
  onCardDrop: (dropData: DropEventData) => void;
};

const Dropzone = React.memo(({
  children,
  location,
  onCardDrop,
  disabled,
}: DropzoneProps) => {
  const dropzoneRef = useRef<any>(null);
  const carouselStore = useContext(CarouselStoreContext);

  // keeps track of how many dropzone component instances exist
  useEffect(() => {
    dropzoneCount += 1;
    return () => {
      dropzoneCount -= 1;
    };
  });

  // Whenever a card is dropped ANYWHERE in the UI, each dropzone checks if
  // the coordinates are contained within itself.
  // If yes, it fires off a DroppedCardsReceived event and calls the function
  // it was given for its onCardDrop prop.
  // If no, it fires a CardsDroppedElsewhere event so that the UI is 
  // informed if something was dropped outside of any dropzone.
  useEffect(() => {
    const subscriptionToken = PubSub.subscribe(
      Topics.CardsDropped,
      async (topic: string, dropData: DropEventData) => {
        if (!disabled
            && wasEventInsideRect(dropData.mouseUpEvent, dropzoneRef.current)
            && dropIsValid(dropData, location, carouselStore)
          ) {
          handleSuccessfulDrop(location, onCardDrop, dropData, carouselStore);
        } else {
          PubSub.publish(Topics.CardsDroppedElsewhere, location);
        }
      }
    );
    return () => {
      PubSub.unsubscribe(subscriptionToken);
    };
  });

  return (
    <div ref={dropzoneRef} className="dropzone">
      {children}
    </div>
  );
});

export default Dropzone;