import React from 'react';
import { ConnectionStatus, WaitingRoom } from '../../../../shared/types';
import LoginIcon from '@mui/icons-material/Login';
import Chip from '@mui/material/Chip';
import LobbyApi from '../../shared/LobbyApi';
import { IconButton, Tooltip } from '@mui/material';
// this icon is indistinguishable from the Lock icon, but the Lock icon
// causes the same import error as the ImportExport icon when deployed to production
import HttpsIcon from '@mui/icons-material/Https';
import { AppStore } from '../../../../shared/AppStore';
import './WaitingRoomListItem.less';

type WaitingRoomListItemProps = {
  // A TRULY bizarre bug made it so that appStore.useState didn't work
  // in this component, only for this value. It wouldn't get updates on currentRoom.
  // Circumvented by passing in as prop instead.
  currentRoom: WaitingRoom | null;
  room: WaitingRoom;
};

const WaitingRoomListItem = ({ currentRoom, room }: WaitingRoomListItemProps) => {
  const signedIn = AppStore.useState(s => s.signedIn);
  // const currentRoom = AppStore.useState(s => s.currentRoom);

  const joinRoom = () => {
    if (!signedIn) {
      alert('Please sign in or create an account in order to join games.');
      return;
    }

    if (currentRoom?.gameLaunched) {
      alert('You must exit the game you are already in before attemping to join another.');
      return;
    }
    
    if (currentRoom) {
      const proceed = confirm('You are already in a game that hasn\'t started yet. Do you want to leave that game and join this one?');
      if (proceed) {
        let password = '';
        if (room.hasPassword) {
          password = prompt('Password?') + '';
        }
        LobbyApi.joinWaitingRoom(room.id, password);
      }
      return;
    }

    let password = '';
    if (room.hasPassword) {
      password = prompt('Password?') + '';
    }
    LobbyApi.joinWaitingRoom(room.id, password);
  };

  let status = null;
  if (currentRoom?.id === room.id) {
    if (room.gameLaunched) {
      status = 'Joined · In Progress';
    } else {
      status = 'Joined';
    }
  } else {
    if (room.gameLaunched) {
      status = 'In Progress';
    }
  }

  // if we are not in any of the above states, it is joinable
  const showJoinButton = !status;

  // when hovered, show player names except those who have exited
  const connectedPlayerIds = Object.keys(room.playerStatuses)
    .filter(key => room.playerStatuses[key] !== ConnectionStatus.Exited);
  const playersLabel = connectedPlayerIds.map(id => room.usernamesByUserId[id])
    .join(', ');

  return (
    <div className="waitingRoomListItem" key={room.id}>
      <div className="roomTitleContainer">
        <div className="roomTitle">
          {room.title}
        </div>
      </div>

      <div className={`playerCountContainer${room.hasPassword ? ' passworded' : ''}`}>
        {room.hasPassword &&
          <HttpsIcon/>
        }

        <Tooltip title={playersLabel} enterDelay={200}>
          <span>{`${Object.values(room.userIds).length}/${room.maxPlayers}`}</span>
        </Tooltip>
      </div>

      <div className="tagsContainer">
        <Chip label={room.gameFormat} style={{ backgroundColor: 'rgb(0 0 0 / 29%)' }} />
        <Chip label={room.experienceLevel} style={{ backgroundColor: 'rgb(0 0 0 / 29%)' }} />
      </div>

      <div className="statusContainer">
        {status}

        {showJoinButton &&
          <IconButton onClick={joinRoom}>
             <LoginIcon/>
          </IconButton>
        }
      </div>
    </div>
  );
};

export default WaitingRoomListItem;