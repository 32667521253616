import { Avatar } from '@material-ui/core';
import React, { useContext } from 'react';
import { GRAVATAR_BASE_URL, LifeCounterId } from '../../../../../../shared/types';
import { GameStoreContext } from '../../../../shared/GameStore';
import LifeCounter from '../LifeCounter';
import './PlayerProfile.less';

type PlayerProfileProps = {
  userId: string;
  showCounterButtons: boolean;
};

const PlayerProfile = ({ userId, showCounterButtons }: PlayerProfileProps) => {
  const gameStore = useContext(GameStoreContext);

  const gravatarHashes = gameStore.useState(s => s.waitingRoom!.gravatarHashes);
  const usernames = gameStore.useState(s => s.waitingRoom!.usernamesByUserId);
  const username = usernames[userId];

  const lifeTotals = gameStore.useState(s => s.lifeTotals);
  const primaryLifeTotal = lifeTotals[userId]?.primary || 0;
  const secondaryLifeTotal = lifeTotals[userId]?.secondary || 0;

  const md5Email = gravatarHashes[userId];
  const gravatarUrl = GRAVATAR_BASE_URL.replace('%s', md5Email);

  return (
    <div className="profileContainer">
      <div className="playerAvatar">
        <Avatar src={gravatarUrl} style={{ height: '100%', width: '100%' }}/>
      </div>

      <div className="playerName">
        <span className="playerNameText">{username}</span>
      </div>

      <LifeCounter value={primaryLifeTotal} allowUpdate={showCounterButtons} 
        counterId={LifeCounterId.PrimaryCounter} />

      <LifeCounter value={secondaryLifeTotal} allowUpdate={showCounterButtons}
        counterId={LifeCounterId.SecondaryCounter} />
    </div>
  );
};

export default PlayerProfile;