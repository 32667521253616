import React, { useEffect } from 'react';
import { getAuth } from 'firebase/auth';
import Linkify from 'linkify-react';
import { WaitingRoom } from '../../../../shared/types';
import ChatInput from '../../../shared/ChatInput';
import './WaitingRoomChat.less';

type WaitingRoomChatProps = {
  room: WaitingRoom;
};

const WaitingRoomChat = ({ room }: WaitingRoomChatProps) => {
  const userId = getAuth().currentUser?.uid;
  const chat = room.chat;

  useEffect(() => {
    const chatLog = document.querySelector('.chatLog');
    chatLog!.scrollTop = chatLog!.scrollHeight;
  }, [chat]);

  return (
    <div className="waitingRoomChatContainer">
      <div className="waitingRoomChatInnerContainer">
        <div className="chatLog">
          {chat.map(message => (
            <div key={message.id} className="waitingRoomChatMessage">
              <span className="chatMessageUserId">
                {room.usernamesByUserId[message.userId] + ':'}
              </span>
              <span className="chatMessageContent">
                <Linkify options={{ target: '_blank' }}>
                  {message.message}
                </Linkify>
              </span>
            </div>
          ))}
        </div>
        <ChatInput isWaitingRoomInput={true}/>
      </div>
    </div>
  );
};

export default WaitingRoomChat;