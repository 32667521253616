import React, { useContext, useEffect, useRef, useState } from 'react';
import { getAuth } from 'firebase/auth';
import {
  BattlefieldLocations,
  CardInPlay,
  Counter,
  DropEventData,
  LEFT_MOUSE,
  MousePosition,
  Topics,
} from '../../../../shared/types';
import {
  blurInputs,
  clearDragListener,
  didMouseLeaveWindow,
  doElementsOverlap,
  getLeftPositionAsPercent,
  getNextZIndex,
  getPlayAreaOffsetsForDropEvent,
  getVerticalPositionAsPercent,
  setGlobalOnMouseMove,
  wasEventInsideRect
} from '../../../../shared/utils';
import Dropzone from '../../shared/Dropzone';
import PlayAreaCard from './components/PlayAreaCard';
import SelectBox from './components/SelectBox';
import SelectedPlayAreaCard from './components/SelectedPlayAreaCard';
import OtherPlayerCard from './components/OtherPlayerCard';
import PlayAreaCounter from '../PlayAreaCounter';
import MagnifiedCard from './components/MagnifiedCard';
import { UiStateStoreContext } from '../../shared/UiStateStore';
import { cancelMagnification, cardComparator, checkToConfirmTrespassingOnDrop, nullifyUiStoreSelectionState, removeDuplicates, setMagnifiedCardDetails } from './PlayAreaUtils';
import TrespassingOverlay from './components/TrespassingOverlay';

import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import PlayerSelect from './components/PlayerSelect';

import './PlayArea.less';

type PlayAreaProps = {
  incomingMyCardsInPlay: CardInPlay[];
  otherCardsInPlay: CardInPlay[];
  incomingCounters: Counter[];
  trespassersOnUs: CardInPlay[];
  trespassersOnOpponent: CardInPlay[];
};

const PlayArea = React.memo(({
  incomingMyCardsInPlay,
  otherCardsInPlay,
  incomingCounters,
  trespassersOnUs,
  trespassersOnOpponent,
}: PlayAreaProps) => {
  const uiStateStore = useContext(UiStateStoreContext);

  const dragSelectedCards = uiStateStore.useState(s => s.dragSelectedCards);

  // the playAreaRef has to be different from other refs because the OtherPlayerCards
  // need this ref in order to position themselves, and it wouldn't be set for them at time
  // of rendering otherwise, whereas the rest are used asynchronously in callbacks.
  const [playAreaRef, setPlayAreaRef] = useState(null)
  const selectBoxRef = useRef<any>(null);
  const myPlayAreaRef = useRef<any>(null);
  const theirPlayAreaRef = useRef<any>(null);

  const userId = getAuth().currentUser!.uid;
  const otherPlayerId = uiStateStore.useState(s => s.currentOtherPlayerId);
  const myCounters = incomingCounters.filter(counter => counter.ownerId === userId);
  const otherCounters = incomingCounters.filter(counter => counter.ownerId !== userId);

  const [myCardsInPlay, setMyCardsInPlay] = useState<CardInPlay[]>(incomingMyCardsInPlay);
  const [staticCorner, setStaticCorner] = useState<MousePosition | null>(null);
  const [movingCorner, setMovingCorner] = useState<MousePosition | null>(null);

  // While we want to be able to update cards and counters immediately locally,
  // we also sync to match updates from the server. Hence props + useState + useEffect.
  useEffect(() => {
    setMyCardsInPlay(cards => {
      const cardsById: { [cardId: string]: CardInPlay } = {};
      cards.forEach(card => { cardsById[card.id] = card; });

      return incomingMyCardsInPlay.map(incomingCard => {
        const originalCard = cardsById[incomingCard.id];

        if (!originalCard) return incomingCard;

        // if we're dragging, we don't want to overwrite certain properties with stale
        // values from the server.
        return {
          ...incomingCard,
          ref: originalCard.ref,
          isDragging: originalCard.isDragging,
          isHighlighted: originalCard.isDragging ? null : incomingCard.isHighlighted,
          left: originalCard.isDragging ? originalCard.left : incomingCard.left,
          top: originalCard.isDragging ? originalCard.top : incomingCard.top,
          zIndex: originalCard.zIndex, // don't want to override DRAG_ZINDEX locally
        };
      });
    });
  }, [incomingMyCardsInPlay]);

  // When we use a keyboard shortcut to send a card out of play, we want to set the new
  // keyboard shortcut hover target to be the next card underneath that one immediately.
  useEffect(() => {
    const cursorPosition = uiStateStore.getRawState().keyboardShortcutTarget.playAreaCursorPosition;
    if (!cursorPosition) return;

    const cardsUnderCursor: CardInPlay[] = [];
    myCardsInPlay.filter(card =>
      !card.isTrespassingOnUserId || card.isTrespassingOnUserId === otherPlayerId
    ).forEach(card => {
      if (card.ref?.current) {
        const cursorOverlapsCard = wasEventInsideRect(
          { clientX: cursorPosition[0], clientY: cursorPosition[1] },
          card.ref.current,
        );
        if (cursorOverlapsCard) cardsUnderCursor.push(card);
      }
    });

    if (cardsUnderCursor.length === 0) {
      cancelMagnification(uiStateStore);
      return;
    }

    let highest = cardsUnderCursor[0];
    cardsUnderCursor.forEach(card => {
      if (card.zIndex > highest.zIndex) highest = card;
    });

    setMagnifiedCardDetails(highest, highest.ref, uiStateStore);
    // If we used a "Send to..." shortcut on some selected cards,
    // setMagDetails will set the new hoveredCardId but it would be trumped
    // by the pre-existing selectedCardIds when picking the new target. getTargetCardIds in
    // initializationUtils would return the selectedCardIds instead of the new
    // hoverId when checked by resetIfApplicable, and thus it would reset everything
    // because it would see no change because it wouldn't see the new hoveredCardId.
    // Consequently, we make a point to set that to null here.
    uiStateStore.update(s => {
      s.keyboardShortcutTarget.selectedCardIds = null;
    });
  }, [myCardsInPlay.length]);

  // When a new card is created via AddCardDialog, it enters play selected but is not
  // yet the keyboard target.
  // Update: this is obsolete right now since cards created via AddCardDialog do NOT
  // enter selected since we refactored to move drag select to be in frontend state only.
  useEffect(() => {
    const selectNewCards = () => {
      const selectedCardIds = incomingMyCardsInPlay
        .filter(card => dragSelectedCards[card.id])
        .map(card => card.id);
      uiStateStore.update(s => {
        s.keyboardShortcutTarget.selectedCardIds = selectedCardIds;
      });
    };

    const subToken = PubSub.subscribe(Topics.CardsCreatedInPlay, selectNewCards);

    return () => {
      PubSub.unsubscribe(subToken);
    };
  }, [incomingMyCardsInPlay, dragSelectedCards]);

  const onCardDrop = (dropData: DropEventData) => {
    const [leftOffset, topOffset] = getPlayAreaOffsetsForDropEvent(
      dropData.mouseUpEvent,
      playAreaRef,
    );

    uiStateStore.update(s => {
      // can only drop one card into play so we only worry about array[0].
      // (dragging multiple cards around within playArea does not trigger onCardDrop)
      s.keyboardShortcutTarget.hoveredCardId = dropData.cards[0].id;
      // unselect all cards when dropping new cards in
      s.dragSelectedCards = {};
    });

    // can only drop one card into play at a time, so this will always be a single card
    const isTrespassing = checkToConfirmTrespassingOnDrop(dropData);

    // The removeDuplicates handles a class of poorly understood bugs that result
    // in a duplicate card being created
    const newMyCardsInPlay = removeDuplicates([
      ...myCardsInPlay,
      ...dropData.cards.map(card => ({
        ...card,
        isFacedown: dropData.mouseUpEvent.shiftKey,
        isRevealedToOwner: false,
        isToken: false,
        left: getLeftPositionAsPercent(dropData.left + leftOffset),
        top: getVerticalPositionAsPercent(dropData.top + topOffset),
        zIndex: getNextZIndex(myCardsInPlay, incomingCounters),
        ref: { current: null },
        originalLeft: getLeftPositionAsPercent(dropData.left + leftOffset),
        originalTop: getVerticalPositionAsPercent(dropData.top + topOffset),
        tapped: false,
        isHighlighted: null,
        isDragging: false,
        showPTModifier: false,
        powerModifier: 0,
        toughnessModifier: 0,
        isTrespassingOnUserId: isTrespassing ?
          uiStateStore.getRawState().currentOtherPlayerId : null,
      })),
    ]);

    setMyCardsInPlay(newMyCardsInPlay);
    return newMyCardsInPlay;
  };

  // handle drag-selecting in playArea
  const onMouseDown = (mouseDownEvent: any) => {
    if (mouseDownEvent.button !== LEFT_MOUSE) return;
    blurInputs();
  
    if (mouseDownEvent.target === myPlayAreaRef.current ||
      mouseDownEvent.target === theirPlayAreaRef.current) {

      // unselect all other cards
      nullifyUiStoreSelectionState(uiStateStore);

      uiStateStore.update(s => { s.isDragSelecting = true; });

      // The mouseDownEvent.clientX/Y are relative to window, but selectBox
      // is inside PlayArea which is position:relative, so we need to add
      // offsets to account for that.
      const playArea = document.querySelector('.playArea')!
        .getBoundingClientRect();
      const leftPosition = mouseDownEvent.clientX - playArea.left;
      const topPosition = mouseDownEvent.clientY - playArea.top;

      setStaticCorner({ x: leftPosition, y: topPosition });
      setMovingCorner({ x: leftPosition, y: topPosition });

      setGlobalOnMouseMove(mouseDownEvent, newMousePosition => {
        setMovingCorner({
          x: newMousePosition.x - playArea.left,
          y: newMousePosition.y - playArea.top,
        })
      }, true);

      const onMouseUp = (mouseUpEvent: any) => {
        clearDragListener();

        const selectedCardIds: string[] = [];

        myCardsInPlay.filter(card =>
          !card.isTrespassingOnUserId || card.isTrespassingOnUserId === otherPlayerId
        ).forEach(card => {
          if (doElementsOverlap(card.ref.current, selectBoxRef.current)) {
            selectedCardIds.push(card.id);
          }
        });

        if (selectedCardIds.length > 0) {
          uiStateStore.update(s => {
            s.keyboardShortcutTarget.selectedCardIds = selectedCardIds;
            selectedCardIds.forEach(cardId => {
              s.dragSelectedCards[cardId] = true;
            });
          });
        } else {
          uiStateStore.update(s => {
            s.keyboardShortcutTarget.selectedCardIds = [];
            s.dragSelectedCards = {};
          });
        }

        setStaticCorner(null);
        setMovingCorner(null);
        uiStateStore.update(s => { s.isDragSelecting = false; });
        document.removeEventListener('mouseup', onMouseUp);
        document.body.removeEventListener('mouseout', onWindowLeave);
      };

      document.addEventListener('mouseup', onMouseUp);

      const onWindowLeave = (leaveEvent: any) => {
        if (didMouseLeaveWindow(leaveEvent)) {
          // reset everything involving drag state if we move the mouse out of the window
          clearDragListener();
          setStaticCorner(null);
          setMovingCorner(null);
          uiStateStore.update(s => { s.isDragSelecting = false; });
          document.removeEventListener('mouseup', onMouseUp);
          document.body.removeEventListener('mouseout', onWindowLeave);
        }
      };
      document.body.addEventListener('mouseout', onWindowLeave);
    }
  };

  const onRightClick = (clickEvent: any) => {
    clickEvent.preventDefault();
  };

  return (
    <div
      ref={(domNode: any) => { setPlayAreaRef(domNode); }}
      className="playArea"
      onContextMenu={onRightClick}
      onMouseDown={onMouseDown}
    >
      <Dropzone
        location={BattlefieldLocations.PlayArea}
        onCardDrop={onCardDrop}
      >
        <Tooltip title="Open Menu" enterDelay={500}>
          <IconButton className="utilityButton" onClick={() => {
            uiStateStore.update(s => { s.mainMenuDialog.isOpen = true; });
          }}>
            <MenuIcon/>
          </IconButton>
        </Tooltip>

        <PlayerSelect/>

        <div className="theirPlayArea" ref={theirPlayAreaRef}>
          {/* We filter out opponent's trespassing cards that are on a player other than us. */}
          {otherCardsInPlay.filter(card =>
            !card.isTrespassingOnUserId || card.isTrespassingOnUserId === userId
          ).slice().sort(cardComparator).map((card: CardInPlay) =>
            <OtherPlayerCard key={card.id} card={card} playAreaRef={playAreaRef} />
          )}
          {otherCounters.filter(counter => counter.ownerId === otherPlayerId).map(counter => (
            <PlayAreaCounter key={counter.id} counterData={counter}/>
          ))}
          {trespassersOnOpponent.slice().sort(cardComparator).map((card: CardInPlay) =>
            <OtherPlayerCard key={card.id} card={card} playAreaRef={playAreaRef} noMirror={true} />
          )}
          <TrespassingOverlay/>
        </div>

        <div className="myPlayArea" ref={myPlayAreaRef}>
          <MagnifiedCard playAreaRef={playAreaRef} />

          {/* We filter out our trespassing cards that are on a player other than this opponent. */}
          {myCardsInPlay.filter(card =>
            !card.isTrespassingOnUserId || card.isTrespassingOnUserId === otherPlayerId
          ).map((card: CardInPlay) => (
            dragSelectedCards[card.id] ?
            <SelectedPlayAreaCard
              key={card.id}
              card={card}
              ref={card.ref}
              playAreaRef={playAreaRef}
              setCardsInPlay={setMyCardsInPlay}
              myCardsInPlay={myCardsInPlay}
            /> :
            <PlayAreaCard
              key={card.id}
              card={card}
              ref={card.ref}
              playAreaRef={playAreaRef}
              setCardsInPlay={setMyCardsInPlay}
            />
          ))}
          {trespassersOnUs.slice().sort(cardComparator).map((card: CardInPlay) =>
            <OtherPlayerCard key={card.id} card={card} playAreaRef={playAreaRef} />
          )}
          {myCounters.map(counter => (
            <PlayAreaCounter key={counter.id} counterData={counter}/>
          ))}
          {staticCorner && movingCorner &&
            <SelectBox
              staticCorner={staticCorner}
              movingCorner={movingCorner}
              ref={selectBoxRef}
            />
          }
        </div>
      </Dropzone>
    </div>
  );
});

export default PlayArea;
