import React from 'react';
import { Store } from 'pullstate';
import { AccessRequestLocationOptions, CardData, ContextMenuSource, DecklistItem, MousePosition } from '../../../shared/types';

export const UiStateStoreContext = React.createContext<Store<UiStateStore>>({} as Store<UiStateStore>);

export type UiStateStore = {
  initComplete: boolean;
  currentOtherPlayerId: string;
  lockedZone: AccessRequestLocationOptions | null;
  isDragSelecting: boolean;
  isTrespassing: boolean;
  contextMenu: {
    isOpen: boolean;
    showSecondaryMenu: boolean;
    source: ContextMenuSource | null;
    position: MousePosition | null;
    instanceData?: any;
    revealTopCardToSelf: boolean;
    revealTopCardToAll: boolean;
  };
  magnifiedCard: {
    shouldShow: boolean;
    onLeft: boolean;
    timeout: NodeJS.Timeout | null;
    cardData: CardData | null;
    position: MousePosition | null;
  };
  addCardDialog: {
    isOpen: boolean;
    recentlySelectedCards: Partial<CardData>[];
  };
  decklistDialog: {
    isOpen: boolean;
  };
  requestConfirmationDialog: {
    isOpen: boolean;
    message: string | null;
    requestId: string | null;
  };
  mainMenuDialog: {
    isOpen: boolean;
  };
  rollDieDialog: {
    isOpen: boolean;
  };
  sideboardDialog: {
    isOpen: boolean;
    workingSideboard: DecklistItem[],
    workingDecklist: DecklistItem[],
  };
  swapDeckDialog: {
    isOpen: boolean;
  };
  // Used for flipping cards outside of the playArea. This isn't saved to backend.
  // So refreshing clears it. This flipping is for *us only*, in piles/carousels.
  // For efficiency, we just add cardId: true here rather than have an array of ids.
  flippedCardIds: {
    [cardId: string]: boolean;
  };
  dragSelectedCards: {
    [cardId: string]: boolean;
  };
  // Descending order of keyboard shortcut priority - what we have menu open on,
  // what we are hovering, what we have drag-selected. Each value is set and nulled
  // separately from the others as user inputs warrant.
  // contextMenuTarget gets set on menu open if and only if it's on a card in play
  // or a card in a carousel.
  // It is not set when opened for pile menus, since then the menu isn't for one card
  // and we don't have keyboard shortcuts for pile options.
  // playAreaCursorPosition is used for setting the hoveredCardId to the next card
  // underneath a card you just moved out of play. E.g. you hover a card and hit
  // "Send to graveyard", now your cursor is over another card. This is how we know.
  keyboardShortcutTarget: {
    playAreaCursorPosition: number[] | null;
    contextMenuTargetCardIds: string[] | null;
    selectedCardIds: string[] | null;
    hoveredCardId: string | null;
  };
};

export const createUiStateStore = () => {
  const uiStateStore = new Store<UiStateStore>({
    initComplete: false,
    currentOtherPlayerId: '',
    lockedZone: null,
    isDragSelecting: false,
    isTrespassing: false,
    contextMenu: {
      isOpen: false,
      showSecondaryMenu: false,
      source: null,
      position: null,
      instanceData: null,
      revealTopCardToSelf: false,
      revealTopCardToAll: false,
    },
    magnifiedCard: {
      shouldShow: false,
      onLeft: false,
      timeout: null,
      cardData: null,
      position: null,
    },
    addCardDialog: {
      isOpen: false,
      recentlySelectedCards: [],
    },
    decklistDialog: {
      isOpen: false,
    },
    requestConfirmationDialog: {
      isOpen: false,
      message: null,
      requestId: null,
    },
    mainMenuDialog: {
      isOpen: false,
    },
    rollDieDialog: {
      isOpen: false,
    },
    sideboardDialog: {
      isOpen: false,
      workingSideboard: [],
      workingDecklist: [],
    },
    swapDeckDialog: {
      isOpen: false,
    },
    flippedCardIds: {},
    dragSelectedCards: {},
    keyboardShortcutTarget: {
      playAreaCursorPosition: null,
      contextMenuTargetCardIds: null,
      selectedCardIds: null,
      hoveredCardId: null,
    },
  });

  return uiStateStore;
};