import React from 'react';
import { Deck } from '../../../../../shared/types';
import { AppStore } from '../../../../../shared/AppStore';
import './DeckItem.less';

type DeckItemProps = {
  deck: Deck;
};

const DeckItem = ({ deck }: DeckItemProps) => {
  const deckCoverStyles = {
    backgroundImage: `url("${deck.decklist[0].url}")`
  };

  return (
    <div className="deckItemContainer" onClick={() => {
      AppStore.update(s => {
        s.editDeckDialog.deck = deck;
        s.editDeckDialog.isOpen = true;
      });
    }}>
      <div className="deckCoverImage" style={deckCoverStyles}/>
      <div className="deckNameBanner">{deck.name}</div>
    </div>
  );
};

export default DeckItem;