import axios from 'axios';
import { throttle } from 'lodash';
import { BattlefieldLocations } from '../../../../../shared/types';
import BattlefieldApi from '../../../utils/BattlefieldApi';

export const fetchSuggestions = async (
  name: string,
  setSuggestions: any,
) => {
  if (name.length < 3) return;

  return _fetchSuggestions(name, setSuggestions);
};

const _fetchSuggestions = throttle(async (
  name: string,
  setSuggestions: any,
) => {
  // two un-cards have lengths 1 and 2, "_" and "ow", but fuck those cards
  if (name.length < 3) return;

  // this is a workaround for the /search endpoint being punctuation-sensitive
  const andedName = encodeURIComponent(
    `"${name.split(' ').join('"and"')}"`
  );

  try {
    const url = `https://api.scryfall.com/cards/search?order=cmc&q=${andedName}&include_extras=true`;
    const searchResponse = await axios.get(url);

    const results = searchResponse.data.data.map((cardData: any) => 
      structureCardData(cardData)
    );

    setSuggestions(results);
  } catch (error) {
    // console.log(error);
  }
}, 300);

const getEmojiStringForColors = (colors: string[]) => {
  return colors.map((color: string) => {
    switch (color) {
      case 'B':
        return '⬛';
      case 'W':
        return '⬜';
      case 'R':
        return '🟥';
      case 'U':
        return '🟦';
      case 'G':
        return '🟩';
    }
  }).join('');
};

const structureCardData = (cardData: any) => {
  const result: any = {
    name: cardData.name,
    id: cardData.id,
    gathererUrl: cardData.related_uris.gatherer,
    cmc: cardData.cmc,
    type: cardData.type,
  };

  if (cardData.image_uris) {
    result.url = cardData.image_uris.normal;
  }

  if (cardData.power) {
    result.powerAndToughness = cardData.power + '/' + cardData.toughness;
  }

  if (cardData.keywords?.length > 0) {
    result.keywords = cardData.keywords.join(', ');
  }

  if (cardData.colors?.length > 0) {
    result.colors = getEmojiStringForColors(cardData.colors);
  }
  
  // If it has multiple faces, fill in any data we don't already have.
  // There are cards like Silverflame Squire that have multiple faces in the data
  // but which have a top-level image_uris attribute.
  // This code should just cover all cases, basically if the image or power are
  // available at top level, use those, and if they weren't there check the
  // card_faces for that info, otherwise leave it undefined.
  if (cardData.card_faces) {
    if (!result.url) {
      result.url = cardData.card_faces[0]?.image_uris?.normal;
      result.altFaceUrl = cardData.card_faces[1]?.image_uris?.normal;
    }

    if (!result.colors) {
      if (cardData.card_faces[0]?.colors) {
        result.colors = getEmojiStringForColors(cardData.card_faces[0]?.colors);
      }

      if (cardData.card_faces[1]?.colors) {
        result.colors += getEmojiStringForColors(cardData.card_faces[1]?.colors);
        // if the two sides share colors, we want to reduce it down to unique colors, not repeat
        result.colors = [...(new Set(result.colors))].join('');
      }
    }

    if (!result.powerAndToughness) {
      let powerAndToughness = '';
      if (cardData.card_faces[0]?.power) {
        powerAndToughness += cardData.card_faces[0]?.power;
        powerAndToughness += '/';
        powerAndToughness += cardData.card_faces[0]?.toughness;
      }
      if (cardData.card_faces[1]?.power) {
        powerAndToughness += ' - ';
        powerAndToughness += cardData.card_faces[1]?.power;
        powerAndToughness += '/';
        powerAndToughness += cardData.card_faces[1]?.toughness;
      }
      result.powerAndToughness = powerAndToughness;
    }
  }

  return result;
};


export const addCardsToZone = async (
  cardData: {
    name: string,
    id: string, // this is the scryfall id (we use it for the key prop in autosuggest list)
    url: string,
    altFaceUrl: string,
    gathererUrl: string,
    cmc: number,
    type: string,
  } | null,
  quantity: number,
  isToken: boolean,
  destination: BattlefieldLocations,
) => {
  if (!cardData) {
    alert('Unable to fetch card.');
    return;
  }

  if (destination === BattlefieldLocations.Hand) {
    if (isToken) {
      alert('Cannot add tokens to hand.');
      return;
    }
    BattlefieldApi.addCardsToHand(cardData, quantity);
    return;
  }

  if (destination === BattlefieldLocations.PlayArea) {
    BattlefieldApi.addCardsToPlayArea(cardData, quantity, isToken);
    return;
  }
};