import { Store } from 'pullstate';
import { AccessRequestLocationOptions } from '../../../../../shared/types';
import { CarouselStore } from '../../../shared/CarouselStore';
import { UiStateStore } from '../../../shared/UiStateStore';
import BattlefieldApi from '../../../utils/BattlefieldApi';

export const getOtherPlayerHandAndLibraryMenuOptions = (
  instanceData: {
    handIsEmpty: boolean;
    libraryIsEmpty: boolean;
    mirrorMode: boolean;
  },
  carouselStore: Store<CarouselStore>,
  uiStateStore: Store<UiStateStore>,
) => {

  return [
    {
      text: 'Request to look at hand',
      action: () => {
        BattlefieldApi.requestToLookAtCards(
          AccessRequestLocationOptions.Hand,
          uiStateStore.getRawState().currentOtherPlayerId,
        );
      },
      disabled: instanceData.handIsEmpty || instanceData.mirrorMode,
    },
    {
      text: 'Request to look through library',
      action: () => {
        BattlefieldApi.requestToLookAtCards(
          AccessRequestLocationOptions.Library,
          uiStateStore.getRawState().currentOtherPlayerId,
        );
      },
      disabled: instanceData.libraryIsEmpty || instanceData.mirrorMode,
    },
    {
      text: 'Request to look at top X cards of library',
      action: () => {
        const numCards = +prompt('How many?', '1')!;
        if (isNaN(numCards) || numCards < 1) {
          alert('Must be 1 or more.');
          return;
        }
        BattlefieldApi.requestToLookAtCards(
          AccessRequestLocationOptions.TopNOfLibrary,
          uiStateStore.getRawState().currentOtherPlayerId,
          numCards,
        );
      },
      disabled: instanceData.libraryIsEmpty || instanceData.mirrorMode,
    },
  ];
};