import React, { useState } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { EmailAuthProvider, getAuth, reauthenticateWithCredential, updatePassword } from 'firebase/auth';
import { ServerErrors } from '../../../../shared/SocketEnums';
import axios, { AxiosError } from 'axios';
import { AppStore } from '../../../../shared/AppStore';

type SimplePromptDialogProps = {
  forceParentUpdate: () => void;
};

const SimplePromptDialog = ({ forceParentUpdate }: SimplePromptDialogProps) => {
  const { isOpen, mode } = AppStore.useState(s => s.accountPagePromptDialog);
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');
  const [newUsername, setNewUsername] = useState('');

  const auth = getAuth();
  const user = auth.currentUser;

  const onClose = () => {
    setCurrentPassword('');
    setNewPassword('');
    setConfirmNewPassword('');
    setNewUsername('');
    AppStore.update(s => { s.accountPagePromptDialog.isOpen = false; });
  };

  const onSaveNewPassword = async (retry?: boolean) => {
    if (!newPassword || !confirmNewPassword || !currentPassword) {
      alert('Field cannot be left blank.');
      return;
    }

    if (newPassword !== confirmNewPassword) {
      alert('Re-entered new password does not match.');
      return;
    }

    try {
      await updatePassword(user!, newPassword);
      alert('Password updated successfully.');
      onClose();
    } catch (error: any) {
      if (error.code === ServerErrors.REQUIRES_RECENT_LOGIN) {
        if (retry) {
          alert('Something went wrong. Log out and log back in, then try again.');
          onClose();
          return;
        }

        if (!auth.currentUser) {
          alert('Log out and log back in, then try again.');
          onClose();
          return;
        }

        const credential = EmailAuthProvider.credential(
          auth.currentUser!.email!,
          currentPassword,
        );

        try {
          await reauthenticateWithCredential(
            auth.currentUser, 
            credential,
          );
        } catch (error: any) {
          if (error.code === ServerErrors.INVALID_PASSWORD) {
            alert('Current password was incorrect.');
            return;
          }

          alert('Something went wrong. Log out and log back in, then try again.');
          onClose();
          return;
        }

        onSaveNewPassword(true);
        return;
      }

      if (error.code === ServerErrors.WEAK_PASSWORD) {
        alert('Password must be at least 8 characters. Try again.');
        return;
      }
    }
  };

  const onSaveNewUsername = async () => {
    try {
      const authToken = await auth.currentUser?.getIdToken(true);

      await axios.post('/update-username', {
        authToken,
        newName: newUsername,
      });
      await user?.reload();

      alert('Changes saved.');
      forceParentUpdate();
      onClose();
    } catch (error: any) {
      const errorCode = (error as AxiosError).response?.data.error;

      if (errorCode === ServerErrors.USERNAME_UNAVAILABLE) {
        alert('Username unavailable, choose a different one.');
      } else if (errorCode === ServerErrors.USERNAME_INVALID) {
        alert('Username must be between 1-15 characters and cannot contain spaces.');
      } else {
        alert('Something went wrong, changes discarded.');
      }
    }
  };

  if (mode === 'password') {
    return (
      <Dialog open={isOpen} onClose={onClose} PaperProps={{
        className: 'simplePromptDialog',
      }}>
        <DialogTitle>Update Password</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Current password"
            type="password"
            fullWidth
            variant="standard"
            value={currentPassword}
            onChange={(e) => { setCurrentPassword(e.target.value); }}
          />
          <br/>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="New password"
            type="password"
            fullWidth
            variant="standard"
            value={newPassword}
            onChange={(e) => { setNewPassword(e.target.value); }}
          />
          <br/>  
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Confirm new password"
            type="password"
            fullWidth
            variant="standard"
            value={confirmNewPassword}
            onChange={(e) => { setConfirmNewPassword(e.target.value); }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Cancel</Button>
          <Button onClick={(e) => { onSaveNewPassword() }}>Save</Button>
        </DialogActions>
      </Dialog>
    );
  }

  if (mode === 'username') { 
    return (
      <Dialog open={isOpen} onClose={onClose} PaperProps={{
        className: 'simplePromptDialog',
      }}>
        <DialogTitle>Update Username</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="New username"
            type="text"
            fullWidth
            variant="standard"
            value={newUsername}
            onChange={(e) => { setNewUsername(e.target.value); }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Cancel</Button>
          <Button onClick={(e) => { onSaveNewUsername() }}>Save</Button>
        </DialogActions>
      </Dialog>
    );
  }

  // should never happen but just satisfying typescript
  return null;
};

export default SimplePromptDialog;