import { Alert, Backdrop, Button, Dialog, DialogTitle } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Select } from 'react-functional-select';
import { DecklistItem, GameFormat } from '../../../../../../shared/types';
import { didUserChangeDecklist, getDataForEditedDeck, getRawDecklistFromDecklist } from './EditDeckUtils';
import { BarLoader } from 'react-spinners';
import { socket } from '../../../../../../shared/SocketInit';
import { ClientActions } from '../../../../../../shared/SocketEnums';
import ErrorUtils from '../../../../../../shared/ErrorUtils';
import { getDeckSizeForInput } from '../CreateDeckDialog/CreateDeckUtils';
import { AppStore } from '../../../../../../shared/AppStore';
import './EditDeckDialog.less';

const EditDeckDialog = () => {
  const { isOpen, deck } = AppStore.useState(s => s.editDeckDialog);

  const [deckName, setDeckName] = useState('');
  const [deckFormat, setDeckFormat] = useState(GameFormat.Any);

  const [decklist, setDecklist] = useState<DecklistItem[]>([]);
  const [sideboard, setSideboard] = useState<DecklistItem[]>([]);

  const [originalRawDecklist, setOriginalRawDecklist] = useState('');
  const [originalRawSideboard, setOriginalRawSideboard] = useState('');

  const [rawDecklist, setRawDecklist] = useState('');
  const [rawSideboard, setRawSideboard] = useState('');

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const [userMadeChanges, setUserMadeChanges] = useState(false);

  useEffect(() => {
    setError(null);
    setDeckName(deck?.name || '');
    setDeckFormat(deck?.format || GameFormat.Any);
    setDecklist(deck?.decklist || []);
    setSideboard(deck?.sideboard || []);
    const rawDecklist = deck ? getRawDecklistFromDecklist(deck.decklist) : '';
    const rawSideboard = deck ? getRawDecklistFromDecklist(deck.sideboard || []) : ''
    setRawDecklist(rawDecklist);
    setRawSideboard(rawSideboard);
    setOriginalRawDecklist(rawDecklist);
    setOriginalRawSideboard(rawSideboard);
    setUserMadeChanges(false);
  }, [deck]);

  const reloadDecks = () => {
    socket.emit(ClientActions.LOAD_USER, (response: any) => {
      if (response.error) {
        AppStore.update(s => {
          s.userData.error = 'Something went wrong while loading user data.';
        });
      } else {
        AppStore.update(s => {
          s.userData.decks = response.decks || [];
        });
      }
    });
  };

  const showDeleteConfirmation = () => {
    if (confirm('Are you sure you want to delete this deck?')) {
      setLoading(true);

      socket.emit(ClientActions.DELETE_DECK, { deckId: deck!.id },
        (response: any) => {
          if (response.error) {
            setLoading(false);
            ErrorUtils.handleGenericError(response);
          } else {
            closeDialog();
            AppStore.update(s => { s.editDeckDialog.showDeleteSuccess = true; });
            reloadDecks();
          }
        });
    }
  };

  const closeDialog = () => {
    AppStore.update(s => {
      s.editDeckDialog.isOpen = false;
      s.editDeckDialog.deck = null;
    });

    setError(null);
    setLoading(false);
    setDeckName('');
    setDeckFormat(GameFormat.Any);
    setDecklist([]);
    setSideboard([]);
    setRawDecklist('');
    setRawSideboard('');
    setUserMadeChanges(false);
  };

  const saveChanges = async () => {
    setLoading(true);

    const result = await getDataForEditedDeck(
      rawDecklist, rawSideboard, setError, setRawDecklist, setRawSideboard);

    if (!result) {
      setLoading(false);
      return;
    }

    const colors = {
      red: false,
      blue: false,
      white: false,
      black: false,
      green: false,
      colorless: false,
    };

    [...(result.decklist || []), ...(result.sideboard || [])].forEach(item => {
      if (item.colors.length === 0 && !item.type.match(/land/i)) {
        colors.colorless = true;
        return;
      }

      if (item.colors.includes('R')) {
        colors.red = true;
      }

      if (item.colors.includes('U')) {
        colors.blue = true;
      }

      if (item.colors.includes('W')) {
        colors.white = true;
      }

      if (item.colors.includes('B')) {
        colors.black = true;
      }

      if (item.colors.includes('G')) {
        colors.green = true;
      }
    });

    const editedDeck = {
      id: deck!.id,
      name: deckName,
      format: deckFormat,
      decklist: result.decklist,
      sideboard: result.sideboard,
      colors,
    };

    socket.emit(ClientActions.EDIT_DECK, { editedDeck },
      (response: any) => {
        if (response.error) {
          setLoading(false);
          ErrorUtils.handleGenericError(response);
        } else {
          closeDialog();
          AppStore.update(s => { s.editDeckDialog.showEditSuccess = true; });
          reloadDecks();
        }
      });
  };

  const decklistPlaceholder = '1 Volrath the Fallen\n20 Island\n16 Plains\n4 Counterspell\netc...\n\nThe first card will be the deck\'s cover image.\n\nFor EDH, the first card should be your commander and that card will start in the play area.';

  const sideboardPlaceholder = '1 Volrath the Fallen\n20 Island\n16 Plains\n4 Counterspell\netc...\n';

  if (!deck) return null;

  const deckSize = getDeckSizeForInput(rawDecklist);
  const sideboardSize = getDeckSizeForInput(rawSideboard);

  return (
    <Dialog
      open={isOpen}
      onClose={closeDialog}
      PaperProps={{
        className: 'editDeckDialogContainer',
      }}
    >
      <DialogTitle className="editDeckDialogTitle">
        Edit Deck
      </DialogTitle>

      <div className="editDeckDialogContent">
        <div className="nameAndFormatContainer">
          <div className="nameContainer">
            <label className="nameLabel">Name</label>
            <input
              className="nameInput"
              onChange={(e: any) => {
                setDeckName(e.target.value);
                if (e.target.value !== deck.name) {
                  setUserMadeChanges(true);
                }
              }}
              autoFocus
              type="text"
              value={deckName}
              placeholder="Elves and Goblins"
            />
          </div>

          <div className="formatContainer">
            <label htmlFor="format" className="formatLabel">
              Format
            </label>
            <Select
              initialValue={[deckFormat]}
              options={Object.values(GameFormat)}
              onOptionChange={(value) => {
                setDeckFormat(value as GameFormat);
                if (value !== deck!.format) {
                  setUserMadeChanges(true);
                }
              }}
              getOptionValue={opt => opt}
              getOptionLabel={opt => opt}
              isSearchable={false}
            />
          </div>
        </div>

        <div className="textareasContainer">
          <div className="decklistContainer">
            <div className="instructionsLabel">
              Decklist
            </div>
            <textarea
              value={rawDecklist}
              style={{ marginBottom: error ? '' : '25px'}}
              onChange={(e: any) => {
                setRawDecklist(e.target.value);
                setUserMadeChanges(didUserChangeDecklist(originalRawDecklist, e.target.value));
              }}
              className="decklistInput"
              placeholder={decklistPlaceholder}
            />
            <div className="deckSize" style={{ bottom: error ? '10px' : '35px'}}>
              Cards: {deckSize}
            </div>
          </div>

          <div className="sideboardContainer">
            <div className="instructionsLabel">
              Sideboard
            </div>
            <textarea
              value={rawSideboard}
              style={{ marginBottom: error ? '' : '25px'}}
              onChange={(e: any) => {
                setRawSideboard(e.target.value);
                setUserMadeChanges(didUserChangeDecklist(originalRawSideboard, rawSideboard));
              }}
              className="decklistInput"
              placeholder={sideboardPlaceholder}
            />
            <div className="sideboardSize" style={{ bottom: error ? '10px' : '35px'}}>
              Cards: {sideboardSize}
            </div>
          </div>
        </div>

        {error && <Alert severity="error" className="errorMessage">{error}</Alert>}

        <div className="buttonRow">
          <Button
            className="deleteButton"
            disabled={loading}
            color="error"
            variant="contained"
            onClick={showDeleteConfirmation}
            >
            Delete Deck
          </Button>

          <Button
            className="submitButton"
            disabled={loading}
            variant="text"
            onClick={closeDialog}
            >
            {userMadeChanges ? 'Cancel' : 'Close'}
          </Button>

          {userMadeChanges &&
            <Button
              className="saveButton"
              disabled={loading}
              variant="contained"
              onClick={saveChanges}
              >
              Save Changes
            </Button>
          }
        </div>

        {loading &&
          <Backdrop open={loading}>
            <BarLoader color="#36d7b7"/>
          </Backdrop>
        }
      </div>
    </Dialog>
  );
};

export default EditDeckDialog;