import { Checkbox, Grid, Switch } from '@mui/material';
import React, { useEffect, useState } from 'react';

type DecklistFilterControlsProps = {
  setColorFilter: any;
  setSortAlphabetically: any;
  sortAlphabetically: boolean;
};

const DecklistFilterControls = ({ setColorFilter, setSortAlphabetically, sortAlphabetically }: DecklistFilterControlsProps) => {
  const [checkedWhite, setCheckedWhite] = useState(false);
  const [checkedBlack, setCheckedBlack] = useState(false);
  const [checkedRed, setCheckedRed] = useState(false);
  const [checkedBlue, setCheckedBlue] = useState(false);
  const [checkedGreen, setCheckedGreen] = useState(false);
  const [checkedColorless, setCheckedColorless] = useState(false);

  useEffect(() => {
    // null if nothing is checked. empty string if colorless.
    // otherwise, some combination of RGBWU.
    const colorString = (
      checkedColorless
      || (!checkedBlack && !checkedRed && !checkedBlue && !checkedWhite && !checkedGreen)
    )
      ? (checkedColorless ? '' : null)
      : `${checkedRed ? 'R' : ''}${checkedGreen ? 'G' : ''}${checkedBlue ? 'U' : ''}${checkedWhite ? 'W' : ''}${checkedBlack ? 'B' : ''}`;    

    setColorFilter(colorString);
  }, [checkedBlack, checkedRed, checkedBlue, checkedWhite, checkedGreen, checkedColorless]);

  return (
    <div className="decklistFilterControls">
      <span className="colorFiltersLabel">Color filters:</span>
      <Checkbox
        checked={checkedWhite}
        onChange={(e) => {
          setCheckedWhite(e.target.checked);
          setCheckedColorless(false);
        }}
        style ={{ color: "white" }}
      />
      <Checkbox
        checked={checkedBlack}
        onChange={(e) => {
          setCheckedBlack(e.target.checked);
          setCheckedColorless(false);
        }}
        style ={{ color: "black" }}
      />
      <Checkbox
        checked={checkedRed}
        onChange={(e) => {
          setCheckedRed(e.target.checked);
          setCheckedColorless(false);
        }}
        style ={{ color: "#e62424" }}
      />
      <Checkbox
        checked={checkedBlue}
        onChange={(e) => {
          setCheckedBlue(e.target.checked);
          setCheckedColorless(false);
        }}
        style ={{ color: "#6b6bf3" }}
      />
      <Checkbox
        checked={checkedGreen}
        onChange={(e) => {
          setCheckedGreen(e.target.checked);
          setCheckedColorless(false);
        }}
        style ={{ color: "green" }}
      />
      <Checkbox
        checked={checkedColorless}
        onChange={(e) => {
          setCheckedColorless(e.target.checked);
          setCheckedBlack(false);
          setCheckedWhite(false);
          setCheckedBlue(false);
          setCheckedRed(false);
          setCheckedGreen(false);
        }}
        style ={{ color: "grey" }}
      />

      <Grid component="label" container alignItems="center" spacing={1} className="sortSwitch">
        <Grid item>CMC</Grid>
        <Grid item>
          <Switch
            checked={sortAlphabetically}
            onChange={(e) => { setSortAlphabetically(e.target.checked); }}
          />
        </Grid>
        <Grid item>A→Z</Grid>
      </Grid>
    </div>
  );
};

export default DecklistFilterControls;