import { Store } from 'pullstate';
import { BattlefieldLocations, ContextMenuInstanceSources, ContextMenuSource } from '../../../../../shared/types';
import { CarouselStore } from '../../../shared/CarouselStore';
import { UiStateStore } from '../../../shared/UiStateStore';
import { getCarouselCardMenuOptions } from './CarouselCardMenuOptions';
import { getHandMenuOptions } from './HandMenuOptions';
import { getExileMenuOptions } from './ExileMenuOptions';
import { getGraveyardMenuOptions } from './GraveyardMenuOptions';
import { getLibraryMenuOptions } from './LibraryMenuOptions';
import { getPlayAreaCardMenuOptions } from './PlayAreaCardMenuOptions';
import { getPlayAreaCounterMenuOptions } from './PlayAreaCounterMenuOptions';
import { getOtherPlayerPlayAreaCardMenuOptions } from './OtherPlayerPlayAreaCardMenuOptions';
import { getOtherPlayerPileMenuOptions } from './OtherPlayerPileMenuOptions';
import { getOtherPlayerHandAndLibraryMenuOptions } from './OtherPlayerHandAndLibraryMenuOptions';
import { getCounterButtonMenuOptions } from './CounterButtonMenuOptions';

const getMenuOptions = (
  source: ContextMenuSource,
  instanceData: any,
  carouselStore: Store<CarouselStore>,
  uiStateStore: Store<UiStateStore>,
) => {
  switch (source) {
    case BattlefieldLocations.Hand:
      return getHandMenuOptions(carouselStore, uiStateStore);
    case BattlefieldLocations.Library:
      return getLibraryMenuOptions(carouselStore, uiStateStore);
    case BattlefieldLocations.Graveyard:
      return getGraveyardMenuOptions(carouselStore, uiStateStore);
    case BattlefieldLocations.Exile:
      return getExileMenuOptions(carouselStore, uiStateStore);
    case BattlefieldLocations.OtherPlayerHand:
      return getOtherPlayerHandAndLibraryMenuOptions(instanceData, carouselStore, uiStateStore);
    case BattlefieldLocations.OtherPlayerGraveyard:
      return getOtherPlayerPileMenuOptions(source, carouselStore, uiStateStore, instanceData);
    case BattlefieldLocations.OtherPlayerExile:
      return getOtherPlayerPileMenuOptions(source, carouselStore, uiStateStore, instanceData);
    case ContextMenuInstanceSources.PlayAreaCard:
      return getPlayAreaCardMenuOptions(instanceData, carouselStore, uiStateStore);
    case ContextMenuInstanceSources.OtherPlayerPlayAreaCard:
      return getOtherPlayerPlayAreaCardMenuOptions(instanceData, carouselStore, uiStateStore);
    case ContextMenuInstanceSources.PlayAreaCounter:
      return getPlayAreaCounterMenuOptions(instanceData, carouselStore, uiStateStore);
    case ContextMenuInstanceSources.CarouselCard:
      return getCarouselCardMenuOptions(instanceData, carouselStore, uiStateStore);
    case ContextMenuInstanceSources.CounterButton:
      return getCounterButtonMenuOptions(uiStateStore);
    default:
      return [];
  }
};

export default getMenuOptions;