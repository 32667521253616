import { Dialog, DialogTitle } from '@material-ui/core';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { CardData, DRAG_ZINDEX } from '../../../../../shared/types';
import { GameStoreContext } from '../../../shared/GameStore';
import { UiStateStoreContext } from '../../../shared/UiStateStore';
import { cancelMagnification, setMagnifiedCardDetails } from '../../PlayArea/PlayAreaUtils';
import { getArtifactsMarkup, getCreaturesMarkup, getEnchantmentsMarkup, getLineForEntry, getMiscellaneousMarkup, sortDecklistByType } from './DecklistUtils';
import DecklistFilterControls from './DecklistFilterControls';
import './DecklistDialog.less';

const DecklistDialog = () => {
  const uiStateStore = useContext(UiStateStoreContext);
  const gameStore = useContext(GameStoreContext);

  const isOpen = uiStateStore.useState(s => s.decklistDialog.isOpen);
  const decklist = gameStore.useState(s => s.privateCardData.currentDecklist);

  const [typeFilter, setTypeFilter] = useState('');
  const [colorFilter, setColorFilter] = useState<string | null>(null);
  const [sortAlphabetically, setSortAlphabetically] = useState(true);

  useEffect(() => {
    // Something weird happens here where the useEffect (or useLayoutEffect!) is
    // executed BEFORE the dialog renders, and so finds no cardReferences
    // to add listeners to. I'm guessing MUI dialog has some stuff going on that
    // involves modifying the DOM post-render. Anyway, a 50ms timeout resolves the issue.
    setTimeout(() => {
      const cardReferences = document.querySelectorAll('.decklistEntry');

      cardReferences.forEach(cardReference => {
        const cardNameSpan = cardReference.querySelector('.itemName');

        cardNameSpan?.addEventListener('mouseenter', () => {
          const url = cardReference.getAttribute('data-url');
          const altFaceUrl = cardReference.getAttribute('data-altfaceurl');

          setMagnifiedCardDetails(
            { url, altFaceUrl } as CardData,
            { current: cardReference },
            uiStateStore,
            { noDelay: true },
          );
        });

        cardReference.addEventListener('mouseleave', () => {
          cancelMagnification(uiStateStore);
        });
      });
    }, 50);
    // I originally thought we needed cleanup here to remove listeners, lest we end up
    // with thousands of duplicate listeners, but it doesn't work that way. I tested it
    // and setMagnifiedCardDetails is only ever called once per hover, no matter the number
    // of times this runs.
  }, [isOpen, typeFilter, colorFilter]);

  const sortedDecklist = decklist && useMemo(() => sortDecklistByType(decklist), [decklist]);

  if (!decklist) return null;

  return (
    <Dialog
      open={isOpen}
      onClose={() => uiStateStore.update(s => {
        s.decklistDialog.isOpen = false;
        cancelMagnification(uiStateStore);
        // timeout to avoid seeing flicker of state change as it closes
        setTimeout(() => {
          setTypeFilter('');
        }, 100);
      })}
      style={{ zIndex: DRAG_ZINDEX }}
      PaperProps={{
        className: 'decklistDialog',
      }}
    >
      <DialogTitle>
        <span>Decklist</span>
        {/* no filtering for decks created before type info was added */}
        {sortedDecklist && (
          <>
            <input
              type="text"
              className="typeFilterInput"
              placeholder="Filter by type..."
              onChange={(changeEvent: any) => {
                setTypeFilter(changeEvent.target.value);
              }}
            />
            <DecklistFilterControls
              setColorFilter={setColorFilter}
              setSortAlphabetically={setSortAlphabetically}
              sortAlphabetically={sortAlphabetically}
            />
          </>
        )}
      </DialogTitle>
      <div className="decklistDialogContent">
        {sortedDecklist &&
          <>
            {getCreaturesMarkup(sortedDecklist, sortAlphabetically, colorFilter, typeFilter)}
            {getArtifactsMarkup(sortedDecklist, sortAlphabetically, colorFilter, typeFilter)}
            {getEnchantmentsMarkup(sortedDecklist, sortAlphabetically, colorFilter, typeFilter)}
            {getMiscellaneousMarkup(sortedDecklist, sortAlphabetically, colorFilter, typeFilter)}
          </>
        }
        {/* for older decks from before we were saving type info from scryfall */}
        {!sortedDecklist &&
          <ul>
            {decklist.map(item => getLineForEntry(item, null))}
          </ul>
        }
      </div>
    </Dialog>
  );
};

export default DecklistDialog;