import { Alert, Backdrop, Button, Dialog, DialogTitle } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Select } from 'react-functional-select';
import { GameFormat } from '../../../../../../shared/types';
import { createDeck, getDeckSizeForInput } from './CreateDeckUtils';
import { BarLoader } from 'react-spinners';
import { AppStore } from '../../../../../../shared/AppStore';
import './CreateDeckDialog.less';

const CreateDeckDialog = () => {
  const isOpen = AppStore.useState(s => s.createDeckDialog.isOpen);

  const [deckName, setDeckName] = useState('');
  const [deckFormat, setDeckFormat] = useState(GameFormat.Any);
  const [decklist, setDecklist] = useState('');
  const [sideboard, setSideboard] = useState('');

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const decklistPlaceholder = '1 Volrath the Fallen\n20 Island\n16 Plains\n4 Counterspell\netc...\n\nThe first card will be the deck\'s cover image.\n\nFor EDH, the first card should be your commander and that card will start in the play area.';

  const sideboardPlaceholder = '1 Volrath the Fallen\n20 Island\n16 Plains\n4 Counterspell\netc...\n';

  useEffect(() => {
    setDeckFormat(GameFormat.Any);
    setDeckName('');
    setDecklist('');
    setSideboard('');
    setError(null);
  }, [isOpen]);

  const deckSize = getDeckSizeForInput(decklist);
  const sideboardSize = getDeckSizeForInput(sideboard);

  return (
    <Dialog
      open={isOpen}
      onClose={() => {
        AppStore.update(s => {
          s.createDeckDialog.isOpen = false;
        });
      }}
      PaperProps={{
        className: 'createDeckDialogContainer',
      }}
    >
      <DialogTitle className="createDeckDialogTitle">
        Create Deck
      </DialogTitle>

      <div className="createDeckDialogContent">
        <div className="nameAndFormatContainer">
          <div className="nameContainer">
            <label className="nameLabel">Name</label>
            <input
              className="nameInput"
              onChange={(e: any) => {
                setDeckName(e.target.value);
              }}
              autoFocus
              type="text"
              value={deckName}
              placeholder="Elves and Goblins"
            />
          </div>

          <div className="formatContainer">
            <label htmlFor="format" className="formatLabel">
              Format
            </label>
            <Select
              initialValue={[GameFormat.Any]}
              options={Object.values(GameFormat)}
              onOptionChange={setDeckFormat}
              getOptionValue={opt => opt}
              getOptionLabel={opt => opt}
              isSearchable={false}
            />
          </div>
        </div>

        <div className="textareasContainer">
          <div className="decklistContainer">
            <div className="instructionsLabel">
              Paste your decklist below in the format shown.
            </div>
            <textarea
              value={decklist}
              style={{ marginBottom: error ? '' : '25px'}}
              onChange={(e: any) => {
                setDecklist(e.target.value);
              }}
              className="decklistInput"
              placeholder={decklistPlaceholder}
            />
            <div className="deckSize" style={{ bottom: error ? '10px' : '35px'}}>
              Cards: {deckSize}
            </div>
          </div>

          <div className="sideboardContainer">
            <div className="instructionsLabel">
              And paste your sideboard here (optional).
            </div>
            <textarea
              value={sideboard}
              style={{ marginBottom: error ? '' : '25px'}}
              onChange={(e: any) => {
                setSideboard(e.target.value);
              }}
              className="decklistInput"
              placeholder={sideboardPlaceholder}
            />
            <div className="sideboardSize" style={{ bottom: error ? '10px' : '35px'}}>
              Cards: {sideboardSize}
            </div>
          </div>
        </div>

        {error && <Alert severity="error" className="errorMessage">{error}</Alert>}

        <Button className="submitButton" disabled={loading} variant="contained" onClick={(e: any) => {
          createDeck(deckName, deckFormat, decklist, sideboard, setLoading, setError, setDecklist, setSideboard, AppStore);
        }}>
          Create Deck
        </Button>

        {loading &&
          <Backdrop open={loading}>
            <BarLoader color="#36d7b7"/>
          </Backdrop>
        }
      </div>
    </Dialog>
  );
};

export default CreateDeckDialog;