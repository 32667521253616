import React, { useContext, useEffect } from 'react';
import { Deck, GameFormat } from '../../../../shared/types';
import DeckManagerEmptyState from './components/DeckManagerEmptyState';
import DeckManagerControls from './components/DeckManagerControls';
import DeckItem from './components/DeckItem';
import { Alert, Snackbar } from '@mui/material';
import { filterDecks } from './DeckManagerUtils';
import { AppStore } from '../../../../shared/AppStore';
import './DeckManager.less';

const DeckManager = () => {
  const { loading, error, decks } = AppStore.useState(s => s.userData);
  const { nameFilter, formatFilter, colorFilter, colorFilterStrategy } = AppStore.useState(s => s.deckManager);

  const showCreateSuccessAlert = AppStore.useState(s => s.createDeckDialog.showSuccess);
  const showDeleteSuccessAlert = AppStore.useState(s => s.editDeckDialog.showDeleteSuccess);
  const showEditSuccessAlert = AppStore.useState(s => s.editDeckDialog.showEditSuccess);

  useEffect(() => {
    return () => {
      AppStore.update(s => {
        // hide these on unmount so it doesn't keep showing up if you click back and forth
        s.createDeckDialog.showSuccess = false;
        s.editDeckDialog.showDeleteSuccess = false;
        s.editDeckDialog.showEditSuccess = false;
        // reset filters
        s.deckManager.formatFilter = GameFormat.Any;
        s.deckManager.nameFilter = '';
      });
    }
  }, []);

  const closeSuccessAlerts = () => {
    AppStore.update(s => { s.createDeckDialog.showSuccess = false; });
    AppStore.update(s => {
      s.editDeckDialog.showDeleteSuccess = false;
      s.editDeckDialog.showEditSuccess = false;
    });
  };

  const filteredDecks = filterDecks(decks, nameFilter, formatFilter, colorFilter, colorFilterStrategy);

  const showEmptyState = error || loading || filteredDecks.length === 0;
  const filteredToEmpty = filteredDecks.length === 0 && decks.length > 0;

  return (
    <>
      <DeckManagerControls/>

      <div className={`decksList ${showEmptyState ? 'emptyState' : ''}`}>
        {showEmptyState && <DeckManagerEmptyState filtered={filteredToEmpty} />}

        {!showEmptyState && filteredDecks.map((deck: Deck) => (
          <DeckItem key={deck.id} deck={deck}/>
        ))}
      </div>

      <Snackbar
        open={showCreateSuccessAlert}
        autoHideDuration={6000}
        onClose={closeSuccessAlerts}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <Alert onClose={closeSuccessAlerts} severity="success" sx={{ width: '100%' }}>
          Deck created.
        </Alert>
      </Snackbar>

      <Snackbar
        open={showEditSuccessAlert}
        autoHideDuration={6000}
        onClose={closeSuccessAlerts}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <Alert onClose={closeSuccessAlerts} severity="success" sx={{ width: '100%' }}>
          Changes saved.
        </Alert>
      </Snackbar>

      <Snackbar
        open={showDeleteSuccessAlert}
        autoHideDuration={6000}
        onClose={closeSuccessAlerts}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <Alert onClose={closeSuccessAlerts} severity="error" sx={{ width: '100%' }}>
          Deck deleted.
        </Alert>
      </Snackbar>
    </>
  );
};

export default DeckManager;