import React, { useState } from 'react';
import axios, { AxiosError } from 'axios';
import { getAuth, sendPasswordResetEmail, signInWithEmailAndPassword } from 'firebase/auth';
import { TextField, Button, Backdrop } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { ServerErrors } from '../../../../../shared/SocketEnums';
import { BarLoader } from 'react-spinners';
import { authConnectOnLogin } from '../../../../../shared/utils';
import { AppStore } from '../../../../../shared/AppStore';
import './SignInDialog.less';

const SignInDialog = () => {
  const isOpen = AppStore.useState(s => s.signInDialog.isOpen);

  const closeDialog = () => {
    AppStore.update(s => {
      s.signInDialog.isOpen = false;
      s.signingIn = false;
    });
  };

  const [creatingAccount, setCreatingAccount] = useState(false);
  const [resettingPassword, setResettingPassword] = useState(false);

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [username, setUsername] = useState('');

  // whitespace for no-error default value so that it still takes up space in doc.
  // just an easy way of accomplishing that
  const [usernameError, setUsernameError] = useState<string>(' ');
  const [emailError, setEmailError] = useState<string>(' ');
  const [passwordError, setPasswordError] = useState<string>(' ');

  const [loading, setLoading] = useState(false);

  const auth = getAuth();

  const getMainButtonText = () => {
    if (resettingPassword) {
      return 'Reset Password';
    } else return creatingAccount ? 'Sign Up' : 'Log In';
  };

  const onEmailChange = (event: any) => {
    setEmail(event.target.value);
    setEmailError(' ');
  };

  const onPasswordChange = (event: any) => {
    setPassword(event.target.value);
    setPasswordError(' ');
  };

  const onUsernameChange = (event: any) => {
    setUsername(event.target.value);
    setUsernameError(' ');
  };

  const validateInputs = () => {
    let isValid = true;

    if (email.trim() === '') {
      setEmailError('Field cannot be left blank.');
      isValid = false;
    }

    if (password.trim() === '') {
      setPasswordError('Field cannot be left blank.');
      isValid = false;
    }

    if (creatingAccount) {
      if (username.trim() === '') {
        setUsernameError('Field cannot be left blank.');
        isValid = false;
      } else if (username.includes(' ')) {
        setUsernameError('Username cannot contain spaces.');
        isValid = false;
      } else if (username.length > 15) {
        setUsernameError('Username cannot be longer than 15 characters.');
        isValid = false;
      }

      if (password.trim().length > 0 && password.length < 8) {
        setPasswordError('Password must be at least 8 characters.');
        isValid = false;
      }
    }

    return isValid;
  };

  const handleLogin = async () => {
    if (!validateInputs()) {
      return;
    }

    // if we got here from handleSignUp, loading is already true
    if (!loading) setLoading(true);

    AppStore.update(s => { s.signingIn = true; });

    try {
      await signInWithEmailAndPassword(auth, email, password);

      authConnectOnLogin(AppStore,
        // success handler
        () => {
          closeDialog();
          AppStore.update(s => { s.signingIn = false });
          setLoading(false);
        },
        // error handler
        () => {
          setEmailError('Invalid auth attempt. Try again.');
          AppStore.update(s => { s.signingIn = false });
          setLoading(false);
        }
      );
    } catch (error: any) {
      setLoading(false);

      if (error.code === ServerErrors.INVALID_PASSWORD) {
        setPasswordError('Invalid password.');
        return;
      }

      if (error.code === ServerErrors.USER_NOT_FOUND) {
        setEmailError('Email not found.');
        return;
      }

      console.log('login error:');
      console.log(error);

      // generic catch-all
      setEmailError('Something went wrong. Try again.');
      return;
    }
  };

  const handleSignUp = async () => {
    if (!validateInputs()) {
      return;
    }
  
    setLoading(true);

    try {
      await axios.post('/create-user', { username, email, password });
    } catch (error) {
      setLoading(false);
      const errorCode = (error as AxiosError).response?.data.error;

      if (errorCode === ServerErrors.EMAIL_ALREADY_IN_USE) {
        setEmailError('There is already an account for this email.');
        return;
      }

      if (errorCode === ServerErrors.INVALID_EMAIL) {
        setEmailError('Invalid email. Check formatting?');
        return;
      }

      if (errorCode === ServerErrors.WEAK_PASSWORD) {
        setPasswordError('Password must be at least 8 characters.');
        return;
      }

      if (errorCode === ServerErrors.USERNAME_UNAVAILABLE) {
        setUsernameError('Username taken.');
        return;
      }

      if (errorCode === ServerErrors.USERNAME_INVALID) {
        setUsernameError('Username invalid.');
        return;
      }

      console.log('signup error:');
      console.log(error);

      // generic catch-all
      setUsernameError('Something went wrong.');
      return;
    }

    handleLogin();
  };

  const handlePasswordReset = async () => {
    if (email.trim() === '') {
      setEmailError('This field cannot be blank.');
      return;
    }

    setLoading(true);

    try {
      await sendPasswordResetEmail(auth, email);
    } catch (error: any) {
      setLoading(false);

      if (error.code === ServerErrors.INVALID_EMAIL) {
        setEmailError('Invalid email.');
        return;
      }

      if (error.code === ServerErrors.USER_NOT_FOUND) {
        setEmailError('Email not found.');
        return;
      }
    }

    setLoading(false);
    closeDialog();
    alert('A password reset email has been sent.');
  };

  const executeMainAction = () => {
    if (creatingAccount) {
      handleSignUp();
    } else if (resettingPassword) {
      handlePasswordReset();
    } else {
      handleLogin();
    }
  };

  return (
    <Dialog
      open={isOpen}
      onClose={closeDialog}
      aria-labelledby="form-dialog-title"
      PaperProps={{
        className: 'signInDialogContainer',
      }}
    >
      <DialogTitle className="signInDialogTitle">
        {getMainButtonText() /*cuz it's the same as the button*/}
      </DialogTitle>
      <DialogContent>
        <form className="signUpForm">

          {creatingAccount && !resettingPassword &&
          <TextField
            error={!!usernameError.trim()}
            helperText={usernameError}
            // ids on inputs are for attempted OnePassword compatibility
            id="username"
            className="usernameField"
            label="Username"
            variant="outlined"
            margin="dense"
            value={username}
            onChange={onUsernameChange}
            onFocus={() => { setUsernameError(' '); }}
            fullWidth
          />}

          <TextField
            error={!!emailError.trim()}
            helperText={emailError}
            id="email"
            autoComplete="email"
            className="emailField"
            label="Email"
            variant="outlined"
            margin="dense"
            value={email}
            onChange={onEmailChange}
            onFocus={() => { setEmailError(' '); }}
            fullWidth
          />

          {!resettingPassword &&
          <TextField
            error={!!passwordError.trim()}
            helperText={passwordError}
            id="password"
            className="passwordField"
            label="Password"
            type="password"
            variant="outlined"
            autoComplete="current-password"
            margin="dense"
            value={password}
            onChange={onPasswordChange}
            onKeyPress={event => {
              if (event.key === 'Enter') {
                event.preventDefault();
                creatingAccount ? handleSignUp() : handleLogin();
              }
            }}
            onFocus={() => { setPasswordError(' '); }}
            fullWidth
          />}

          <Button
            className="signUpButton"
            color="primary"
            variant="contained"
            onClick={executeMainAction}
          >
            {getMainButtonText()}
          </Button>

          <Button
            color="primary"
            className="forgotPassword"
            onClick={() => {
              setResettingPassword(!resettingPassword);
            }}
          >
            {resettingPassword ? 'Go back' : 'Forgot your password?'}
          </Button>

          <span className="loginOrSignupSwitchLabel">
            {creatingAccount ?
              'Already have an account?'
              : 'New to CardTavern?'}
          </span>

          <Button
            color="primary"
            className="loginOrSignUpSwitch"
            onClick={() => {
              setEmail('');
              setPassword('');
              setCreatingAccount(!creatingAccount);
              setResettingPassword(false);
            }}
          >
            {creatingAccount ? 'Log In' : 'Sign Up'}
          </Button>

        </form>

        {loading &&
          <Backdrop className="loadingBackdrop" open={loading}>
            <BarLoader color="#36d7b7"/>
          </Backdrop>
        }
      </DialogContent>
    </Dialog>
  );
};

export default SignInDialog;