import React, { useEffect, useRef, useState } from 'react';
import { Checkbox, Tooltip } from '@mui/material';
import HelpIcon from '@mui/icons-material/Help';
import { Select } from 'react-functional-select';
import { ColorFilterStrategies } from '../../../../../shared/types';
import { AppStore } from '../../../../../shared/AppStore';

const DeckManagerColorFilters = () => {
  const [checkedWhite, setCheckedWhite] = useState(false);
  const [checkedBlack, setCheckedBlack] = useState(false);
  const [checkedRed, setCheckedRed] = useState(false);
  const [checkedBlue, setCheckedBlue] = useState(false);
  const [checkedGreen, setCheckedGreen] = useState(false);
  const [checkedColorless, setCheckedColorless] = useState(false);
  const colorFilterStrategy = AppStore.useState(s => s.deckManager.colorFilterStrategy);

  const strategyDropdownRef = useRef(null);

  useEffect(() => {
    if (colorFilterStrategy !== ColorFilterStrategies.Exactly) {
      setCheckedColorless(false);
    }
  }, [colorFilterStrategy])

  useEffect(() => {
    if (checkedColorless) {
      AppStore.update(s =>  {
        s.deckManager.colorFilterStrategy = ColorFilterStrategies.Exactly;
      });
      (strategyDropdownRef as any).current?.setValue([ColorFilterStrategies.Exactly]);
    }
  }, [checkedColorless]);

  useEffect(() => {
    AppStore.update(s => {
      s.deckManager.colorFilter = {
        red: checkedRed,
        blue: checkedBlue,
        white: checkedWhite,
        black: checkedBlack,
        green: checkedGreen,
        colorless: checkedColorless,
      };
    });
  }, [checkedBlack, checkedRed, checkedBlue, checkedWhite, checkedGreen, checkedColorless]);

  const tooltipText = 'This dropdown controls how the color filters are applied. Exactly: decks that have exactly these colors. Including: decks that have at least these colors, and may have other colors too. At most: decks that have some or all of the selected colors, but no others. If the colorless filter is selected, only purely colorless decks will match.';

  return (
    <div className="deckManagerColorFilters">
      <Checkbox
        checked={checkedWhite}
        onChange={(e) => {
          setCheckedWhite(e.target.checked);
          setCheckedColorless(false);
        }}
        style ={{ color: "white" }}
      />
      <Checkbox
        checked={checkedBlack}
        onChange={(e) => {
          setCheckedBlack(e.target.checked);
          setCheckedColorless(false);
        }}
        style ={{ color: "black" }}
      />
      <Checkbox
        checked={checkedRed}
        onChange={(e) => {
          setCheckedRed(e.target.checked);
          setCheckedColorless(false);
        }}
        style ={{ color: "#e62424" }}
      />
      <Checkbox
        checked={checkedBlue}
        onChange={(e) => {
          setCheckedBlue(e.target.checked);
          setCheckedColorless(false);
        }}
        style ={{ color: "#6b6bf3" }}
      />
      <Checkbox
        checked={checkedGreen}
        onChange={(e) => {
          setCheckedGreen(e.target.checked);
          setCheckedColorless(false);
        }}
        style ={{ color: "green" }}
      />
      <Checkbox
        checked={checkedColorless}
        onChange={(e) => {
          setCheckedColorless(e.target.checked);
          setCheckedBlack(false);
          setCheckedWhite(false);
          setCheckedBlue(false);
          setCheckedRed(false);
          setCheckedGreen(false);
        }}
        style ={{ color: "grey" }}
      />
      <div className="colorFilterStrategy">
        <Select
          initialValue={[ColorFilterStrategies.Including]}
          options={Object.values(ColorFilterStrategies)}
          onOptionChange={val => { AppStore.update(s =>  {
            s.deckManager.colorFilterStrategy = val;
          })}}
          getOptionValue={opt => opt}
          getOptionLabel={opt => opt}
          isSearchable={false}
          ref={strategyDropdownRef}
        />
        <div className="colorStrategyTooltip">
          <Tooltip title={tooltipText}>
            <HelpIcon style={{
              position: 'relative',
              height: '20px',
              top: '5px',
              marginLeft: '5px',
            }}/>
          </Tooltip>
        </div>
      </div>
    </div>
  );
};

export default DeckManagerColorFilters;