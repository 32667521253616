import React from 'react';
import { Store } from 'pullstate';
import { BattlefieldLocations, CardData, FacedownCard } from '../../../shared/types';

export const CarouselStoreContext = React.createContext<Store<CarouselStore>>({} as Store<CarouselStore>);

export enum CarouselId {
  Main = 'Main',
  Popup = 'Popup',
};

export type CarouselStore = {
  // used to force a state reset when a card is dropped into the carousel,
  // even if it's the same card that was already there in the same place as before.
  cardWasPlacedInSameSpot: boolean;
  // used to delay resetting state until server update arrives, to avoid flicker
  waitingForUpdate: boolean;
  isDrawerOpen: boolean;
  // the data source for the pop-up carousel
  popupCarouselContent: BattlefieldLocations | null;
  isViewingLibraryFacedown: boolean;
  // the pre-drag location of the card we're currently dragging or hovering
  activeCardOrigin: BattlefieldLocations | null;
  isDraggingCard: boolean;
  previewIsVisible: boolean;
  cardData: CardData | FacedownCard | null;
  // where we are when hovering a card over the carousel
  carouselHoverIndex: number | null;
  // which carousel we're hovering over (if any) while dragging a card around
  hoveredCarouselId: CarouselId | null;
  previewPosition: {
    left: number;
    bottom: number | string;
  };
};

export const createCarouselStore = () => {
  const carouselStore = new Store<CarouselStore>({
    cardWasPlacedInSameSpot: false,
    waitingForUpdate: false,
    isDrawerOpen: false,
    popupCarouselContent: null,
    isViewingLibraryFacedown: false,
    cardData: null,
    activeCardOrigin: null,
    previewIsVisible: false,
    isDraggingCard: false,
    carouselHoverIndex: null,
    hoveredCarouselId: null,
    previewPosition: {
      left: 0,
      bottom: 0,
    },
  });

  return carouselStore;
};