import React, { useEffect, useMemo } from 'react';
import PlayArea from './components/PlayArea';
import { createStoreForGameId, GameStoreContext } from './shared/GameStore';
import BattlefieldApi from './utils/BattlefieldApi';
import LeftSidebar from './components/LeftSidebar';
import CarouselDrawer from './components/CarouselDrawer';
import { createCarouselStore, CarouselStoreContext } from './shared/CarouselStore';
import { createUiStateStore, UiStateStoreContext } from './shared/UiStateStore';
import ContextMenu from './components/ContextMenu';
import { dismissContextMenu } from './components/ContextMenu/ContextMenu';
import { setKeyboardShortcuts, setOnFocusLossHandlers, setupUiStateStore } from './utils/InitializationUtils';
import RightSidebar from './components/RightSidebar';
import { AppStore } from '../../shared/AppStore';
import './Battlefield.less';

const Battlefield = () => {
  // docs say memoization isn't guaranteed here - might need to re-examine later
  const gameStore = useMemo(() => createStoreForGameId(), []);
  const carouselStore = useMemo(() => createCarouselStore(), []);
  const uiStateStore = useMemo(() => createUiStateStore(), []);

  const lockedZone = gameStore.useState(s => s.privateCardData.lockedZone);
  const initialLoadComplete = gameStore.useState(s => s.initialLoadComplete);
  const uiStateStoreReady = uiStateStore.useState(s => s.initComplete);
  const otherPlayerUserId = uiStateStore.useState(s => s.currentOtherPlayerId);

  const userSettings = AppStore.useState(s => s.userData.userSettings);

  useEffect(() => {
    uiStateStore.update(s => { s.lockedZone = lockedZone; });
  }, [lockedZone]);

  useEffect(() => {
    if (userSettings?.keyboardShortcuts) {
      setKeyboardShortcuts(userSettings!, uiStateStore, gameStore, carouselStore);
    }
  }, [userSettings]);

  useEffect(() => {
    if (initialLoadComplete) {
      setupUiStateStore(otherPlayerUserId, lockedZone, uiStateStore, gameStore);
    }
  }, [initialLoadComplete]);

  // load game data from server and update the store whenever that data changes
  useEffect(() => {
    BattlefieldApi.linkStoreToServer(gameStore);
    // in case you are looking at someone's hand and refresh, we just free it immediately
    // when the page reloads and this code executes
    BattlefieldApi.releaseAllLockedZones();
    setOnFocusLossHandlers(uiStateStore);

    // primarily for if someone swipes to the side too far on a mac when
    // trying to side scroll the card carousel
    window.onbeforeunload = function() {
      // You can't actually customize the message, it will always say:
      // "Changes you made may not be saved." Whatever.
      return 'Are you sure you want to leave?';
    };
  }, []);

  // There's a bunch of stuff throughout the app that depends on this, we should really extract it all and clean it all up so it's dealt with in one place at the top level.
  if (!initialLoadComplete || !uiStateStoreReady) return null;

  return (
    <GameStoreContext.Provider value={gameStore}>
      <CarouselStoreContext.Provider value={carouselStore}>
        <UiStateStoreContext.Provider value={uiStateStore}>
          <div className="battlefield" onMouseDown={() => {
            dismissContextMenu(uiStateStore, carouselStore);
          }}>
            <LeftSidebar/>

            <PlayArea/>
            <CarouselDrawer/>

            <RightSidebar/>
          </div>
          <ContextMenu/> {/* one context menu to rule them all */}
        </UiStateStoreContext.Provider>
      </CarouselStoreContext.Provider>
    </GameStoreContext.Provider>
  );
};

export default Battlefield;