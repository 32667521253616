import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getAuth } from 'firebase/auth';
import { socket } from '../../../../shared/SocketInit';
import { ServerNotifications } from '../../../../shared/SocketEnums';
import { WaitingRoom } from '../../../../shared/types';
import LobbyApi from '../../shared/LobbyApi';
import CurrentWaitingRoom from '../CurrentWaitingRoom';
import RoomsListControls from '../RoomsListControls';
import WaitingRoomListItem from '../WaitingRoomListItem';
import RoomsListEmptyState from './RoomsListEmptyState';
import { filterRooms } from './RoomsListUtils';
// import { dummyData } from './dummyData';
import { AppStore } from '../../../../shared/AppStore';
import './RoomsList.less';

const RoomsList = () => {
  const currentRoom = AppStore.useState(s => s.currentRoom);// || dummyData[0];
  const { formatFilter, titleFilter } = AppStore.useState(s => s.roomsList);

  const navigate = useNavigate();

  const userId = getAuth().currentUser?.uid;

  const [rooms, setRooms] = useState<WaitingRoom[]>([]);
  const [loading, setLoading] = useState(true);

  const roomUpdateListener = (roomUpdate: WaitingRoom) => {
    if (!roomUpdate) {
      // we left the room
      AppStore.update(s => { s.currentRoom = null; });
      return;
    }

    const hasBecomeHost = currentRoom && currentRoom.hostUserId !== userId
      && roomUpdate.hostUserId === userId;

    if (hasBecomeHost) alert('The original host has left. You are now the host.');

    AppStore.update(s => { s.currentRoom = roomUpdate; });

    // if we've launched for the first time, navigate to /game.
    // but if we had already launched and the user has returned to the lobby for whatever reason
    // without exiting the game, don't force them back to the game on subsequent unrelated updates.
    if (roomUpdate.gameLaunched && !currentRoom?.gameLaunched) {
      navigate('/game');
    }
  };

  // get updates on the waiting room we're in
  useEffect(() => {
    socket.on(ServerNotifications.CURRENT_ROOM_UPDATED, roomUpdateListener);

    return () => {
      socket.off(ServerNotifications.CURRENT_ROOM_UPDATED, roomUpdateListener);
    };
  }, [currentRoom]);

  // fetch initial data on page load and get updates for the list of waiting rooms
  useEffect(() => {
    LobbyApi.loadWaitingRooms(setRooms, setLoading, AppStore);

    socket.on(ServerNotifications.WAITING_ROOMS_UPDATED, setRooms);

    return () => {
      socket.off(ServerNotifications.WAITING_ROOMS_UPDATED, setRooms);
    };
  }, []);

  const processedRooms = filterRooms(rooms, currentRoom, formatFilter, titleFilter);

  const showEmptyState = !loading && rooms.length === 0;
  const roomsListClasses = `roomsList ${showEmptyState ? 'emptyState' : ''} ${currentRoom ? 'halfList' : ''}`;

  return (
    <>
      <RoomsListControls/>

      <div className={roomsListClasses}>
        {/* {dummyData.map(room => { */}
        {processedRooms.map(room => {
            return (
              <WaitingRoomListItem key={room.id} room={room} currentRoom={currentRoom} />
            );
        })}
        {showEmptyState && <RoomsListEmptyState/>}
      </div>

      <CurrentWaitingRoom/>
    </>
  );
};

export default RoomsList;