import { Store } from 'pullstate';
import { CardData, CardInPlay, DropEventData } from '../../../../shared/types';
import { copyCardData, vhToPixels } from '../../../../shared/utils';
import { UiStateStore } from '../../shared/UiStateStore';
import { uniq } from 'lodash';
import BattlefieldApi from '../../utils/BattlefieldApi';
import { GameStore } from '../../shared/GameStore';

export const setMagnifiedCardDetails = (
  card: CardInPlay | CardData | null,
  cardRef: any,
  uiStateStore: Store<UiStateStore>,
  options?: {
    noDelay?: boolean,
    onLeft?: boolean,
  },
) => {
  if (!card) {
    uiStateStore.update(s => {
      s.magnifiedCard.position = null;
      s.magnifiedCard.cardData = null;
    });
    return;
  }

  const boundingBox = cardRef.current.getBoundingClientRect();
  const midpointY = boundingBox.bottom - boundingBox.height / 2;
  const midpointX = boundingBox.left + boundingBox.width / 2;

  uiStateStore.update(s => {
    s.magnifiedCard.position = { x: midpointX, y: midpointY };
    s.magnifiedCard.cardData = copyCardData(card);

    if (options?.onLeft) {
      s.magnifiedCard.onLeft = true;
    }

    // we don't delay when hovering decklist items in DecklistDialog
    if (options?.noDelay) {
      s.magnifiedCard.shouldShow = true;
    } else {
      s.magnifiedCard.timeout = setTimeout(() => {
        uiStateStore.update(s => {
          s.magnifiedCard.shouldShow = true;
        });
      }, 300);
    }
    // as a side action we set the hovered card to be the keyboard shortcut target
    s.keyboardShortcutTarget.hoveredCardId = card.id;
  });
};

export const cancelMagnification = (
  uiStateStore: Store<UiStateStore>,
) => {
  uiStateStore.update(s => {
    clearTimeout(s.magnifiedCard.timeout as any);

    s.magnifiedCard.cardData = null;
    s.magnifiedCard.timeout = null;
    s.magnifiedCard.position = null;
    s.magnifiedCard.shouldShow = false;
    s.magnifiedCard.onLeft = false;
    // as a side action we clear the hovered card as the keyboard shortcut target
    s.keyboardShortcutTarget.hoveredCardId = null;
  });
};

export const nullifyUiStoreSelectionState = (
  uiStateStore: Store<UiStateStore>
) => {
  uiStateStore.update(s => {
    s.keyboardShortcutTarget.selectedCardIds = null;
    s.dragSelectedCards = {};
  });
};

// This is a sort of catch-all for anything that might trigger a bug in which a card
// in play becomes duplicated. No two cards should ever have the same id, not even if
// you clone one, so if there are EVER two with same id, we immediately tell the server
// to update gamestate without the duplicate(s), because not doing so can lead to total
// interface collapse since so much depends on the uniqueness of each card's id attribute.
export const hasDuplicates = (
  incomingMyCardsInPlay: CardInPlay[],
) => {
  const cardIds = incomingMyCardsInPlay.map(card => card.id);
  const uniqueCardIds = uniq(cardIds);

  if (cardIds.length !== uniqueCardIds.length) {
    const alreadySeen: { [cardId: string]: boolean | undefined } = {};
    const uniqueCards: CardInPlay[] = [];

    incomingMyCardsInPlay.forEach(card => {
      if (!alreadySeen[card.id]) {
        alreadySeen[card.id] = true;
        uniqueCards.push(card);
      }
    });

    BattlefieldApi.moveCardsWithinPlayArea(uniqueCards);

    return true;
  }

  return false;
};

// Used to sort otherCardsInPlay so they're always in the same order.
// This allows react to keep things continuous instead of unmounting
// and remounting cards, which interferes with the movement animations.
export const cardComparator = (a: CardInPlay, b: CardInPlay) => {
  if (a.id < b.id) return -1;
  return 1;
};

export const removeDuplicates = (cards: CardInPlay[]) => {
  const uniqueIds = new Set();
  const result = [];

  for (const card of cards) {
    if (!uniqueIds.has(card.id)) {
      uniqueIds.add(card.id);
      result.push(card);
    }
  }

  return result;
};

// Determine whether a card that's been dropped in play is trespassing.
// This is separate from the util that shows the trespass overlay while dragging,
// because this event is triggered from a different point in the code with different information.
export const checkToConfirmTrespassingOnDrop = (dropData: DropEventData) => {
  const theirPlayAreaRect = document.querySelector('.theirPlayArea')!.getBoundingClientRect();
  // all cards in the UI are 14vh tall while dragging
  const isTrespassing = (dropData.top + (0.5 * vhToPixels(14))) < theirPlayAreaRect.bottom + 2; // the +2px is a little buffer that smooothes out triggering this correctly

  return isTrespassing;
};

// Determine whether a single card that's been moved within the play area is trespassing.
// This is also called for each individual card in a moved drag-selected group, because although
// you can't _start_ a trespass that way, you can move cards _out_ of trespassing that way.
export const updateTrespassingStatusForSingleCard = (
  topAsPercent: number,
  card: CardInPlay,
  uiStateStore: Store<UiStateStore>,
  blockTrespassStart?: boolean,
) => {
  const playAreaRect = document.querySelector('.playArea')!.getBoundingClientRect();
  const playAreaHeight = playAreaRect.height;
  const topInPixels = (topAsPercent / 100) * playAreaHeight;

  const theirPlayAreaRect = document.querySelector('.theirPlayArea')!.getBoundingClientRect();
  // all cards in the UI are 14vh tall while dragging
  const isTrespassing = (topInPixels + (0.5 * vhToPixels(14))) < theirPlayAreaRect.bottom;
  const wasTrespassing = !!card.isTrespassingOnUserId;

  // If they were trespassing already, or just started, either way we need to updateTrespassers,
  // because it could be that they're just moving an aura from one permanent to another within 
  // that person's side of the battlefield.
  // So possibly the trespassing status is unchanged, but the card's data must be updated
  // just the same.
  //
  // Secondly, the point of `blockTrespassStart` is that we only allow starting trespass by
  // moving a single card into their side of play. But we have to call this when they dragselect 
  // multiple cards too, because if they're ALREADY trespassing and they dragselect to move them,
  // it would be really counterintuitive if it didn't work. BUT we don't want to allow them to
  // INITIATE a trespass with multiple drag-selected cards. So for the case of multiple cards,
  // we only update trespassers if they were *already* trespassing - otherwise, we do nothing.
  if (!blockTrespassStart) {
    if (isTrespassing || wasTrespassing) {
      const otherPlayerId = uiStateStore.getRawState().currentOtherPlayerId;
      BattlefieldApi.updateTrespassers([card.id], isTrespassing, otherPlayerId);
    }
  } else {
    if (wasTrespassing) {
      const otherPlayerId = uiStateStore.getRawState().currentOtherPlayerId;
      BattlefieldApi.updateTrespassers([card.id], isTrespassing, otherPlayerId);
    }
  }
};

// Determines whether or not to show the trespassing overlay while dragging a card over play.
export const checkToShowTrespassingOverlay = (cardBeingDragged: any, uiStateStore: Store<UiStateStore>) => {
  const theirPlayAreaRect = document.querySelector('.theirPlayArea')!.getBoundingClientRect();
  const cardRect = cardBeingDragged.getBoundingClientRect();
  const isVerticallyOnTheirSide = (cardRect.bottom - (0.5 * cardRect.height))
    < theirPlayAreaRect.bottom;
  const isHorizontallyWithinPlayArea = (cardRect.left >= theirPlayAreaRect.left) && (cardRect.right <= theirPlayAreaRect.right);
  const isTrespassing = isVerticallyOnTheirSide && isHorizontallyWithinPlayArea;

  uiStateStore.update(s => {
    s.isTrespassing = isTrespassing;
  });
};