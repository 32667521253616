import React, { useContext, useEffect } from 'react';
import { percentPixelsOfWindowHeight, removeCardsOrReturnToOriginalLocation } from '../../../../../../shared/utils';
import { CarouselId, CarouselStoreContext } from '../../../../shared/CarouselStore';
import { onPreviewDragEnd } from '../../CarouselUtils';
import { CardData, LEFT_MOUSE } from '../../../../../../shared/types';
import { UiStateStoreContext } from '../../../../shared/UiStateStore';
import './DraggablePreview.less';

const DraggablePreview = () => {
  const carouselStore = useContext(CarouselStoreContext);
  const uiStateStore = useContext(UiStateStoreContext);
  const { isDrawerOpen, isDraggingCard, previewIsVisible, previewPosition, cardData,
    activeCardOrigin, hoveredCarouselId } = carouselStore.useState();
  const flippedCardIds = uiStateStore.useState(s => s.flippedCardIds);

  // whenever this unmounts, clear out the keyboard shortcut target
  useEffect(() => {
    return () => {
      uiStateStore.update(s => {
        s.keyboardShortcutTarget.hoveredCardId = null;
      });
    };
  }, []);

  if (!cardData) return null;

  const isFacedown = !cardData.url;

  const onMouseUp = (mouseUpEvent: any) => {
    if (mouseUpEvent.button !== LEFT_MOUSE) return;

    mouseUpEvent.preventDefault();
    mouseUpEvent.persist();

    // timeout to ensure it happens after the eventhandler from mouseMove that sets it to true
    setTimeout(() => {
      uiStateStore.update(s => {
        s.isTrespassing = false;
      });
    }, 40);

    if (!isDraggingCard) return;

    removeCardsOrReturnToOriginalLocation(
      mouseUpEvent,
      activeCardOrigin!,
      // This may be FacedownCard instead of CardData, but it doesn't matter
      // because the downstream code checks and acts accordingly.
      [ cardData as CardData ],
      // On an InvalidDrop, we reset the carousel immediately.
      // But on a successful drop, we set it waiting and then when the new
      // state update arrives from the server, a useEffect trigger in CardCarousel resets
      // carousel state in response. This used to be a setTimeout, but doing it this way
      // prevents any flickering if the game update arrives after the carousel reset.
      // Separately, the logic to handle cards being added somewhere and removed elsewhere
      // is handled by the dropzone, not these handlers.
      () => onPreviewDragEnd(carouselStore, true),
      () => onPreviewDragEnd(carouselStore, false),
    );
  };

  const classList = 'draggablePreview '
  + (previewIsVisible || ' hidden') // this is adding the string 'true' into the classlist btw, bug
  + (isDraggingCard ? ' dragSize' : ' previewSize')
  + (isFacedown ? ' facedownPreview' : '');

  /* The CarouselCard is statically positioned, while the DraggablePreview is 
  absolutely positioned. So when the initial location is measured in showCardPreview for 
  the card being hovered, that is relative to the window. But the absolute
  positioning is relative to the drawer instead of the window.
  
  So we need this offset here to counteract the offset distance.
  (Separately btw remember that translateXYZ counts as giving something position:relative.) */
  const sidebarOffset = document.querySelector('.leftSidebar')!
    .getBoundingClientRect().width;

  // matching the css in DraggablePreview.less of height: 30vh / min-height: 300px
  const previewHeight = Math.max(percentPixelsOfWindowHeight(30), 300);

  const left = isDraggingCard ?
    `calc(${previewPosition.left - sidebarOffset}px - 14vh / 1.4 / 2)`
    : previewPosition.left - sidebarOffset - (previewHeight / 1.4 / 2);

  const bottom = isDraggingCard ? previewPosition.bottom
   : (isDrawerOpen && hoveredCarouselId === CarouselId.Main) ? '20vh' : '2vh';

  const showAltFace = !!cardData.altFaceUrl && !!flippedCardIds[cardData.id];
  const faceUpImageUrl = showAltFace ? cardData.altFaceUrl : cardData.url;
  const backgroundImage = isFacedown ? '' : `url("${faceUpImageUrl}")`;

  const style = {
    backgroundImage,
    left,
    bottom,
    pointerEvents: (isDraggingCard ? 'all' : 'none') as any
  };

  return (
    <div
      className={classList}
      style={style}
      onMouseUp={onMouseUp}
    />
  );
};

export default DraggablePreview;