import { KeyboardActions } from '../components/shared/SettingsPanel/keyboardShortcutConstants';

export type ColorAttributes = {
  red: boolean;
  blue: boolean;
  black: boolean;
  white: boolean;
  green: boolean;
  colorless: boolean;
};

export type Deck = {
  id: string;
  name: string;
  format: GameFormat;
  decklist: DecklistItem[];
  sideboard: DecklistItem[];
  colors: ColorAttributes;
};

export type CardData = {
  id: string;
  name: string;
  url: string;
  altFaceUrl?: string;
  showAltFace: boolean;
  // used for inserting a ghost card in CardCarousel while hovering a dragged card
  isGhost?: boolean;
  gathererUrl: string;
  cmc: number;
  type: string;
};

export type FacedownCard = Partial<CardData> & {
  id: string;
};

export type CardInPlay = CardData & {
  ref: any; // this is not stored on backend - no need and can't be serialized anyway
  isFacedown: boolean;
  isRevealedToOwner: boolean;
  isToken: boolean;
  left: number;
  top: number;
  zIndex: number;
  originalLeft: number;
  originalTop: number;
  tapped: boolean;
  showPTModifier: boolean;
  powerModifier: number;
  toughnessModifier: number;
  // Not a boolean because if we want to highlight twice in a row, going from true -> true
  // is not helpful. so instead the frontend checks if they uuid has changed -
  // same principle as cachebusting. If the value is non-null and has changed, then highlight.
  isHighlighted: string | null;
  isDragging?: boolean;
  isTrespassingOnUserId: string | null;
};

export type ChatMessage = {
  userId: string;
  message: string;
  publicMessage?: string;
  displayToSenderAs?: string;
  time: number;
  id: string;
  restrictedTo?: string[]; // userIds for players who should be able to see message
  senderId?: string;
};

// used for consolidating consecutive messages from same user
export type ChatMessageSeries = {
  id: string;
  userId: string;
  messages: ChatMessage[];
  isActionLog: boolean;
};

// these match the css class names in PlayAreaCounter.less
export enum CounterColor {
  Red = 'red',
  Orange = 'orange',
  Yellow = 'yellow',
  Blue = 'blue',
  BlueDark = 'blue-dark',
  Green = 'green',
  GreenDark = 'green-dark',
  Teal = 'teal',
  Purple = 'purple',
  Pink = 'pink',
  Gold = 'gold',
  Silver = 'silver',
};

export type Counter = {
  id: string;
  ownerId: string; // the userId of the player who created it.
  label: string;
  color: CounterColor;
  value: number;
  zIndex: number;
  left: number;
  top: number;
  ref?: any;
};

export type DropEventData = {
  mouseUpEvent: any;
  cards: CardData[]; // the cards being transferred in the drop
  cameFrom: BattlefieldLocations;
  left: number; // absolute distance from left edge of page in px
  top: number; // absolute distance from top edge of page in px
  newCardsInOrigin: CardData[] | CardInPlay[]; // what the origin's contents will look like if the drop is successful
};

export type DropReceivedEventData = {
  cardIds: string[];
  cameFrom: BattlefieldLocations;
  receivedBy: BattlefieldLocations;
};

export enum BattlefieldLocations {
  Hand = 'Hand',
  Library = 'Library',
  PlayArea = 'PlayArea',
  Graveyard = 'Graveyard',
  Exile = 'Exile',
  OtherPlayerHand = 'OtherPlayerHand',
  OtherPlayerHandCarousel = 'OtherPlayerHandCarousel',
  OtherPlayerLibrary = 'OtherPlayerLibrary',
  OtherPlayerLibraryCarousel = 'OtherPlayerLibraryCarousel',
  OtherPlayerGraveyard = 'OtherPlayerGraveyard',
  OtherPlayerExile = 'OtherPlayerExile',
  // these types represent the piles when opened in the popup carousel
  GraveyardCarousel = 'GraveyardCarousel',
  ExileCarousel = 'ExileCarousel',
  LibraryCarousel = 'LibraryCarousel',
  TopNOfLibrary = 'TopNOfLibrary',
};

export enum ContextMenuInstanceSources {
  PlayAreaCard = 'PlayAreaCard',
  PlayAreaCounter = 'PlayAreaCounter',
  CarouselCard = 'CarouselCard',
  OtherPlayerPlayAreaCard = 'OtherPlayerPlayAreaCard',
  CounterButton = 'CounterButton',
};

export type ContextMenuSource = BattlefieldLocations | ContextMenuInstanceSources;

export enum Topics {
  // drag+drop topics
  CardsDropped = 'CardsDropped',
  DroppedCardsReceived = 'DroppedCardsReceived',
  CardsDroppedElsewhere = 'CardsDroppedElsewhere',
  InvalidDropLocation = 'InvalidDropLocation',
  CardIsBeingDragged = 'CardIsBeingDragged',
  // request topics
  RequestReceived = 'RequestReceived',
  RequestOutcomeReceived = 'RequestOutcomeReceived',
  // settings topics
  SaveSettingsChanges = 'SaveSettingsChanges',
  DiscardSettingsChanges = 'DiscardSettingsChanges',
  // keyboard shortcut topics
  CardsMovedByShortcut = 'CardsMovedByShortcut',
  CardsCreatedInPlay = 'CardsCreatedInPlay',
  CycleNextPlayer = 'CycleNextPlayer',
  CyclePrevPlayer = 'CyclePrevPlayer',
  IncrementLifeCounter = 'IncrementLifeCounter',
  DecrementLifeCounter = 'DecrementLifeCounter',
  IncrementSecondaryLifeCounter = 'IncrementSecondaryLifeCounter',
  DecrementSecondaryLifeCounter = 'DecrementSecondaryLifeCounter',
  IncrementLifeCounterBy5 = 'IncrementLifeCounterBy5',
  DecrementLifeCounterBy5 = 'DecrementLifeCounterBy5',
  IncrementSecondaryLifeCounterBy5 = 'IncrementSecondaryLifeCounterBy5',
  DecrementSecondaryLifeCounterBy5 = 'DecrementSecondaryLifeCounterBy5',
};

export type MousePosition = {
  x: number;
  y: number;
};

export enum WaitingRoomExperienceLevel {
  Beginner = 'Beginner',
  Casual = 'Casual',
  Competitive = 'Competitive',
};

export enum GameFormat {
  Any = 'Any',
  EDH = 'EDH',
  Legacy = 'Legacy',
  Modern = 'Modern',
  Pauper = 'Pauper',
  Pioneer = 'Pioneer',
  Standard = 'Standard',
  Vintage = 'Vintage',
};

export enum PlayerColors {
  Red = 'Red',
  Blue = 'Blue',
  Green = 'Green',
  Cyan = 'Cyan',
  Yellow = 'Yellow',
  Pink = 'Pink',
  Orange = 'Orange',
  Magenta = 'Magenta',
};

export type WaitingRoom = {
  id: string; // uuid
  title: string;
  hostUserId: string;
  experienceLevel: WaitingRoomExperienceLevel;
  gameFormat: GameFormat;
  gameLaunched: boolean;
  userIds: string[];
  usernamesByUserId: { [ userId: string ]: string };
  playerStatuses: PlayerStatuses;
  colorsByUserId: { [ userId: string ]: PlayerColors };
  gravatarHashes: {
    [userId: string]: string;
  };
  // password: string || null; // exists on BE; isn't used on FE
  hasPassword: boolean;
  // on FE, used for displaying whether players have selected a deck yet
  deckIdsByUserId: { [ userId: string ]: string };
  maxPlayers: number;
  gameId: string | null;
  mirrorMode: boolean;
  chat: ChatMessage[];
};

export type DecklistItem = {
  name: string;
  url: string;
  altFaceUrl?: string;
  quantity: number;
  gathererUrl: string;
  cmc: number;
  type: string;
  colors: string[];
};

export enum AccessRequestLocationOptions {
  Hand = 'Hand',
  Library = 'Library',
  TopNOfLibrary = 'TopNOfLibrary',
};

export type RevealedOpponentCards = {
  ownerId: string;
  origin: AccessRequestLocationOptions;
  cards: CardData[];
};

export type PublicCardData = {
  [playerId: string]: {
    playArea: CardInPlay[];
    exile: CardData[];
    graveyard: CardData[];
    handSize: number;
    librarySize: number;
    revealedTopCardOfLibrary: CardData | null;
  };
};

export type PrivateCardData = {
  // the frontend doesn't actually need to distinguish between "starting" and "current"
  // like the backend does, but we want to keep parity between the FE and BE types anyway
  startingDecklist: DecklistItem[];
  startingSideboard: DecklistItem[];
  currentDecklist: DecklistItem[];
  currentSideboard: DecklistItem[];
  hand: CardData[];
  lockedZone: AccessRequestLocationOptions | null;
  revealedOpponentCards: RevealedOpponentCards | null;
  revealedTopCardOfLibrary: CardData | null;
  topNOfLibrary: CardData[] | null;
  revealedLibrary: CardData[] | null;
  facedownLibrary: FacedownCard[]; // this isn't really "private" but it was the closest match
};

export enum ConnectionStatus {
  Connected = 'Connected',  // green dot in UI
  TemporarilyDisconnected = 'TemporarilyDisconnected',  // yellow dot in UI
  Exited = 'Exited',  // grey dot in UI
};

export type PlayerStatuses = {
  [userId: string]: ConnectionStatus;
};

export type GameState = {
  startTime: number;
  playerStatuses: PlayerStatuses;
  mirrorMode: boolean;
  chat: ChatMessageSeries[];
  lifeTotals: {
    [playerId: string]: {
      primary: number;
      secondary: number;
    };
  };
  otherPlayerIds: string[];
  publicCounterData: Counter[];
  publicCardData: PublicCardData;
  privateCardData: PrivateCardData;
};

// the waitingRoom isn't really part of the gamestate but it's part of the response
// (the battlefield uses it to look up usernames by userId)
export type GameStateResponse = GameState & { waitingRoom: WaitingRoom };

export type ErrorResponse = {
  error: string;
};

export type RequestDetails = {
  requestId: string;
  message: string;
};

export enum RequestType {
  CardControlRequest = 'CardControlRequest',
  LookAtCardsRequest = 'LookAtCardsRequest',
};

export type RequestOutcome = {
  requestType: RequestType;
  granted: boolean;
  message: string;
  requestedLocation?: AccessRequestLocationOptions;
};

export enum LifeCounterId {
  PrimaryCounter = 1,
  SecondaryCounter = 2,
};

export type UserSettings = {
  keyboardShortcuts: {
    -readonly [key in keyof typeof KeyboardActions]: string[];
  };
};

export enum DieVariant {
  D6 = 'D6',
  D20 = 'D20',
  Coin = 'Coin',
};

export const DRAG_ZINDEX = 9_000_000;

export const LEFT_MOUSE = 0;
export const RIGHT_MOUSE = 2;

export const ENTER_KEY = 13;

export const GRAVATAR_BASE_URL = 'https://www.gravatar.com/avatar/%s';

export const formatAbbreviations = {
  [GameFormat.Any]: 'Any',
  [GameFormat.EDH]: 'EDH',
  [GameFormat.Legacy]: 'LGCY',
  [GameFormat.Modern]: 'MDRN',
  [GameFormat.Pauper]: 'PPR',
  [GameFormat.Pioneer]: 'PNR',
  [GameFormat.Standard]: 'STD',
  [GameFormat.Vintage]: 'VTG',
};

export enum ColorFilterStrategies {
  Exactly = 'Exactly',
  Including = 'Including',
  AtMost = 'At Most',
};