import React from 'react';
import { getAuth } from 'firebase/auth';
import TopBar from '../shared/TopBar';
import AccountPage from './components/AccountPage';
import DeckManager from './components/DeckManager';
import LobbyNavigation from './components/LobbyNavigation';
import RoomsList from './components/RoomsList';
import SettingsPage from './components/SettingsPage';
import './Lobby.less';

type LobbyProps = {
  path: string;
};

const Lobby = ({ path }: LobbyProps) => {
  const auth = getAuth();
  const currentUser = auth.currentUser;

  let lobbyContent;
  switch(path) {
    case '/account':
      if (currentUser) {
        lobbyContent = <AccountPage/>;
      } else {
        window.location = '/' as any;
      }
      break;
    case '/settings':
      if (currentUser) {
        lobbyContent = <SettingsPage/>;
      } else {
        window.location = '/' as any;
      }
      break;
    case '/decks':
      lobbyContent = <DeckManager/>;
      break;
    case '/':
    default:
      lobbyContent = <RoomsList/>;
      break;
  }

  return (
    <>
      <TopBar/>
      <div className="lobbyContainer">
        <div/>

        <div className="leftSidebarContainer">
          <LobbyNavigation/>
        </div>

        <div className="centerContentContainer">
          {lobbyContent}
        </div>

        <div className="rightSidebarContainer"/>

        <div/>

      </div>
    </>
  );
};

export default Lobby;