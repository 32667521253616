import React, { useContext, useEffect, useState } from 'react';
import SettingsPanel from '../../../shared/SettingsPanel';
import { Alert, Button, Snackbar } from '@mui/material';
import { Topics } from '../../../../shared/types';
import { AppStore } from '../../../../shared/AppStore';
import './SettingsPage.less';

const SettingsPage = () => {
  const { showSaveSuccess, showDiscardSuccess } = AppStore.useState(s => s.settingsChanges);
  const [showSaveChanges, setShowSaveChanges] = useState(false);

  useEffect(() => {
    return () => {
      AppStore.update(s => {
        // hide these on unmount so it doesn't keep showing up if you click back and forth
        s.settingsChanges.showSaveSuccess = false;
        s.settingsChanges.showDiscardSuccess = false;
      });
    }
  }, []);

  // So the actual "newSettings" that are actively being updated are contained
  // in the SettingsPanel component. That component alone has that information.
  // But the snackbar has to be up here for document hierarchy reasons to do with
  // styling, so that it's displayed at the top lavel relative to the viewport.
  // So we trigger save/discard here, and listen for the events in the SettingsPanel,
  // where we actually handle the save/discard and then update AppStore to show a
  // success notification.
  const onSave = () => {
    PubSub.publish(Topics.SaveSettingsChanges);
  };

  const onDiscard = () => {
    PubSub.publish(Topics.DiscardSettingsChanges);
  };

  const closeSuccessAlerts = () => {
    AppStore.update(s => {
      s.settingsChanges.showSaveSuccess = false;
      s.settingsChanges.showDiscardSuccess = false;
    });
  };

  return (
    <>
      <div className="settingsContainer">
        <SettingsPanel setShowSaveChanges={setShowSaveChanges}/>
      </div>

      <Snackbar
        className="saveSettingsChangesSnackbar"
        open={showSaveChanges}
        onClose={() => {}}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
        <Alert onClose={() => {}} severity="info" sx={{ width: '100%' }}>
          You have unsaved changes.

          <Button className="saveChangesButton" variant="contained" onClick={onSave}>
            Save
          </Button>

          <Button className="discardChangesButton" variant="outlined" color="error" onClick={onDiscard}>
            Discard
          </Button>
        </Alert>
      </Snackbar>

      <Snackbar
        open={showSaveSuccess}
        autoHideDuration={6000}
        onClose={closeSuccessAlerts}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <Alert onClose={closeSuccessAlerts} severity="success" sx={{ width: '100%' }}>
          Changes saved.
        </Alert>
      </Snackbar>

      <Snackbar
        open={showDiscardSuccess}
        autoHideDuration={6000}
        onClose={closeSuccessAlerts}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <Alert onClose={closeSuccessAlerts} severity="info" sx={{ width: '100%' }}>
          Changes discarded.
        </Alert>
      </Snackbar>
    </>
  );
};

export default SettingsPage;