import React from 'react';
import { Button } from '@mui/material';
import { GameFormat } from '../../../../../shared/types';
import CreateDeckDialog from './CreateDeckDialog';
import EditDeckDialog from './EditDeckDialog';
import { Select } from 'react-functional-select';
import DeckManagerColorFilters from './DeckManagerColorFilters';
import { AppStore } from '../../../../../shared/AppStore';
import './DeckManagerControls.less';

const DeckManagerControls = () => {
  const { formatFilter, nameFilter, signedIn } = AppStore.useState(s => {
    return {
      formatFilter: s.deckManager.formatFilter,
      nameFilter: s.deckManager.nameFilter,
      signedIn: s.signedIn,
    };
  });

  const showCreateDeckDialog = () => {
    if (!signedIn) {
      alert('You are logged out. Log back in and try again.');
      return;
    }
    AppStore.update(s => { s.createDeckDialog.isOpen = true; });
  };

  return (
    <div className="deckManagerControls">
      <CreateDeckDialog/>
      <EditDeckDialog/>

      <Button variant="contained" className="createDeckButton"
        onClick={showCreateDeckDialog}>
        Create Deck
      </Button>

      <DeckManagerColorFilters/>

      <input
        className="nameFilter"
        onChange={(event) => {
          AppStore.update(s => { s.deckManager.nameFilter = event.target.value; })
        }}
        type="text"
        value={nameFilter}
        placeholder="Filter decks by name"
      />

      <Select
        initialValue={[formatFilter]}
        options={Object.values(GameFormat)}
        onOptionChange={val => { AppStore.update(s =>  {
          s.deckManager.formatFilter = val;
        })}}
        getOptionValue={opt => opt}
        getOptionLabel={opt => opt}
        isSearchable={false}
      />
    </div>
  );
};

export default DeckManagerControls;