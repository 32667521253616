import React, { useContext } from 'react';
import { CardData, ContextMenuInstanceSources, FacedownCard } from '../../../../../../shared/types';
import { CarouselId, CarouselStoreContext } from '../../../../shared/CarouselStore';
import { UiStateStoreContext } from '../../../../shared/UiStateStore';
import { showCardPreview } from '../DraggablePreview/DraggablePreviewUtils';
import './CarouselCard.less';
import { GameStoreContext } from '../../../../shared/GameStore';

type CarouselCardProps = {
  card: CardData | FacedownCard;
  index: number;
  carouselId: CarouselId;
};

const CarouselCard = ({ card, index, carouselId }: CarouselCardProps) => {
  const carouselStore = useContext(CarouselStoreContext);
  const uiStateStore = useContext(UiStateStoreContext);
  const gameStore = useContext(GameStoreContext);

  const isDraggingCard = carouselStore.useState(s => s.isDraggingCard);
  const contextMenuIsOpenOnCarousel = uiStateStore.useState(s => s.contextMenu.source === ContextMenuInstanceSources.CarouselCard);
  const flippedCardIds = uiStateStore.useState(s => s.flippedCardIds);

  // if the url is defined, we're face-up. if not, we're face-down.
  // and if somehow we SHOULD be face-up but got no url, we show a face-down card
  // as a fallback.
  const isFacedown = !card.url;

  // mouseMove instead of mouseEnter/Over so that right after dropping a card into the
  // hand, the card your mouse is on after the drop is draggable without having
  // to leave and re-enter
  const onMouseMove = (mouseMoveEvent: any) => {
    if (!isDraggingCard && !contextMenuIsOpenOnCarousel) {
      showCardPreview(mouseMoveEvent, card, index, carouselId, carouselStore, uiStateStore, gameStore);
    }
  };

  const showAltFace = !!card.altFaceUrl && !!flippedCardIds[card.id];
  const faceUpImageUrl = showAltFace ? card.altFaceUrl : card.url;
  const backgroundImage = isFacedown ? '' : `url("${faceUpImageUrl}")`;

  return (
    <div className="carouselCardWrapper">
      {card.isGhost ?
        <span className="carouselHoverGhost" />
        :
        <span
          onMouseMove={onMouseMove}
          className={`carouselCard ${isFacedown ? 'facedownCarouselCard' : ''}`}
          style={{ backgroundImage }}
        />
      }
    </div>
  );
};

export default CarouselCard;