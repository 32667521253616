import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { AppStore } from '../../../../shared/AppStore';

const GravatarDialog = () => {
  const isOpen = AppStore.useState(s => s.accountPageGravatarDialog.isOpen);

  const onClose = () => {
    AppStore.update(s => { s.accountPageGravatarDialog.isOpen = false; });
  };

  const onConfirm = () => {
    window.open('http://www.gravatar.com', '_blank');
  };

  return (
    <Dialog open={isOpen} onClose={onClose} PaperProps={{
      className: 'gravatarDialog',
    }}>
      <DialogTitle>Change Avatar</DialogTitle>
      <DialogContent>
        <div className="promptText">
          Change your avatar by creating a free Gravatar account with the same email used on CardTavern. Set your desired avatar image on Gravatar and refresh CardTavern to see the change.
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={onConfirm}>Go to Gravatar</Button>
      </DialogActions>
    </Dialog>
  );
};

export default GravatarDialog;