import { Checkbox } from '@mui/material';
import React from 'react';
import { WaitingRoom } from '../../../../shared/types';

type PlayerStatusPanelProps = {
  room: WaitingRoom;
  readyForLaunch: boolean;
};

const PlayerStatusPanel = ({ room, readyForLaunch }: PlayerStatusPanelProps) => {
  const waitingForOthersToJoin = !room.mirrorMode && room.userIds.length === 1;

  let status;
  if (room.gameLaunched) {
    status = 'Game In Progress';
  } else if (readyForLaunch) {
    status = 'Waiting on host to launch game';
  } else if (waitingForOthersToJoin) {
    status = 'Waiting for others to join';
  } else {
    status = 'Waiting on player deck selection';
  }

  return (
    <div className="playerStatusPanel">
      <h1 className="roomStatus">{status}</h1>

      <hr/>

      <div className="playerList">
        {room.userIds.map(_userId => (
          <div key={_userId} className="playerListItem">
            <span className="playerIdLabel">
              {room.usernamesByUserId[_userId]}:
            </span>

            <span className="deckSelectedIndicator">
              <Checkbox checked={!!room.deckIdsByUserId[_userId]} disabled={!room.deckIdsByUserId[_userId]} />
            </span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default PlayerStatusPanel;