import React from 'react';
import { Select } from 'react-functional-select';
import ErrorUtils from '../../../../shared/ErrorUtils';
import { socket } from '../../../../shared/SocketInit';
import { ClientActions } from '../../../../shared/SocketEnums';
import { Deck, GameFormat, formatAbbreviations } from '../../../../shared/types';
import { chain, sortBy } from 'lodash';
import { AppStore } from '../../../../shared/AppStore';
import './DeckSelector.less';

// thanks chatGPT. groups by format, and then alphabetically within each group.
 // Place the matching format group first (if provided)
const orderDecksByFormatAndName = (decks: Deck[], format?: GameFormat) => {
  return chain(decks)
    .orderBy(deck => (format && deck.format === format) ? 0 : 1)
    .groupBy('format')
    .mapValues((group) => sortBy(group, 'name'))
    .flatMap()
    .value();
};

type DeckSelectorProps = {
  // used for swapping decks in-game, not used in lobby
  setDeckId?: (deckId: string) => void;
  format?: GameFormat;
};

const DeckSelector = ({ setDeckId, format }: DeckSelectorProps) => {
  const { decks, loading, error } = AppStore.useState(s => s.userData);

  const onDeckSelect = (deck: Deck) => {
    if (setDeckId) {
      setDeckId(deck.id);
    } else {
      socket.emit(ClientActions.CHOOSE_DECK_FOR_WAITING_ROOM, { deckId: deck.id },
        ErrorUtils.handleGenericError);
    }
  };

  if (error) {
    return (
      <span className="deckLoadError">{error}</span>
    );
  }

  return (
    <div className="deckSelector">
      <Select
        placeholder="Select a deck"
        autoFocus={true}
        isLoading={loading}
        options={orderDecksByFormatAndName(decks, format)}
        onOptionChange={onDeckSelect}
        getOptionValue={(deck: Deck) => deck.id}
        getOptionLabel={(deck: Deck) => deck.name + ' - ' + (formatAbbreviations[deck.format] || 'Any')}
      />
    </div>
  );
};

export default DeckSelector;