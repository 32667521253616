import { Store } from 'pullstate';
import { UiStateStore } from '../../../shared/UiStateStore';
import BattlefieldApi from '../../../utils/BattlefieldApi';

export const getCounterButtonMenuOptions = (
  uiStateStore: Store<UiStateStore>,
) => {
  return [
    {
      text: 'Delete all counters',
      action: () => {
        BattlefieldApi.deleteAllCounters();
      },
    },
  ];
};