import { Store } from 'pullstate';
import { BattlefieldLocations, CardData, DropEventData, Topics } from '../../../../shared/types';
import { convertToDataSource } from '../../components/CardCarousel/CarouselUtils';
import BattlefieldApi from '../../utils/BattlefieldApi';
import { CarouselStore } from '../CarouselStore';

// true if coming from pile to same pile or carousel for that pile,
// or if coming from pile carousel to that pile
const isTryingToDropFromPileOntoItself = (
  cameFrom: BattlefieldLocations,
  destination: BattlefieldLocations,
) => {
  // could convert this to a switch and only check the one corresponding
  // to where it came from to avoid doing 20+ logic checks, but prob doesnt matter tbh
  return (
    (
      cameFrom === BattlefieldLocations.Library
      && [
        BattlefieldLocations.Library,
        BattlefieldLocations.LibraryCarousel].includes(destination)
    ) ||
    (
      cameFrom === BattlefieldLocations.Graveyard
      && [
        BattlefieldLocations.Graveyard,
        BattlefieldLocations.GraveyardCarousel].includes(destination)
    ) ||
    (
      cameFrom === BattlefieldLocations.Exile
      && [
        BattlefieldLocations.Exile,
        BattlefieldLocations.ExileCarousel].includes(destination)
    ) ||
    (
      cameFrom === BattlefieldLocations.GraveyardCarousel
      && destination === BattlefieldLocations.Graveyard
    ) ||
    (
      cameFrom === BattlefieldLocations.ExileCarousel
      && destination === BattlefieldLocations.Exile
    ) ||
    (
      cameFrom === BattlefieldLocations.LibraryCarousel
      && destination === BattlefieldLocations.Library
    ) ||
    (
      cameFrom === BattlefieldLocations.TopNOfLibrary
      && destination === BattlefieldLocations.Library
    )
  );
};

export const dropIsValid = (
  dropData: DropEventData,
  location: BattlefieldLocations,
  carouselStore: Store<CarouselStore>,
) => {
  const drawerTop = document.querySelector('.slider')!.getBoundingClientRect().top;

  if (location === BattlefieldLocations.Library && carouselStore.getRawState().popupCarouselContent === BattlefieldLocations.TopNOfLibrary) {
    setTimeout(() => {
      alert ('Cannot drop to Library pile while viewing top X cards.');
    }, 100);
  }

  return (
    // Don't allow piles to drop onto themselves; easier to just consider that invalid.
    !isTryingToDropFromPileOntoItself(dropData.cameFrom, location)
    // And if drawer is open, don't allow dropping in overlapped part of playArea.
    // Note that this only affects cards dropped from OTHER places to the playArea.
    // Cards FROM playarea and TO playArea have their own guards inside the card files
    && (location !== BattlefieldLocations.PlayArea || dropData.mouseUpEvent.clientY < drawerTop)
    && !(location === BattlefieldLocations.Library && carouselStore.getRawState().popupCarouselContent === BattlefieldLocations.TopNOfLibrary)
  );
};

// The DropZone calls the functions it's given that deal with adding a card to
// the new zone and removing it from the previous zone. If it came from the library,
// the DropZone also talks to the backend to find out what card it is.
// Finally, it handles updating the backend.
export const handleSuccessfulDrop = (
  location: BattlefieldLocations,
  onCardDrop: any,
  dropData: DropEventData,
  carouselStore: Store<CarouselStore>,
) => {
  const isViewingLibraryFacedown = carouselStore.getRawState().isViewingLibraryFacedown;
  const { cameFrom } = dropData;

  // this clause is only for lib pile, not lib carousel
  if (cameFrom === BattlefieldLocations.Library) {
    // On a successful drop from the library pile, we fetch a card from the backend.
    // The drawn card is then moved to the new destination. It's secret until now.
    BattlefieldApi.drawTopCardFromLibraryToDestination(dropData, location, onCardDrop);
    // return early because the DroppedCardsReceived event is already fired by BattlefieldApi
    return;
  }

  const isReorderingCarousel = isReorderingCarouselContent(dropData, location);

  if (!isReorderingCarousel) {
    // handle dragging from facedown library to elsewhere, or from topNOfLibrary to elsewhere
    if ((cameFrom === BattlefieldLocations.LibraryCarousel && isViewingLibraryFacedown)
       || cameFrom === BattlefieldLocations.TopNOfLibrary) {
      BattlefieldApi.drawCardByIdFromLibraryToDestination(dropData, location, onCardDrop);
      // return early because the DroppedCardsReceived event is already fired by BattlefieldApi
      return;
    }
  }

  const newCardsInDestination = onCardDrop(dropData);

  // handle dropping into the facedown library carousel
  // (including from itself, i.e. facedown reordering)
  if (location === BattlefieldLocations.LibraryCarousel && isViewingLibraryFacedown) {
    BattlefieldApi.updateFacedownLibrary(
      newCardsInDestination,
      dropData.cards,
      convertToDataSource(cameFrom),
    );
  } else if (isReorderingCarousel) {
    BattlefieldApi.reorderCarousel(location, newCardsInDestination);
  } else {
    const cardsBelongToOpponent = [
      BattlefieldLocations.OtherPlayerHandCarousel,
      BattlefieldLocations.OtherPlayerLibraryCarousel,
    ].includes(cameFrom);
    // If the destination is the library pile (not library carousel carousel), instead 
    // onCardDrop gives the cards that will be ADDED to the existing cards - but the flow is
    // largely the same, since it hits the same endpoint which then just adjusts
    // its behavior slightly.
    BattlefieldApi.moveCardsBetweenZones(location, cameFrom,
      newCardsInDestination, dropData.cards, cardsBelongToOpponent);
  }

  PubSub.publish(Topics.DroppedCardsReceived, {
    cameFrom: cameFrom,
    receivedBy: location,
    cardIds: dropData.cards.map((card: CardData) => card.id)
  });
};

// check that destination and origin are the same, and that both are carousels.
export const isReorderingCarouselContent = (
  dropData: DropEventData,
  location: BattlefieldLocations,
) => {
  if (dropData.cameFrom !== location) return false;

  const carouselContentPossibilities = [
    BattlefieldLocations.Hand,
    BattlefieldLocations.LibraryCarousel,
    BattlefieldLocations.GraveyardCarousel,
    BattlefieldLocations.ExileCarousel,
    BattlefieldLocations.TopNOfLibrary,
    BattlefieldLocations.OtherPlayerHandCarousel,
    BattlefieldLocations.OtherPlayerLibraryCarousel
  ];

  const cameFromCarousel = carouselContentPossibilities.includes(dropData.cameFrom);

  return cameFromCarousel;
};