import React, { useContext } from 'react';
import { getAuth } from 'firebase/auth';
import { cloneDeep } from 'lodash';
import PlayArea from './PlayArea';
import { GameStoreContext } from '../../shared/GameStore';
import { UiStateStoreContext } from '../../shared/UiStateStore';
import { CardInPlay } from '../../../../shared/types';

const WrappedPlayArea = (props: any) => {
  const gameStore = useContext(GameStoreContext);
  const uiStateStore = useContext(UiStateStoreContext);
  const mirrorMode = gameStore.useState(s => s.mirrorMode);

  // is there a way we can divide this up between players? in a 6 player game do we want to be re-rendering CONSTANTLY? seems like a recipe for lag + interface collapse
  const incomingPublicCardData = cloneDeep(gameStore.useState(s => s.publicCardData));

  const userId = getAuth().currentUser!.uid;
  let incomingMyCardsInPlay = incomingPublicCardData[userId]?.playArea || [];

  const opponentId = uiStateStore.useState(s => s.currentOtherPlayerId);
  let incomingOtherCardsInPlay: CardInPlay[] = [];
  if (opponentId) {
    incomingOtherCardsInPlay = incomingPublicCardData[opponentId]?.playArea || [];
  }

  // PullState makes everything read-only, and then the refs don't work.
  // So we clone this and re-create them. This only happens on the first load.
  // Later edit: btw we don't store refs on backend anymore anyway, so we're actually
  // creating the refs here for the frontend to use.
  // We're using cloning instead of mutation because we don't want to modify the store.
  incomingMyCardsInPlay = incomingMyCardsInPlay.map((card: any) => ({
    ...card,
    ref: { current: null },
  }));

  if (mirrorMode) {
    incomingOtherCardsInPlay = incomingMyCardsInPlay.map(card => ({
      ...card,
      id: card.id + 'm', // can't have two cards with duplicate ids out in play so we do this
    }));
  }

  const incomingCounters = cloneDeep(gameStore.useState(s => s.publicCounterData));

  // This exludes the current opponent's cards trespassing on us,
  // since we'll already be displaying them as part of their cards. Wouldn't want to double display!
  const trespassersOnUs = Object.keys(incomingPublicCardData)
    .filter(_playerId => _playerId !== opponentId)
    .map(_playerId => {
      return incomingPublicCardData[_playerId].playArea.filter(card =>
        card.isTrespassingOnUserId === userId
      );
    }).flat();

  // This excludes our cards trespssing on this opponent,
  // since we'll already be displaying our cards. Wouldn't want to double display!
  const trespassersOnOpponent = Object.keys(incomingPublicCardData)
    .filter(_playerId => _playerId !== userId)
    .map(_playerId => {
      return incomingPublicCardData[_playerId].playArea.filter(card =>
        card.isTrespassingOnUserId === opponentId
      );
    }).flat();

  return (
    <PlayArea
      incomingCounters={incomingCounters}
      incomingMyCardsInPlay={incomingMyCardsInPlay}
      otherCardsInPlay={incomingOtherCardsInPlay}
      trespassersOnUs={trespassersOnUs}
      trespassersOnOpponent={trespassersOnOpponent}
      {...props}
    />
  );
};

export default WrappedPlayArea;