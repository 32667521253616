import React, { useContext, useState } from 'react';
import { Dialog, DialogTitle } from '@material-ui/core';
import { DRAG_ZINDEX } from '../../../../../shared/types';
import { UiStateStoreContext } from '../../../shared/UiStateStore';
import Button from '@mui/material/Button';
import { blurInputs } from '../../../../../shared/utils';
import DeckSelector from '../../../../Lobby/components/DeckSelector';
import BattlefieldApi from '../../../utils/BattlefieldApi';
import { GameStoreContext } from '../../../shared/GameStore';
import './SwapDeckDialog.less';

const SwapDeckDialog = () => {
  const uiStateStore = useContext(UiStateStoreContext);
  const gameStore = useContext(GameStoreContext);

  const { isOpen } = uiStateStore.useState(s => s.swapDeckDialog);
  const [deckId, setDeckId] = useState<string>('');

  return (
    <Dialog
      open={isOpen}
      onClose={() => {
        uiStateStore.update(s => { s.swapDeckDialog.isOpen = false; });
        blurInputs();
      }}
      style={{ zIndex: DRAG_ZINDEX }}
      PaperProps={{
        className: 'swapDeckDialogContainer',
      }}
    >
      <DialogTitle className="swapDeckDialogTitle">Swap Deck</DialogTitle>

      <div className="swapDeckDialogContent">
        <DeckSelector setDeckId={setDeckId} />
        <Button id="submit" variant="contained" onClick={() => {
          BattlefieldApi.swapDeck(deckId, gameStore);
          uiStateStore.update(s => { s.swapDeckDialog.isOpen = false; });
        }}>
          Swap Deck
        </Button>
      </div>
    </Dialog>
  );
};

export default SwapDeckDialog;