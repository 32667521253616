import { Store } from 'pullstate';
import { AccessRequestLocationOptions, BattlefieldLocations } from '../../../../../shared/types';
import { CarouselStore } from '../../../shared/CarouselStore';
import { UiStateStore } from '../../../shared/UiStateStore';
import BattlefieldApi from '../../../utils/BattlefieldApi';

export const getHandMenuOptions = (
  carouselStore: Store<CarouselStore>,
  uiStateStore: Store<UiStateStore>,
) => {
  const lockedZone = uiStateStore.getRawState().lockedZone;
  const handIsLocked = lockedZone === AccessRequestLocationOptions.Hand;
  const libraryIsLocked = [AccessRequestLocationOptions.Library, AccessRequestLocationOptions.TopNOfLibrary].includes(lockedZone!);

  return [
    {
      text: 'Discard hand',
      action: () => {
        BattlefieldApi.sendPileToOtherPile(
          BattlefieldLocations.Hand,
          BattlefieldLocations.Graveyard,
        );
      },
      disabled: handIsLocked,
    },
    {
      text: 'Exile hand',
      action: () => {
        BattlefieldApi.sendPileToOtherPile(
          BattlefieldLocations.Hand,
          BattlefieldLocations.Exile,
        );
      },
      disabled: handIsLocked,
    },
    {
      text: 'Mulligan',
      action: () => {
        BattlefieldApi.mulligan();
      },
      disabled: handIsLocked || libraryIsLocked,
    },
    {
      divider: true,
    },
    {
      text: 'Discard X cards at random',
      action: () => {
        const numCards = +prompt('How many?', '1')!;
        if (isNaN(numCards) || numCards < 1) {
          alert('Must be 1 or more.');
          return;
        }
        BattlefieldApi.discardOrExileNCardsAtRandom(
          numCards,
          BattlefieldLocations.Graveyard,
        );
      },
      disabled: handIsLocked,
    },
    {
      text: 'Exile X cards at random',
      action: () => {
        const numCards = +prompt('How many?', '1')!;
        if (isNaN(numCards) || numCards < 1) {
          alert('Must be 1 or more.');
          return;
        }
        BattlefieldApi.discardOrExileNCardsAtRandom(
          numCards,
          BattlefieldLocations.Exile,
        );
      },
      disabled: handIsLocked,
    },
    {
      divider: true,
    },
    {
      text: 'Shuffle hand into library',
      action: () => {
        BattlefieldApi.shufflePileIntoLibrary(BattlefieldLocations.Hand);
      },
      disabled: handIsLocked || libraryIsLocked,
    },
    {
      text: 'Send hand to bottom of library',
      action: () => {
        BattlefieldApi.sendPileToBottomOfLibrary(BattlefieldLocations.Hand);
      },
      disabled: handIsLocked || libraryIsLocked,
    },
  ];
};