import React from 'react';
import { DecklistItem } from '../../../../../shared/types';

/*
  Creatures

  Artifacts
    Artifact Creatures
    Equipment
    Vehicles
  
  Enchantments
    Enchantment Creatures
    Auras
    Sagas

  Instants

  Sorceries

  Planeswalkers

  Battles
  
  Lands
*/

type SortedDecklist = {
  creatures: DecklistItem[];

  artifacts: DecklistItem[];
  basicArtifacts: DecklistItem[];
  artifactCreatures: DecklistItem[];
  equipment: DecklistItem[];
  vehicles: DecklistItem[];

  enchantments: DecklistItem[];
  basicEnchantments: DecklistItem[];
  auras: DecklistItem[];
  sagas: DecklistItem[];
  enchantmentCreatures: DecklistItem[];

  instants: DecklistItem[];
  sorceries: DecklistItem[];
  planeswalkers: DecklistItem[];
  battles: DecklistItem[];
  lands: DecklistItem[];
};

export const sortDecklistByType = (decklist: DecklistItem[]) => {
  // catch for decks created before we started saving type info from scryfall
  if (!decklist[0].type) return null;

  const creatures = decklist.filter(item => item.type.match(/creature/i));

  const artifacts = decklist.filter(item => item.type.match(/artifact/i));
  const artifactCreatures = artifacts.filter(item => item.type.match(/creature/i));
  const equipment = artifacts.filter(item => item.type.match(/equipment/i));
  const vehicles = artifacts.filter(item => item.type.match(/vehicle/i));
  const basicArtifacts = artifacts.filter(item => 
    !artifactCreatures.includes(item) &&
    !equipment.includes(item) &&
    !vehicles.includes(item)
  );

  const enchantments = decklist.filter(item => item.type.match(/enchantment/i));
  const enchantmentCreatures = enchantments.filter(item => item.type.match(/creature/i));
  const auras = enchantments.filter(item => item.type.match(/aura/i));
  const sagas = enchantments.filter(item => item.type.match(/saga/i));
  const basicEnchantments = enchantments.filter(item => 
    !auras.includes(item) &&
    !sagas.includes(item) &&
    !enchantmentCreatures.includes(item)
  );

  const instants = decklist.filter(item => item.type.match(/instant/i));
  const sorceries = decklist.filter(item => item.type.match(/sorcery/i));
  const planeswalkers = decklist.filter(item => item.type.match(/planeswalker/i));
  const lands = decklist.filter(item => item.type.match(/land/i));
  const battles = decklist.filter(item => item.type.match(/battle/i));

  return {
    creatures,

    artifacts,
    basicArtifacts,
    artifactCreatures,
    equipment,
    vehicles,

    enchantments,
    basicEnchantments,
    auras,
    sagas,
    enchantmentCreatures,

    instants,
    sorceries,
    planeswalkers,
    lands,
    battles,
  };
};

const isColorMatch = (colorFilter: string | null, cardColors: string[]) =>{
  // If colorFilter is null, it's a match for any value.
  if (colorFilter === null) {
    return true;
  }

  // If colorFilter is an empty string, it only matches an empty array. (i.e. colorless)
  if (colorFilter === '') {
    return cardColors.length === 0;
  }

  // Check if the cardColors array contains ALL the letters from the colorFilter string.
  return colorFilter.split('').every(color => cardColors.includes(color));
};


export const getLineForEntry = (decklistItem: DecklistItem, colorFilter: string | null, typeRegex?: RegExp) => {
  if (typeRegex && !decklistItem.type.match(typeRegex)) return null;
  if (!isColorMatch(colorFilter, decklistItem.colors)) return null;

  return (
    <li
      data-url={decklistItem.url}
      {...(decklistItem.altFaceUrl ? { 'data-altfaceurl': decklistItem.altFaceUrl } : {})}
      className="decklistEntry"
      key={decklistItem.name}
    >
      <span className="itemQuantity">{decklistItem.quantity}</span>
      <span className="itemName">{decklistItem.name} ({decklistItem.cmc})</span>
    </li>
  );
};

const getUlForHeaderAndKey = (sortedDecklist: SortedDecklist, sortAlphabetically: boolean, colorFilter: string | null, typeFilter: string, key: keyof SortedDecklist, subheader?: string) => {
  if (sortedDecklist[key].length === 0) return null;

  const typeRegex = typeFilter ? (new RegExp(typeFilter, 'i')) : undefined;

  const itemsForKey = sortedDecklist[key];

  if (sortAlphabetically) {
    itemsForKey.sort((a, b) => {
      // Compare alphabetically based on the 'name' property
      const nameA = a.name.toUpperCase();
      const nameB = b.name.toUpperCase();
      if (nameA < nameB) return -1;
      if (nameA > nameB) return 1;
      return 0;
    });
  } else {
    itemsForKey.sort((a, b) => {
      // Compare numerically based on the 'cmc' property
      const cmcComparison = a.cmc - b.cmc;
      if (cmcComparison !== 0) {
        // If CMC values are different, return the comparison result
        return cmcComparison;
      } else {
        // If CMC values are equal, compare alphabetically based on the 'name' property
        const nameA = a.name.toUpperCase();
        const nameB = b.name.toUpperCase();
        if (nameA < nameB) return -1;
        if (nameA > nameB) return 1;
        return 0;
      }
    });
  }

  // filter out the nulls for lines that didn't match the type filter
  const filteredItems = sortedDecklist[key].filter(item => item);

  const count = filteredItems.reduce((sum: number, item: DecklistItem) => sum + item.quantity, 0);

  const htmlForItems = filteredItems.map(item => (
    getLineForEntry(item, colorFilter, typeRegex)
  ));

  if (htmlForItems.length === 0) return null;

  return (
    <>
      {subheader && <span className="subcategoryHeader">{subheader} - {count}</span>}
      <ul>
        {htmlForItems}
      </ul>
    </>
  );
};

export const getCreaturesMarkup = (sortedDecklist: SortedDecklist, sortAlphabetically: boolean, colorFilter: string | null, typeFilter: string) => {
  if (sortedDecklist.creatures.length === 0) return null;

  const creatures = getUlForHeaderAndKey(sortedDecklist, sortAlphabetically, colorFilter, typeFilter, 'creatures');

  if (!creatures) return null;

  const count = sortedDecklist.creatures.reduce((sum: number, item: DecklistItem) => sum + item.quantity, 0);

  return (
    <div>
      <span className="categoryHeader">Creatures - {count}</span>
      <hr/>
      {creatures}
    </div>
  );
};

export const getArtifactsMarkup = (sortedDecklist: SortedDecklist, sortAlphabetically: boolean, colorFilter: string | null, typeFilter: string) => {
  if (sortedDecklist.artifacts.length === 0) return null;

  const basicArtifacts = getUlForHeaderAndKey(sortedDecklist, sortAlphabetically, colorFilter, typeFilter, 'basicArtifacts');
  const artifactCreatures = getUlForHeaderAndKey(sortedDecklist, sortAlphabetically, colorFilter, typeFilter, 'artifactCreatures', 'Artifact Creatures');
  const equipment = getUlForHeaderAndKey(sortedDecklist, sortAlphabetically, colorFilter, typeFilter, 'equipment', 'Equipment');
  const vehicles = getUlForHeaderAndKey(sortedDecklist, sortAlphabetically, colorFilter, typeFilter, 'vehicles', 'Vehicles');

  if (!basicArtifacts && !artifactCreatures && !equipment && !vehicles) return null;

  const count = sortedDecklist.artifacts.reduce((sum: number, item: DecklistItem) => sum + item.quantity, 0);

  return (
    <div>
      <span className="categoryHeader">Artifacts - {count}</span>
      <hr/>
      {basicArtifacts}
      {artifactCreatures}
      {equipment}
      {vehicles}
    </div>
  );
};

export const getEnchantmentsMarkup = (sortedDecklist: SortedDecklist, sortAlphabetically: boolean, colorFilter: string | null, typeFilter: string) => {
  if (sortedDecklist.enchantments.length === 0) return null;

  const basicEnchantments = getUlForHeaderAndKey(sortedDecklist, sortAlphabetically, colorFilter, typeFilter, 'basicEnchantments');
  const auras = getUlForHeaderAndKey(sortedDecklist, sortAlphabetically, colorFilter, typeFilter, 'auras', 'Auras');
  const sagas = getUlForHeaderAndKey(sortedDecklist, sortAlphabetically, colorFilter, typeFilter, 'sagas', 'Sagas');
  const enchantmentCreatures = getUlForHeaderAndKey(sortedDecklist, sortAlphabetically, colorFilter, typeFilter, 'enchantmentCreatures', 'Enchantment Creatures');

  if (!basicEnchantments && !auras && !sagas) return null;

  const count = sortedDecklist.enchantments.reduce((sum: number, item: DecklistItem) => sum + item.quantity, 0);

  return (
    <div>
      <span className="categoryHeader">Enchantments - {count}</span>
      <hr/>
      {basicEnchantments}
      {auras}
      {sagas}
      {enchantmentCreatures}
    </div>
  );
};

// This is just used for the card-types that dont have subcategories, like instants.
// By contrast, artifacts/creatures/enchantments needed to be handled on their own.
const getMarkupForCategory = (sortedDecklist: SortedDecklist, sortAlphabetically: boolean, colorFilter: string | null, typeFilter: string, key: keyof SortedDecklist, header: string) => {
  const content = getUlForHeaderAndKey(sortedDecklist, sortAlphabetically, colorFilter, typeFilter, key);

  const count = sortedDecklist[key].reduce((sum: number, item: DecklistItem) => sum + item.quantity, 0);

  return content ? (
    <>
      <span className="categoryHeader">{header} - {count}</span>
      <hr/>
      {content}
    </>
  ) : null;
};

export const getMiscellaneousMarkup = (sortedDecklist: SortedDecklist, sortAlphabetically: boolean, colorFilter: string | null, typeFilter: string) => {
  return (
    <>
      <div>
        {getMarkupForCategory(sortedDecklist, sortAlphabetically, colorFilter, typeFilter, 'instants', 'Instants')}
      </div>

      <div>
        {getMarkupForCategory(sortedDecklist, sortAlphabetically, colorFilter, typeFilter, 'sorceries', 'Sorceries')}
      </div>

      <div>
        {getMarkupForCategory(sortedDecklist, sortAlphabetically, colorFilter, typeFilter, 'planeswalkers', 'Planeswalkers')}
      </div>

      <div>
        {getMarkupForCategory(sortedDecklist, sortAlphabetically, colorFilter, typeFilter, 'lands', 'Lands')}
      </div>

      <div>
        {getMarkupForCategory(sortedDecklist, sortAlphabetically, colorFilter, typeFilter, 'battles', 'Battles')}
      </div>
    </>
  );
};