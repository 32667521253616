// duplicated on backend
export const ClientActions = {
  AUTH_CONNECT: 'auth_connect',

  CREATE_WAITING_ROOM: 'create_waiting_room',
  UPDATE_WAITING_ROOM: 'update_waiting_room',
  LEAVE_WAITING_ROOM: 'leave_waiting_room',
  JOIN_WAITING_ROOM: 'join_waiting_room',
  LOAD_WAITING_ROOMS: 'load_waiting_rooms',
  SEND_ROOM_MESSAGE: 'send_room_message',
  CHOOSE_DECK_FOR_WAITING_ROOM: 'choose_deck_for_waiting_room',

  UPDATE_LIFE_COUNTER: 'update_life_counter',
  LOG_LIFE_COUNTER_CHANGE: 'log_life_counter_change',

  CREATE_DECK: 'create_deck',
  EDIT_DECK: 'edit_deck',
  DELETE_DECK: 'delete_deck',
  
  LOAD_USER: 'load_user',

  ADD_CARDS_TO_HAND: 'add_cards_to_hand',
  ADD_CARDS_TO_PLAY_AREA: 'add_cards_to_play_area',
  DELETE_CARDS: 'delete_cards',
  UNTAP_ALL: 'untap_all',

  DRAW_ATTENTION_TO_CARDS: 'draw_attention_to_cards',
  FLIP_CARDS_TO_ALT_FACES: 'flip_cards_to_alt_faces',
  CLONE_CARDS: 'clone_cards',
  TURN_CARDS_FACE_UP_OR_DOWN: 'turn_cards_face_up_or_down',
  REVEAL_CARDS_TO_OWNER: 'reveal_cards_to_owner',
  TAP_OR_UNTAP_CARDS: 'tap_or_untap_cards',
  UPDATE_PT_MODIFIER: 'update_pt_modifier',
  UPDATE_TRESPASSERS: 'update_trespassers',

  GIVE_CONTROL_OF_CARDS: 'give_control_of_cards',
  REQUEST_CONTROL_OF_CARDS: 'request_control_of_cards_',
  REQUEST_TO_VIEW_CARDS: 'request_to_view_cards',
  REQUEST_REANIMATION: 'request_reanimation',
  REVEAL_CARD: 'reveal_card',

  DRAW_TOP_CARD_FROM_LIBRARY: 'draw_top_card_from_library',
  DRAW_CARD_BY_ID_FROM_LIBRARY: 'draw_card_by_id_from_library',
  DRAW_N_CARDS_FROM_LIBRARY: 'draw_n_cards_from_library',
  CASCADE: 'cascade',
  REVEAL_CARD_FROM_LIBRARY_BY_ID: 'reveal_card_from_library_by_id',
  TURN_LIBRARY_CARD_FACEDOWN_BY_ID: 'turn_library_card_facedown_by_id',
  PLACE_CARDS_ON_LIBRARY: 'place_cards_on_library',
  UPDATE_FACEDOWN_LIBRARY: 'update_facedown_library',
  REVEAL_TOP_N_OF_LIBRARY: 'reveal_top_n_of_library',
  OPEN_LIBRARY: 'open_library',
  CLOSE_REVEALED_VIEWS: 'close_revealed_views',
  SHUFFLE_LIBRARY: 'shuffle_library',
  SEND_CARDS_TO_BOTTOM_OF_LIBRARY: 'send_cards_to_bottom_of_library',
  SEND_PILE_TO_BOTTOM_OF_LIBRARY: 'send_pile_to_bottom_of_library',
  SHUFFLE_CARDS_INTO_LIBRARY: 'shuffle_cards_into_library',
  SHUFFLE_PILE_INTO_LIBRARY: 'shuffle_pile_into_library',
  SEND_PILE_TO_OTHER_PILE: 'send_pile_to_other_pile',
  MULLIGAN: 'mulligan',
  DISCARD_N_AT_RANDOM: 'discard_n_at_random',
  TOGGLE_KEEP_TOP_CARD_REVEALED: 'toggle_keep_top_card_revealed',
  PICK_CARD_AT_RANDOM: 'pick_card_at_random',

  CREATE_COUNTER: 'create_counter',
  UPDATE_COUNTER: 'update_counter',
  DELETE_COUNTER: 'delete_counter',
  DELETE_ALL_COUNTERS: 'delete_all_counters',
  LOG_COUNTER_CHANGE: 'log_counter_change',

  LAUNCH_GAME: 'launch_game',
  LOAD_GAME_STATE: 'load_game_state',
  RESET_GAME_STATE: 'reset_game_state',
  SAVE_SIDEBOARD_CHANGES: 'save_sideboard_changes',
  SWAP_DECK: 'swap_deck',
  MOVE_CARDS_BY_ID: 'move_cards_by_id',
  MOVE_CARDS_BETWEEN_ZONES: 'move_cards_between_zones',
  MOVE_CARDS_WITHIN_PLAY_AREA: 'move_cards_within_play_area',
  REORDER_CAROUSEL: 'reorder_carousel',
  SORT_HAND: 'sort_hand',

  RESPONSE_TO_REQUEST: 'response_to_request',

  ROLL_DIE: 'roll_die',
  SEND_BATTLEFIELD_MESSAGE: 'send_battlefield_message',

  EXIT_GAME: 'exit_game',
  RELEASE_ALL_LOCKS: 'release_all_locks',
};

export const ServerNotifications = {
  REQUEST_FROM_OTHER_PLAYER: 'request_from_other_player',
  OUTCOME_OF_REQUEST: 'outcome_of_request',
  WAITING_ROOMS_UPDATED: 'waiting_rooms_updated',
  CURRENT_ROOM_UPDATED: 'current_room_updated',
  AUTH_SUCCESS: 'auth_success',
  GAME_UPDATE: 'game_update',
  SUCCESS: 'success',
  FAILURE: 'failure',
};

export const ServerErrors = {
  GENERIC_ERROR: 'generic_error',

  LOGGED_OUT: 'logged_out',

  // errors for waiting room actions
  ROOM_NO_LONGER_EXISTS: 'room_no_longer_exists',
  ROOM_IS_FULL: 'room_is_full',
  GAME_ALREADY_LAUNCHED: 'game_already_launched',
  WRONG_PASSWORD: 'wrong_password',
  PLAYER_ALREADY_IN_GAME: 'player_already_in_game',
  ONLY_HOST_CAN_UPDATE: 'only_host_can_update',
  PLAYERS_NOT_READY: 'players_not_ready',

  // errors for games
  PLAYER_NOT_IN_GAME: 'player_not_in_game',

  // error for requests
  CARDS_LOCKED: 'cards_locked',
  PLAYER_NOT_CONNECTED: 'player_not_connected',

  // auth errors
  // (these match the possible values of the `code` property on the error response from firebase)
  WEAK_PASSWORD: 'auth/weak-password',
  INVALID_PASSWORD: 'auth/wrong-password',
  INVALID_EMAIL: 'auth/invalid-email',
  USER_NOT_FOUND: 'auth/user-not-found',
  EMAIL_ALREADY_IN_USE: 'auth/email-already-exists',
  REQUIRES_RECENT_LOGIN: 'auth/requires-recent-login',
  // these ones are ours, not from firebase
  USERNAME_UNAVAILABLE: 'username-unavailable',
  USERNAME_INVALID: 'username-invalid',
  INVALID_TOKEN: 'invalid-token',
};