import { Store } from 'pullstate';
import { BattlefieldLocations } from '../../../../../shared/types';
import { CarouselStore } from '../../../shared/CarouselStore';
import { UiStateStore } from '../../../shared/UiStateStore';
import { expandPile } from './GenericMenuOptions';

export const getOtherPlayerPileMenuOptions = (
  source: BattlefieldLocations.OtherPlayerExile | BattlefieldLocations.OtherPlayerGraveyard,
  carouselStore: Store<CarouselStore>,
  uiStateStore: Store<UiStateStore>,
  instanceData: {
    mirrorMode: boolean;
  },
) => {
  return [
    {
      text: 'Expand',
      action: () => {
        expandPile(source, carouselStore, uiStateStore);
      },
      disabled: instanceData.mirrorMode,
    },
  ];
};