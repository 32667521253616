import React, { useContext, useEffect } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { UiStateStoreContext } from '../../../shared/UiStateStore';
import { Topics, RequestDetails } from '../../../../../shared/types';
import { socket } from '../../../../../shared/SocketInit';
import { ClientActions } from '../../../../../shared/SocketEnums';
import { dismissContextMenu } from '../../ContextMenu/ContextMenu';
import { CarouselStoreContext } from '../../../shared/CarouselStore';

const RequestConfirmationDialog = () => {
  const uiStateStore = useContext(UiStateStoreContext);
  const carouselStore = useContext(CarouselStoreContext);
  const {
    isOpen,
    message,
    requestId,
  } = uiStateStore.useState(s => s.requestConfirmationDialog);

  useEffect(() => {
    PubSub.subscribe(Topics.RequestReceived, (topic: Topics, requestDetails: RequestDetails) => {
      dismissContextMenu(uiStateStore, carouselStore);
      uiStateStore.update(s => {
        s.requestConfirmationDialog.message = requestDetails.message;
        s.requestConfirmationDialog.requestId = requestDetails.requestId;
        s.requestConfirmationDialog.isOpen = true;
      });
    });
  }, []);

  if (!message) return null;

  const onClose = (shouldAutoDeny?: boolean) => {
    uiStateStore.update(s => {
      s.requestConfirmationDialog.message = null;
      s.requestConfirmationDialog.requestId = null;
      s.requestConfirmationDialog.isOpen = false;
    });

    // if the modal is closed by clicking outside it or hitting esc, deny automatically
    if (shouldAutoDeny) {
      socket.emit(ClientActions.RESPONSE_TO_REQUEST, {
        requestId,
        requestGranted: false,
      });
    }
  };

  const onDeny = () => {
    socket.emit(ClientActions.RESPONSE_TO_REQUEST, {
      requestId,
      requestGranted: false,
    });
    onClose();
  };

  const onAgree = () => {
    socket.emit(ClientActions.RESPONSE_TO_REQUEST, {
      requestId,
      requestGranted: true,
    });
    onClose();
  };

  return (
    <Dialog
      open={isOpen}
      onClose={() => onClose(true)}
      aria-labelledby="request-confirmation-dialog"
    >
      <DialogTitle id="request-confirmation-dialog">
        Request from Opponent
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {message}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={onDeny}>
          Cancel
        </Button>
        <Button onClick={onAgree}>Agree</Button>
      </DialogActions>
    </Dialog>
  );
};

export default RequestConfirmationDialog;