import React, { useEffect } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { KeyboardActions, KeyboardShortcutLabels, ShiftedKeys } from '../keyboardShortcutConstants';
import { UserSettings } from '../../../../shared/types';
import { isEqual } from 'lodash';
import { AppStore } from '../../../../shared/AppStore';
import './ChangeShortcutDialog.less';

let newShortcutListener: any = null;
// it's control on mac - is it ctrl on windows? haven't tested
const modifierKeys = ['alt', 'ctrl', 'control', 'meta', 'shift'];

const ChangeShortcutDialog = (
  { setNewSettings }: { setNewSettings: Function }
) => {
  const { isOpen, action } = AppStore.useState(s => s.changeShortcutDialog);

  const actionLabel = KeyboardShortcutLabels[action as KeyboardActions];

  const onClose = () => {
    document.removeEventListener('keydown', newShortcutListener);

    AppStore.update(s => {
      s.changeShortcutDialog.isOpen = false;
      s.changeShortcutDialog.action = '';
    });
  };

  useEffect(() => {
    if (isOpen) {
      let keysPressed: string[] = [];

      newShortcutListener = (event: KeyboardEvent) => {
        let key = event.key.toLowerCase();
        // although in the actual game it comes up as space when you hit it,
        // for this listener it comes up as a whitespace character. idk what the difference is.
        if (key === ' ') key = 'space';

        // convert ! to 1, etc
        key = ShiftedKeys[key] || key;

        // only one modifier key allowed. if they hit a second one,
        // it basically resets our state here by emptying the array
        // of any prior values and replacing it with the new modifier key alone.
        if (modifierKeys.includes(key)) {
          keysPressed = [key];
        } else {
          keysPressed.push(key);

          setNewSettings((previousSettings: UserSettings) => {
            // check if this shortcut is already in use. if so, we clear the pre-existing one.
            let previousShortcutAction = null;
            Object.keys(previousSettings.keyboardShortcuts).forEach(_action => {
              // if we set an action to the shortcut it already uses, don't clear it
              if (_action === action) return;
              // but if it's a DIFFERENT action using this shortcut...
              const shortcut = previousSettings.keyboardShortcuts[_action as KeyboardActions];
              if (isEqual(shortcut, keysPressed)) {
                previousShortcutAction = _action;
              }
            });

            if (previousShortcutAction) {
              return {
                ...previousSettings,
                keyboardShortcuts: {
                  ...previousSettings.keyboardShortcuts,
                  [action]: keysPressed,
                  [previousShortcutAction]: [],
                },
              };
            }

            return {
              ...previousSettings,
              keyboardShortcuts: {
                ...previousSettings.keyboardShortcuts,
                [action]: keysPressed,
              },
            };
          });
          onClose();
        }
      };

      // Add event listener for keydown events
      document.addEventListener('keydown', newShortcutListener);
    } else {

    }
  }, [isOpen]);

  // stop it from briefly displaying wrong text while closing
  if (!action) {
    return null;
  }

  return (
    <Dialog open={isOpen} onClose={onClose} PaperProps={{
      className: 'changeShortcutDialog',
    }}>
      <DialogTitle>Set Shortcut</DialogTitle>
      <DialogContent>
        <div className="promptText">
          {`Press the desired key or key combination for ${actionLabel}.`}
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
      </DialogActions>
    </Dialog>
  );
};

export default ChangeShortcutDialog;