import React from 'react';
import { Chip } from '@mui/material';
import WaitingRoomChat from '../WaitingRoomChat/WaitingRoomChat';
// import { WaitingRoomExperienceLevel, GameFormat } from '../../../../shared/types';
import PlayerStatusPanel from './PlayerStatusPanel';
import RoomControlsPanel from './RoomControlsPanel';
import { AppStore } from '../../../../shared/AppStore';
import './CurrentWaitingRoom.less';

// remove the type imports when done
// leaving this for now cuz we need it when we work on different screen sizes
// const dummyRoom = {
//   id: 'jaoifjqoerijg',
//   title: 'EDH experienced players preferred',
//   hostUserId: 'ajeroiguhaeorgue',
//   experienceLevel: WaitingRoomExperienceLevel.Beginner,
//   gameFormat: GameFormat.EDH,
//   gameLaunched: false,
//   userIds: ['ajeroiguhaeorgue', 'vmoienoiguhaeorgue', 'xuxjiuehaeorgue'],
//   maxPlayers: 2,
//   gameId: null,
//   deckIdsByUserId: { 'ajeroiguhaeorgue': 'aooeirqeo' },
//   chat: [],
//   mirrorMode: false,
// };

const CurrentWaitingRoom = () => {
  const room = AppStore.useState(s => s.currentRoom);// || dummyRoom;

  if (!room) return null;

  const waitingForOthersToJoin = !room.mirrorMode && room.userIds.length === 1;
  const allPlayersHaveSelectedDecks = Object.keys(room.deckIdsByUserId).length === room.userIds.length;
  const readyForLaunch = !waitingForOthersToJoin && allPlayersHaveSelectedDecks;

  return (
    <div className="currentWaitingRoomView">
      <div className="titleAndTagsContainer">
        <div className="roomTitle">
          {room.title}
        </div>

        <div className="roomTags">
          <Chip label={room.gameFormat}/>
          <Chip label={room.experienceLevel}/>
        </div>
      </div>

      <div className="roomChat">
        <WaitingRoomChat room={room}/>
      </div>

      <div className="roomDetails">
        <PlayerStatusPanel room={room} readyForLaunch={readyForLaunch} />
        <RoomControlsPanel room={room} readyForLaunch={readyForLaunch} />
      </div>
    </div>
  );
};

export default CurrentWaitingRoom;