import { ColorAttributes, ColorFilterStrategies, Deck, GameFormat } from '../../../../shared/types';

export const filterDecks = (
  decks: Deck[],
  nameFilter: string,
  formatFilter: GameFormat,
  colorFilter: ColorAttributes,
  colorFilterStrategy: ColorFilterStrategies,
) => {
  return decks
    .filter(deck => deck.name.toLowerCase().includes(nameFilter.toLowerCase()))
    .filter(deck => (formatFilter === GameFormat.Any || deck.format === formatFilter))
    .filter(deck => {
      // if a filter is on at all, ignore all decks created before colors info was added
      if (Object.values(colorFilter).some(value => value) && !deck.colors) return false;

      // if no colors filters are on, don't filter by color.
      if (Object.values(colorFilter).every(value => !value)) return true;

      switch (colorFilterStrategy) {
        case ColorFilterStrategies.Exactly: {
          // If all 5 colors are false on both sides, then the filter
          // is set to colorless and the deck is colorless and is an exact match.
          return (
            colorFilter.red === deck.colors.red &&
            colorFilter.blue === deck.colors.blue &&
            colorFilter.black === deck.colors.black &&
            colorFilter.white === deck.colors.white &&
            colorFilter.green === deck.colors.green
          );
        }

        case ColorFilterStrategies.Including: {
          for (const color in colorFilter) {
            if (colorFilter[color as keyof ColorAttributes]) {
              if (!deck.colors[color as keyof ColorAttributes]) {
                return false;
              }
            }
          }
          // If all properties that are true in colorsFilter are also true
          // in deck.colors, it's a match.
          return true;
        }

        // ignore colorless for this strategy
        case ColorFilterStrategies.AtMost: {
          for (const color in colorFilter) {
            if (color === 'colorless') continue;
            if (colorFilter[color as keyof ColorAttributes]
                && deck.colors[color as keyof ColorAttributes]) {
              // Deck has at least one color that is present in filter.
              // Now check if deck has any colors not in filter.
              for (const otherColor in deck.colors) {
                if (otherColor === 'colorless') continue;
                if (!colorFilter[otherColor as keyof ColorAttributes]
                  && deck.colors[otherColor as keyof ColorAttributes]) {
                  // Deck has a color that is not present in obj1
                  return false;
                }
              }
              return true;
            }
          }

          // No matching colors found
          return false;
        }
      }
    });
};